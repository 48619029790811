import { Formik } from 'formik';
import { Box, Button, IconButton, Typography, TextField, Grid, Avatar } from '@mui/material';
import {
  updateUser,
  deleteUserPhoto,
  getUser,
  changePassword,
  mailVerificationEmail,
} from 'src/features/user/user.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { accountSchema } from 'src/utils/formSchemas';
import ErrorPopup from '../ErrorPopup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState } from 'react';
import SuccessPopup from '../SuccessPopup';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { compareChanges } from 'src/libs/utils';
import { timezonelist } from 'src/libs/timezones';
import Select from 'react-select';
import { delayTime } from '../ToastMessage';
import ChangePassword from './ChangePassword';
import ValidatedPhoneInput from '../ValidatedPhoneInput';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const AccountForm = () => {
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const { user, userLoading, userError } = useAppSelector((state) => state.user);
  const [successMessage, setSuccessMessage] = useState("")
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [selectedPicture, setSelectedPicture] = useState(null);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const initialValues = {
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email,
    contact_phone: user.contact_phone,
    timezone: user.timezone,
    existing_user: false,
  };

  const onSubmit = (data) => {
    let payload = compareChanges(initialValues, data)
    dispatch(updateUser(data))
      .unwrap()
      .then((response) => {
        dispatch(trackAnalytics({ action_name: "profile_saved", payload: { ...payload, description: "user updated account form" } }))
        setSuccessMessage("Successfully Updated")
        setTimeout(() => {
          setSuccessMessage("")
        }, delayTime)
      })
  };

  const deletePhoto = () => {
    dispatch(deleteUserPhoto())
      .unwrap()
      .then(() => {
        dispatch(getUser());
      });
  };

  const closeChangePassword = () => {
    setShowChangePassword(false)
  };

  const onSubmitPassword = (data) => {
    dispatch(changePassword(data)).unwrap().then(() => {
      setSuccessMessage("Successfully Updated Password")
      setTimeout(() => {
        setSuccessMessage("")
      }, delayTime)
    })
    setShowChangePassword(false)
  };

  const sendVerificationEmail = () => {
    dispatch(mailVerificationEmail()).unwrap().then((response) => {
      setSuccessMessage(response.data.message)
      setTimeout(() => {
        setSuccessMessage("")
      }, delayTime)
    })
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      background: isDarkMode ? alpha(theme.palette.background.paper, 0.1) : theme.palette.background.paper,
      borderColor: isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider,
      boxShadow: 'none',
      '&:hover': {
        borderColor: theme.palette.primary.main
      }
    }),
    menu: (base) => ({
      ...base,
      background: isDarkMode ? theme.palette.background.paper : theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      marginTop: 0,
      boxShadow: theme.shadows[4]
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      background: isDarkMode ? theme.palette.background.paper : theme.palette.background.paper,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? theme.palette.primary.main
        : isFocused
          ? isDarkMode
            ? alpha(theme.palette.primary.main, 0.1)
            : theme.palette.action.hover
          : isDarkMode
            ? theme.palette.background.paper
            : theme.palette.background.paper,
      color: isSelected
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary,
      '&:hover': {
        backgroundColor: isDarkMode
          ? alpha(theme.palette.primary.main, 0.1)
          : theme.palette.action.hover
      }
    }),
    singleValue: (base) => ({
      ...base,
      color: theme.palette.text.primary,
    }),
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
    })
  };

  return (
    <Box sx={{ p: 3 }}>
      {showChangePassword && (
        <ChangePassword
          show={showChangePassword}
          onClose={closeChangePassword}
          onSubmit={onSubmitPassword}
          userLoading={userLoading}
        />
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 5 }}>
        <Typography variant="h5" component="h3">Account</Typography>
        <Button
          variant="contained"
          disabled={userLoading}
          onClick={() => setShowChangePassword(true)}
        >
          Change Password
        </Button>
      </Box>

      {userError && <ErrorPopup message={userError} />}
      {successMessage && <SuccessPopup message={successMessage} />}

      <Typography variant="h6" sx={{ mb: 1 }}>Profile</Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 4 }}>
        This information will be displayed publicly
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={accountSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          resetForm,
          values,
          touched,
          errors,
        }) => {
          const selectedzone = timezonelist.filter(zone => zone.value === values.timezone)
          return (
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    value={values.first_name}
                    id="first_name"
                    name="first_name"
                    disabled={userLoading}
                    onChange={handleChange}
                    error={touched.first_name && !!errors.first_name}
                    helperText={touched.first_name && errors.first_name}
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.1) : theme.palette.background.paper,
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={values.last_name}
                    id="last_name"
                    name="last_name"
                    disabled={userLoading}
                    onChange={handleChange}
                    error={touched.last_name && !!errors.last_name}
                    helperText={touched.last_name && errors.last_name}
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.1) : theme.palette.background.paper,
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>Photo</Typography>
                    <Avatar
                      src={selectedPicture || user.picture}
                      sx={{ width: 80, height: 80, mb: 2 }}
                    />
                    <Box>
                      <input
                        type="file"
                        id="avatar"
                        name="[photo_attributes].file"
                        accept="image/png, image/jpeg"
                        disabled={userLoading}
                        onChange={(event) => {
                          setFieldValue(
                            '[photo_attributes].file',
                            event.currentTarget.files[0]
                          );
                          setSelectedPicture(URL.createObjectURL(event.target.files[0]));
                        }}
                        style={{ display: 'none' }}
                      />
                      <Button
                        component="label"
                        htmlFor="avatar"
                        variant="outlined"
                        sx={{ mr: 2 }}
                      >
                        Choose file
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={deletePhoto}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>

                  <Box sx={{ mb: 4 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      Time Zone<Typography component="span" color="error">*</Typography>
                    </Typography>
                    <Select
                      placeholder="Select Timezone"
                      id="timezone"
                      name="timezone"
                      defaultValue={selectedzone.length ? selectedzone[0] : { label: values.timezone, value: values.timezone }}
                      onChange={e => setFieldValue("timezone", e.value)}
                      options={timezonelist}
                      styles={customStyles}
                    />
                    {errors.timezone && (
                      <Typography color="error" variant="caption">
                        {errors.timezone}
                      </Typography>
                    )}
                  </Box>

                  <Typography variant="h6" sx={{ mb: 1 }}>Personal Information</Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                    This information will be displayed publicly
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Email Address
                      </Typography>
                      <Box sx={{ position: 'relative' }}>
                        <TextField
                          fullWidth
                          value={values.email}
                          id="email"
                          name="email"
                          disabled
                          error={touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          InputProps={{
                            endAdornment: (
                              <Button
                                onClick={sendVerificationEmail}
                                sx={{ position: 'absolute', right: 8 }}
                              >
                                update email
                              </Button>
                            ),
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.1) : theme.palette.background.paper,
                            }
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Cell Phone Number<Typography component="span" color="error">*</Typography>
                      </Typography>
                      <ValidatedPhoneInput
                        value={values.contact_phone}
                        setValue={(field, value) => setFieldValue(field, value)}
                        setIsPhoneVerified={setIsPhoneVerified}
                        setPhoneError={setPhoneError}
                      />
                      {(phoneError || (values.existing_user && values.contact_phone)) && (
                        <Typography color="error" variant="caption">
                          {phoneError || `Phone number +${values.contact_phone} is already associated with a different account`}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={userLoading}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => resetForm({ values: initialValues })}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )
        }}
      </Formik>
    </Box>
  );
};

export default AccountForm;
