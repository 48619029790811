import { Formik } from 'formik';
import { Dialog, DialogTitle, DialogContent, Box, Button, TextField, Typography } from '@mui/material';
import { useAppSelector } from 'src/store';
import { changePasswordSchema } from 'src/utils/formSchemas';
import { getBranding } from 'src/utils/branding';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ChangePassword = ({
  show,
  onClose,
  onSubmit,
  userLoading,
}) => {
  const initialValues = {
    current_password: '',
    password: '',
    password_confirmation: '',
  };
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const branding_logo = getBranding('logo');

  return (
    <Dialog
      open={show}
      onClose={onClose}
      fullScreen={window.innerWidth < 600}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: isDarkMode ? 'background.paper' : 'background.paper',
          backgroundImage: 'none',
        }
      }}
    >
      <DialogTitle sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        {/* Desktop view */}
        <Box sx={{
          display: { xs: 'none', sm: 'flex' },
          alignItems: 'center',
          fontSize: '12px',
          ml: 3
        }}>
          Powered by
          <Box component="img" src={branding_logo} sx={{ ml: 1 }} alt="" />
        </Box>

        {/* Mobile view */}
        <Box sx={{
          display: { xs: 'flex', sm: 'none' },
          alignItems: 'center',
          px: 3,
          py: 2,
          fontSize: '14px',
          fontWeight: 500,
          cursor: 'pointer'
        }} onClick={onClose}>
          <ArrowBackIcon sx={{ mr: 1 }} />
          Go back
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}>
          <Typography variant="h5" sx={{ mb: 3, fontWeight: 500 }}>
            Change Password
          </Typography>

          <Box sx={{ width: '100%' }}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize
              validationSchema={changePasswordSchema}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                resetForm,
                values,
                touched,
                errors,
              }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1, textAlign: 'left' }}>
                      Old Password <Typography component="span" color="error">*</Typography>
                    </Typography>
                    <TextField
                      fullWidth
                      type="password"
                      value={values.current_password}
                      id="current_password"
                      name="current_password"
                      onChange={handleChange}
                      error={touched.current_password && !!errors.current_password}
                      helperText={touched.current_password && errors.current_password}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.1) : theme.palette.background.paper,
                        }
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: 4 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1, textAlign: 'left' }}>
                      New Password <Typography component="span" color="error">*</Typography>
                    </Typography>
                    <TextField
                      fullWidth
                      type="password"
                      value={values.password}
                      id="password"
                      name="password"
                      onChange={handleChange}
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.1) : theme.palette.background.paper,
                        }
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: 4 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1, textAlign: 'left' }}>
                      Confirm Password <Typography component="span" color="error">*</Typography>
                    </Typography>
                    <TextField
                      fullWidth
                      type="password"
                      value={values.password_confirmation}
                      id="password_confirmation"
                      name="password_confirmation"
                      onChange={handleChange}
                      error={touched.password_confirmation && !!errors.password_confirmation}
                      helperText={touched.password_confirmation && errors.password_confirmation}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.1) : theme.palette.background.paper,
                        }
                      }}
                    />
                  </Box>

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={userLoading}
                    sx={{ mb: 2, fontWeight: 500 }}
                  >
                    Update Password
                  </Button>
                </Box>
              )}
            </Formik>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
