import axios from 'axios';

interface SearchResponse {
    success: boolean;
    current_page: number;
    total_pages: number;
    total_count: number;
    has_more: boolean;
    chunks: Array<{
        id: string;
        content: string;
        chunk_type: string;
        similarity: number;
        metadata: {
            url: string;
            title: string | null;
            description: string | null;
        };
    }>;
}

const searchService = {
    search: async (query: string): Promise<SearchResponse> => {
        const params = new URLSearchParams({
            query,
            limit: '10',
            threshold: '0.7',
            distance: 'cosine',
            order_by: 'similarity',
            order_direction: 'desc',
            page: '1',
            per_page: '25'
        });

        try {
            const httpClient = axios.create({
                baseURL: 'https://comms-api-883356951770.us-central1.run.app',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                validateStatus: (status) => status === 200, // Only accept 200 status
            });

            try {
                const response = await httpClient.get(`/api/v1/public/search?${params.toString()}`);
                console.log('Production response:', {
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    data: response.data
                });

                if (!response.data) {
                    throw new Error('Empty response data');
                }

                return response.data;
            } catch (axiosError: any) {
                console.error('Axios error details:', {
                    status: axiosError.response?.status,
                    statusText: axiosError.response?.statusText,
                    data: axiosError.response?.data,
                    headers: axiosError.response?.headers,
                    message: axiosError.message
                });
                throw axiosError;
            }
        } catch (error) {
            console.error('Search request failed:', error);
            throw error;
        }
    }
};

export default searchService;