import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography, Card, CircularProgress, IconButton, LinearProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { createContact, updateContact, deleteContact } from 'src/features/account/contacts.slice';
import ContactsDataGridV2 from './ContactsDataGridV2';
import ContactsList from './ContactsList';
import { ContactCreateDialog } from './ContactCreateDialog';
import ContactsFilterBar from './ContactsFilterBar';
import ContactsUploaderDialog from './uploader/ContactsUploaderDialog';
import { setOpenDialog } from 'src/features/theme/theme.slice';
import { fetchFilteredContacts } from 'src/features/account/filteredContacts.slice';
import { selectAllFilteredContacts } from 'src/features/account/filteredContacts.slice';
import { isEmpty } from 'lodash';
import { ViewAgenda, ViewStream } from '@mui/icons-material'; // Updated imports
// Removed unused imports: ViewList, GridView
// Import Oppy image
import { getBranding } from 'src/utils/branding';
import { selectIsFilterActive } from 'src/features/account/filteredContacts.slice'; // Import selector
import { setFilter } from 'src/features/account/filteredContacts.slice'; // Import setFilter
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // Import PersonAddIcon
import ImportContactsIcon from '@mui/icons-material/ImportContacts'; // Import ImportContactsIcon
import BulkConversationCreateDialog from '../Channels/BulkConversationCreateDialog';
import ContactsKanban from './ContactsKanban'; // Import the new component
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import ContactsService from 'src/services/contacts.services'; // Import ContactsService
import { useTheme } from '@mui/material/styles';
import { Contact } from 'src/types/contacts';

interface ContactsWrapperProps {
  contacts: Contact[];
}

const ContactsWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const [viewMode, setViewMode] = useState<'list' | 'grid' | 'kanban'>('list'); // Add 'kanban' to viewMode
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const isFilterActive = useAppSelector(selectIsFilterActive); // Use selector
  const [isBulkDialogOpen, setIsBulkDialogOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [multiEditDialogOpen, setMultiEditDialogOpen] = useState(false); // State for MultiEditDialog
  const navigate = useNavigate();
  const location = useLocation(); // Initialize useLocation
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  // State to control loading bar visibility
  const [showLoadingBar, setShowLoadingBar] = React.useState(false);

  // Get contacts and loading status from Redux store
  const contacts = useAppSelector(selectAllFilteredContacts);
  const contactsLoading = useAppSelector(
    (state) => state.filteredContacts.contactsLoading || state.contacts.contactsLoading
  );
  const filterObject = useAppSelector((state) => state.filteredContacts.filterObject);

  // Use 'oppy_expressions_drunk' to match 404.tsx
  const oppyImage = getBranding('oppy_expressions_drunk');

  // Extract viewMode from URL query parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const viewModeParam = params.get('viewMode');
    if (viewModeParam === 'list' || viewModeParam === 'grid' || viewModeParam === 'kanban') {
      setViewMode(viewModeParam as 'list' | 'grid' | 'kanban');
    }
  }, [location.search]);

  useEffect(() => {
    // Fetch contacts when component mounts or filters change
    if (isFilterActive) {
      console.log('Fetching contacts with filters:', filterObject);
      setShowLoadingBar(true);

      // **Reset selected contacts when filters change**
      setSelectedContacts([]);

      dispatch(fetchFilteredContacts(filterObject))
        .then(() => {
          console.log('Contacts fetched successfully.');
        })
        .catch((error) => {
          console.error('Error fetching contacts:', error);
        })
        .finally(() => {
          setShowLoadingBar(false);
        });
    }
  }, [dispatch, filterObject, isFilterActive]);

  const toggleViewIcon = viewMode === 'list' ? <ViewStream /> : viewMode === 'grid' ? <ViewAgenda /> : <ViewStream />;

  const handleViewModeToggle = () => {
    setViewMode(prevMode => {
      const newMode = prevMode === 'list' ? 'grid' : prevMode === 'grid' ? 'kanban' : 'list';
      navigate(`?viewMode=${newMode}`); // Update URL with new viewMode
      return newMode;
    });
  };

  const handleCreateContact = (newContact) => {
    dispatch(createContact(newContact)).then(() => {
      dispatch(fetchFilteredContacts({}));
    });
  };

  const handleOpenContactsUploaderDialog = () => {
    dispatch(setOpenDialog('ContactsUploaderDialog'));
  };

  // Function to clear filters and fetch default contacts
  const handleClearFilters = () => {
    console.log('Clearing filters and fetching default contacts');
    dispatch(setFilter({})); // Reset filters in Redux
    dispatch(fetchFilteredContacts({}));
  };

  const handleContactsSelection = (selectedIds: string[]) => {
    console.log('Selected IDs:', selectedIds);
    const selected = contacts.filter((contact) => selectedIds.includes(contact.id.toString()));
    setSelectedContacts(selected);
  };

  const handleBulkMessageClick = () => {
    setIsBulkDialogOpen(true);
  };

  const handleBulkDelete = () => {
    if (window.confirm(`Are you sure you want to delete ${selectedContacts.length} selected contacts?`)) {
      Promise.all(selectedContacts.map(contact => dispatch(deleteContact(contact.id))))
        .then(() => {
          setSelectedContacts([]);
          dispatch(fetchFilteredContacts(filterObject));
        });
    }
  };

  // Function to handle bulk edit click
  const handleBulkEditClick = () => {
    setMultiEditDialogOpen(true);
    console.log('Bulk edit clicked');
  };

  // Define styles
  const styles = {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: 2,
      background: darkMode ? 'grey.900' : '#FAFAFA',
      color: darkMode ? 'grey.100' : 'text.primary',
      width: '100%',
      mb: 2,
      transition: 'all 0.3s ease',
      borderBottom: `1px solid ${darkMode ? theme.palette.grey[800] : theme.palette.grey[200]}`,
    },
    topBarAction: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiIconButton-root': {
        color: darkMode ? 'grey.300' : 'inherit',
        '&:hover': {
          bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    noContactsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '50vh',
      color: darkMode ? 'grey.300' : 'text.primary',
      bgcolor: darkMode ? 'grey.900' : 'background.default',
    },
    oppyImage: {
      width: '200px',
      mb: 2,
      filter: darkMode ? 'brightness(0.8)' : 'none',
    },
    clearButton: {
      mt: 2,
      color: darkMode ? 'grey.300' : 'primary.main',
      '&:hover': {
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)',
      },
    },
    wrapper: {
      p: 2,
      bgcolor: darkMode ? 'grey.900' : 'background.default',
      color: darkMode ? 'grey.100' : 'text.primary',
      minHeight: '100vh',
      transition: 'all 0.3s ease',
    },
    bulkActions: {
      display: 'flex',
      gap: 2,
      mt: 2,
      '& .MuiButton-root': {
        '&:hover': {
          filter: 'brightness(110%)',
        },
      },
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '50vh',
      color: darkMode ? 'grey.300' : 'text.primary',
    },
  };

  // Lazy load MultiEditDialog
  const MultiEditDialog = React.useMemo(
    () => lazy(() => import('./MultiEditDialog')),
    []
  );

  const handleSyncWithFub = () => {
    ContactsService.syncWithFub()
      .then(response => {
        console.log('Sync initiated:', response.data.message);
      })
      .catch(error => {
        console.error('Error syncing with FUB:', error);
      });
  };

  return (
    <Box sx={styles.wrapper}>
      {showLoadingBar && <LinearProgress sx={{ mb: 2, bgcolor: darkMode ? 'grey.800' : undefined }} />}

      <Box sx={styles.header}>
        <Typography variant="h4" sx={{ color: darkMode ? 'grey.100' : 'text.primary' }}>
          Contacts
        </Typography>
        <Box sx={styles.topBarAction}>
          <IconButton onClick={handleViewModeToggle} sx={{ mr: 0 }}>
            {toggleViewIcon}
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => setIsCreateDialogOpen(true)}
            sx={{ mr: 0 }}
          >
            <PersonAddIcon />
          </IconButton>
          <IconButton color="secondary" onClick={handleOpenContactsUploaderDialog}>
            <ImportContactsIcon />
          </IconButton>
          {/* IconButton for Sync with FUB using provided image */}
          {/* <IconButton onClick={handleSyncWithFub}>
            <img
              src="https://res.cloudinary.com/kw/image/upload/v1729703593/oppy/fub_icon.svg"
              alt="Sync with FUB"
              style={{ width: 34, height: 34 }}
            />
          </IconButton> */}
        </Box>
      </Box>

      <ContactsFilterBar />

      {selectedContacts && selectedContacts.length > 0 && (
        <Box sx={styles.bulkActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBulkEditClick}
            sx={{
              bgcolor: darkMode ? 'primary.dark' : 'primary.main',
              '&:hover': {
                bgcolor: darkMode ? 'primary.main' : 'primary.dark',
              },
            }}
          >
            Edit Selected ({selectedContacts.length})
          </Button>
          <Button
            variant="contained"
            onClick={handleBulkMessageClick}
            sx={{
              bgcolor: darkMode ? 'primary.dark' : 'primary.main',
              '&:hover': {
                bgcolor: darkMode ? 'primary.main' : 'primary.dark',
              },
            }}
          >
            Send Bulk Message
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleBulkDelete}
            sx={{
              bgcolor: darkMode ? 'error.dark' : 'error.main',
              '&:hover': {
                bgcolor: darkMode ? 'error.main' : 'error.dark',
              },
            }}
          >
            Delete Selected
          </Button>
        </Box>
      )}

      {contactsLoading && contacts.length === 0 ? (
        <Box sx={styles.loadingContainer}>
          <CircularProgress sx={{ color: darkMode ? 'grey.300' : 'primary.main' }} />
        </Box>
      ) : !contactsLoading && contacts.length === 0 ? (
        <Box sx={styles.noContactsContainer}>
          <img src={oppyImage} alt="No contacts" style={{ width: '200px', marginBottom: '16px' }} />
          <Typography variant="h6" sx={{ color: darkMode ? 'grey.300' : 'text.primary', mb: 2 }}>
            {isEmpty(filterObject) ? "No contacts here yet." : "Couldn't find any contacts."}
          </Typography>
          <Typography variant="body1" sx={{ color: darkMode ? 'grey.400' : 'text.secondary', mb: 2 }}>
            {isEmpty(filterObject)
              ? "Add your first contact to get started."
              : "Try adjusting your search or clearing the filters."}
          </Typography>
          {isEmpty(filterObject) ? (
            <Button
              variant="contained"
              onClick={() => setIsCreateDialogOpen(true)}
              sx={{
                bgcolor: darkMode ? 'primary.dark' : 'primary.main',
                '&:hover': {
                  bgcolor: darkMode ? 'primary.main' : 'primary.dark',
                },
              }}
            >
              Add Contact
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={handleClearFilters}
              sx={styles.clearButton}
            >
              Clear Filters
            </Button>
          )}
        </Box>
      ) : (
        <>
          {viewMode === 'list' ? (
            <ContactsList
              onSelectionChange={handleContactsSelection}
              selectedContactIds={selectedContacts.map((contact) => contact.id)}
            />
          ) : viewMode === 'grid' ? (
            <ContactsDataGridV2
              onSelectionChange={handleContactsSelection}
              selectedContactIds={selectedContacts.map((contact) => contact.id)}
            />
          ) : (
            <ContactsKanban
              contacts={contacts as Contact[]}
              onSelectionChange={handleContactsSelection}
              selectedContactIds={selectedContacts.map((contact) => contact.id)}
            />
          )}
        </>
      )}

      <ContactCreateDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        handleContactUpdate={handleCreateContact}
      />

      <ContactsUploaderDialog />

      {multiEditDialogOpen && (
        <Suspense fallback={<div>Loading...</div>}>
          <MultiEditDialog
            open={multiEditDialogOpen}
            onClose={() => setMultiEditDialogOpen(false)}
            selectedContactIds={selectedContacts.map(contact => contact.id.toString())}
          />
        </Suspense>
      )}

      <BulkConversationCreateDialog
        open={isBulkDialogOpen}
        handleClose={() => setIsBulkDialogOpen(false)}
        selectedContacts={selectedContacts}
      />
    </Box>
  );
};

export default ContactsWrapper;
