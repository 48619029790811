import React, { useState } from 'react';
import { Button, Chip, Stack, Autocomplete, TextField, IconButton, Popover, Paper, Box, Badge } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { fetchConversationsWithMessages } from "src/features/account/conversations.slice";
import { useAppDispatch, useAppSelector } from 'src/store';
import ContactsSearchAutocomplete from '../Contacts/ContactsSearchAutocomplete';
import { useTheme } from '@mui/material/styles';
import { fetchFilteredConversations, setFilter, selectIsFilterActive } from 'src/features/account/filteredConversations.slice';

const statusOptions = [
  "Closed",
  "Error",
  "Awaiting Oppy",
  "Awaiting User",
  "Processing Reply",
  "Standby",
  "Needs Attention"
];

const ConversationsListFilterBar = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const isFilterActive = useAppSelector(selectIsFilterActive);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedContactId, setSelectedContactId] = useState("");
  const [subject, setSubject] = useState("");
  const [queryString, setQueryString] = useState("");
  const [latestDeliveryBefore, setLatestDeliveryBefore] = useState(null);
  const [latestMessageCreatedBefore, setLatestMessageCreatedBefore] = useState(null);
  const [lastUpdatedBefore, setLastUpdatedBefore] = useState(null);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusSelect = (event, newValue) => {
    setSelectedStatus(newValue);
  };

  const handleSubmit = () => {
    const statusFilter = (() => {
      switch (selectedStatus) {
        case "Closed": return "closed";
        case "Error": return "error";
        case "Awaiting Oppy": return "pending_assistant_reply";
        case "Awaiting User": return "pending_user_reply";
        case "Processing Reply": return "processing_autocomplete";
        case "Standby": return "standby";
        case "Needs Attention": return "needs_attention";
        default: return "";
      }
    })();

    const filterParams = {
      status: statusFilter,
      contactId: selectedContactId,
      subjectContains: subject,
      queryString,
      conversationLatestDeliveryAtBefore: latestDeliveryBefore,
      conversationLatestMessageCreatedAtBefore: latestMessageCreatedBefore,
      conversationLastUpdatedBefore: lastUpdatedBefore
    };

    // Set the filter in the filtered conversations slice
    dispatch(setFilter(filterParams));

    // Fetch filtered conversations
    dispatch(fetchFilteredConversations(filterParams));

    // Close the popover after applying filters
    handleClose();
  };

  const handleClearFilters = () => {
    setSelectedStatus("");
    setSelectedContactId("");
    setSubject("");
    setQueryString("");
    setLatestDeliveryBefore(null);
    setLatestMessageCreatedBefore(null);
    setLastUpdatedBefore(null);

    // Clear the filters in Redux
    dispatch(setFilter({}));
    dispatch(fetchFilteredConversations({}));

    // Close the popover
    handleClose();
  };

  const styles = {
    filterIcon: {
      color: isFilterActive ? 'primary.main' : (darkMode ? 'grey.300' : 'inherit'),
      '&:hover': {
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
      }
    },
    badge: {
      '& .MuiBadge-badge': {
        bgcolor: 'primary.main',
        color: 'common.white',
      }
    },
    popoverContent: {
      bgcolor: darkMode ? 'grey.900' : 'background.paper',
      color: darkMode ? 'grey.300' : 'text.primary',
      '& .MuiTextField-root': {
        '& .MuiInputBase-root': {
          color: darkMode ? 'grey.300' : 'text.primary',
        },
        '& .MuiInputLabel-root': {
          color: darkMode ? 'grey.400' : 'text.secondary',
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: darkMode ? 'grey.700' : 'rgba(0, 0, 0, 0.42)',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottomColor: darkMode ? 'grey.500' : 'rgba(0, 0, 0, 0.87)',
        },
      },
      '& .MuiAutocomplete-root': {
        '& .MuiInputBase-root': {
          color: darkMode ? 'grey.300' : 'text.primary',
        },
        '& .MuiAutocomplete-popupIndicator': {
          color: darkMode ? 'grey.400' : 'inherit',
        },
        '& .MuiAutocomplete-clearIndicator': {
          color: darkMode ? 'grey.400' : 'inherit',
        },
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 1,
      mt: 2
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Badge
        variant="dot"
        invisible={!isFilterActive}
        sx={styles.badge}
      >
        <IconButton onClick={handleFilterClick} sx={styles.filterIcon}>
          <FilterListIcon />
        </IconButton>
      </Badge>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: darkMode ? 0 : 1,
          component: Paper,
          sx: styles.popoverContent
        }}
      >
        <Stack spacing={2} sx={{ p: 2 }}>
          <Autocomplete
            id="status-filter"
            options={statusOptions}
            getOptionLabel={(option) => option}
            onChange={handleStatusSelect}
            value={selectedStatus || null}
            isOptionEqualToValue={(option, value) => option === value}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Filter by Status" placeholder="Select Status" />
            )}
          />
          <TextField
            label="Filter by Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            variant="standard"
            placeholder="Enter Subject"
          />
          <TextField
            label="Filter by Query String"
            value={queryString}
            onChange={(e) => setQueryString(e.target.value)}
            variant="standard"
            placeholder="Enter Query String"
          />
          <ContactsSearchAutocomplete onSelectContact={(contact) => setSelectedContactId(contact.id)} />
          <Box sx={styles.buttonContainer}>
            <Button
              variant="outlined"
              onClick={handleClearFilters}
              sx={{
                color: darkMode ? 'grey.300' : 'text.primary',
                borderColor: darkMode ? 'grey.700' : 'grey.300',
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                bgcolor: darkMode ? 'primary.dark' : 'primary.main',
                color: 'common.white',
                '&:hover': {
                  bgcolor: darkMode ? 'primary.main' : 'primary.dark',
                }
              }}
            >
              Apply
            </Button>
          </Box>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ConversationsListFilterBar;
