import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ThemeState {
  darkMode: boolean;
  fullScreen: boolean;
  activePage: string;
  activePageTitleText: string;
  activePageBodyStyles: object;
  isChannelsListOpen: boolean;
  isCreateChannelDialogOpen: boolean;
  openDialog: string;
}

const initialState: ThemeState = {
  darkMode: false,
  fullScreen: false,
  activePage: '',
  activePageTitleText: '',
  activePageBodyStyles: {},
  isChannelsListOpen: false,
  isCreateChannelDialogOpen: false,
  openDialog: '',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    enableDarkMode: (state) => {
      state.darkMode = true;
    },
    enableLightMode: (state) => {
      state.darkMode = false;
    },
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    toggleFullScreen: (state) => {
      state.fullScreen = !state.fullScreen;
    },
    setActivePageString: (state, action) => {
      state.activePage = action.payload;
    },
    setPageTitleText: (state, action) => {
      document.title = action.payload;
      state.activePageTitleText = action.payload;
    },
    setPageBodyStyles: (state, action) => {
      state.activePageBodyStyles = action.payload;
    },
    toggleChannelsList: (state, action: PayloadAction<boolean>) => {
      state.isChannelsListOpen = action.payload;
    },
    toggleCreateChannelDialog: (state) => {
      state.isCreateChannelDialogOpen = !state.isCreateChannelDialogOpen;
    },
    setOpenDialog: (state, action) => {
      state.openDialog = action.payload;
    }
  },
});

export const {
  enableDarkMode,
  enableLightMode,
  toggleDarkMode,
  toggleFullScreen,
  setActivePageString,
  setPageTitleText,
  setPageBodyStyles,
  toggleChannelsList,
  toggleCreateChannelDialog,
  setOpenDialog
} = themeSlice.actions;

export default themeSlice.reducer;
