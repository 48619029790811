import React from 'react';
import { Box, Typography } from '@mui/material';

interface DataItem {
  label: string;
  value: number;
  subtitle: string;
  color: string;
}

const TamSomSlide: React.FC = () => {
  // Step-by-step calculations with console logs
  const arpu = 20000;
  console.log(`ARPU (Average Revenue Per User): $${arpu}`);

  // Define variables with console logs
  const totalCustomersGlobal = 358_000_000;
  console.log(`Total Customers Global: ${totalCustomersGlobal.toLocaleString()}`);

  const totalCustomersUSCanada = 4_380_000;
  console.log(`Total Customers US & Canada: ${totalCustomersUSCanada.toLocaleString()}`);

  const totalBrokeragesUSCanada = 442_941;
  console.log(`Total Brokerages US & Canada: ${totalBrokeragesUSCanada.toLocaleString()}`);

  const penetrationRate = 0.10; // 10%
  console.log(`Penetration Rate: ${(penetrationRate * 100).toFixed(0)}%`);

  const SAM_BrokeragesUSCanada = 40_000_000_000; // $40B
  console.log(`SAM (Brokerages US & Canada): $${(SAM_BrokeragesUSCanada / 1e9).toFixed(2)}B`);

  // Additional markets
  const telecomGlobal = 1_800_000_000_000; // $1.8T
  const aiSpendingGlobal = 110_000_000_000; // $110B
  const additionalGlobalMarket = telecomGlobal + aiSpendingGlobal; // $1.91T
  console.log(
    `Additional Global Market: $${(telecomGlobal / 1e12).toFixed(2)}T telecom + $${(
      aiSpendingGlobal / 1e9
    ).toFixed(2)}B AI = $${(additionalGlobalMarket / 1e12).toFixed(2)}T`
  );

  const telecomUSCanada = 468_000_000_000; // $468B
  const aiSpendingUSCanada = 30_000_000_000; // $30B
  const additionalUSMarket = telecomUSCanada + aiSpendingUSCanada; // $498B
  console.log(
    `Additional US Market: $${(telecomUSCanada / 1e9).toFixed(2)}B telecom + $${(
      aiSpendingUSCanada / 1e9
    ).toFixed(2)}B AI = $${(additionalUSMarket / 1e9).toFixed(2)}B`
  );

  // Calculations with console logs
  const TAM_Global_Customers = totalCustomersGlobal * arpu; // $7.16T
  console.log(
    `TAM (Global Customers): ${totalCustomersGlobal.toLocaleString()} * $${arpu.toLocaleString()} = $${(
      TAM_Global_Customers / 1e12
    ).toFixed(2)}T`
  );

  const TAM_Global = TAM_Global_Customers + additionalGlobalMarket; // $9.07T
  console.log(`Total TAM (Global): $${(TAM_Global / 1e12).toFixed(2)}T`);

  const TAM_USCanada_Customers = totalCustomersUSCanada * arpu; // $87.6B
  console.log(
    `TAM (US & Canada Customers): ${totalCustomersUSCanada.toLocaleString()} * $${arpu.toLocaleString()} = $${(
      TAM_USCanada_Customers / 1e9
    ).toFixed(2)}B`
  );

  const TAM_USCanada = TAM_USCanada_Customers + additionalUSMarket; // $585.6B
  console.log(`Total TAM (US & Canada): $${(TAM_USCanada / 1e9).toFixed(2)}B`);

  const TAM_BrokeragesUSCanada = totalBrokeragesUSCanada * arpu; // $8.86B
  console.log(
    `TAM (Brokerages US & Canada): ${totalBrokeragesUSCanada.toLocaleString()} * $${arpu.toLocaleString()} = $${(
      TAM_BrokeragesUSCanada / 1e9
    ).toFixed(2)}B`
  );

  const SOM_BrokeragesUSCanada = SAM_BrokeragesUSCanada * penetrationRate; // $4B
  console.log(
    `SOM (Brokerages US & Canada): ${(penetrationRate * 100).toFixed(0)}% * $${(
      SAM_BrokeragesUSCanada / 1e9
    ).toFixed(2)}B = $${(SOM_BrokeragesUSCanada / 1e9).toFixed(2)}B`
  );

  // Updated data array with detailed subtitles
  const data: DataItem[] = [
    {
      label: 'Total Addressable Market (Global)',
      value: TAM_Global,
      subtitle: `${totalCustomersGlobal.toLocaleString()} customers * $${arpu.toLocaleString()} ARPU + $${(
        telecomGlobal / 1e12
      ).toFixed(2)}T telecom + $${(aiSpendingGlobal / 1e9).toFixed(2)}B AI = $${(
        TAM_Global / 1e12
      ).toFixed(2)}T TAM`,
      color: '#42A5F5',
    },
    {
      label: 'Total Addressable Market (US & Canada)',
      value: TAM_USCanada,
      subtitle: `${totalCustomersUSCanada.toLocaleString()} customers * $${arpu.toLocaleString()} ARPU + $${(
        telecomUSCanada / 1e9
      ).toFixed(2)}B telecom + $${(aiSpendingUSCanada / 1e9).toFixed(2)}B AI = $${(
        TAM_USCanada / 1e9
      ).toFixed(2)}B TAM`,
      color: '#90CAF9',
    },
    {
      label: 'Total Addressable Market (Brokerages (US & Canada))',
      value: TAM_BrokeragesUSCanada,
      subtitle: `${totalBrokeragesUSCanada.toLocaleString()} brokerages * $${arpu.toLocaleString()} ARPU = $${(
        TAM_BrokeragesUSCanada / 1e9
      ).toFixed(2)}B TAM`,
      color: '#BBDEFB',
    },
    {
      label: 'Serviceable Obtainable Market (Brokerages (US & Canada))',
      value: SOM_BrokeragesUSCanada,
      subtitle: `${(penetrationRate * 100).toFixed(0)}% penetration * $${(
        SAM_BrokeragesUSCanada / 1e9
      ).toFixed(2)}B SAM = $${(SOM_BrokeragesUSCanada / 1e9).toFixed(2)}B SOM`,
      color: '#FB8C00',
    },
  ];

  // Use the first data point for scaling
  const scalingValue = data[0].value;
  const calculateRadius = (value: number) => {
    const radius = Math.sqrt(value / scalingValue) * 80;
    console.log(`Calculated radius for value ${value}: ${radius}`);
    return radius;
  };

  const circleCenter = { x: 0, y: 50 }; // Center of the circles

  const formatCurrency = (value: number) => {
    if (value >= 1e12) return `$${(value / 1e12).toFixed(1)}T`;
    if (value >= 1e9) return `$${(value / 1e9).toFixed(1)}B`;
    if (value >= 1e6) return `$${(value / 1e6).toFixed(1)}M`;
    return `$${value.toLocaleString()}`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, // Stack as rows on small devices
        color: 'white',
        padding: 4,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
      }}
    >
      {/* Wrapper for heading and subheading */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        width: '100%',
        maxWidth: '300px',
      }}>
        <Typography variant="h3" align="center" sx={{ mb: 2 }}>
          Market Size For Business Phone + AI Roles
        </Typography>
        <Typography variant="subtitle1" align="center" sx={{ mb: 4 }}>
          TAM &amp; SOM represented by nested circles
        </Typography>
      </Box>
      {/* Visualization and bullets section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          width: '100%',
        }}
      >
        {/* Circles visualization */}
        <svg width="100%" height="50%" viewBox="0 0 90 100">
          {/* Draw circles */}
          {data.map((item, index) => {
            const radius = calculateRadius(item.value);
            console.log(`Circle radius for ${item.label}: ${radius}`);
            return (
              <circle
                key={index}
                cx={circleCenter.x}
                cy={circleCenter.y}
                r={radius}
                fill={item.color}
              />
            );
          })}
        </svg>
        {/* Bullets and text */}
        <Box
          sx={{
            width: { xs: '100%', sm: '100%' },
            ml: { xs: 0, sm: 2 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          {data.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
              {/* Colored bullet */}
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  bgcolor: item.color,
                  mt: '5px',
                  mr: 1,
                  flexShrink: 0,
                }}
              ></Box>
              {/* Text labels */}
              <Box sx={{ overflowWrap: 'break-word' }}>
                <Typography variant="h6" sx={{ color: 'white' }}>
                  {item.label}: {formatCurrency(item.value)}
                </Typography>
                <Typography variant="body2" sx={{ color: 'lightgray' }}>
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TamSomSlide;
