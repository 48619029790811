import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Button, IconButton, Icon } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { dateTimeFormatter } from "src/libs/dateTimeFormatter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { useAppDispatch, useAppSelector } from 'src/store';
import { deleteMessage, deliverMessageNow, updateMessage } from "src/features/account/messages.slice";
import { decrementMessageCount } from "src/features/account/conversations.slice";
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from "notistack";
import { getBranding } from "src/utils/branding";
import { useTheme, Theme } from '@mui/material/styles';
import { RootState } from 'src/store';
import { useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useColorMode } from 'src/theme/ThemeProvider';
import { alpha } from '@mui/material/styles';

// **Add the missing import for ConversationCard**
import ConversationCard from "./ConversationCard";

interface MessageProps {
  message: any;
  sendingEntity: any;
  channelId: any;
  fetchConversationsWithMessagesAction?: () => void;
  conversationDeliveryState?: string;
  variant?: string;
}

const Message: React.FC<MessageProps> = ({
  message,
  sendingEntity,
  channelId,
  fetchConversationsWithMessagesAction,
  conversationDeliveryState = '',
  variant = 'full'
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(true);
  const [updatedMessage, setUpdatedMessage] = useState(message.content);
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const [showRawContent, setShowRawContent] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.user);
  const { loading } = useAppSelector((state: RootState) => state.messages);
  const { enqueueSnackbar } = useSnackbar();
  const [conversation, setConversation] = useState(null);
  const [deliverNowButtonDisabled, setDeliverNowButtonDisabled] = useState(false);
  const theme = useTheme();
  const { mode } = useColorMode();
  const isDarkMode = mode === 'dark';
  const navigate = useNavigate();

  useEffect(() => {
    const extractJson = (text: string) => {
      if (!text) return null;
      const jsonPattern = /{"conversation"=>.*?}}/;
      const match = text.match(jsonPattern);
      return match ? match[0] : null;
    };

    const jsonStr = extractJson(message.raw_content);
    if (jsonStr) {
      try {
        const parsedData = JSON.parse(jsonStr.replace(/=>/g, ':').replace(/nil/g, 'null') || '{}');
        setConversation(parsedData.conversation);
      } catch (error) {
        console.error('Failed to parse conversation JSON:', error);
      }
    }
  }, [message.raw_content]);
  const toggleRawContent = () => {
    setShowRawContent(!showRawContent);
  };

  const handleReload = () => {
    fetchConversationsWithMessagesAction && fetchConversationsWithMessagesAction();
    setCurrentMessageId(null);
  };

  const setSuccessMessage = (msg: string) => {
    enqueueSnackbar(msg, {
      variant: 'success',
      autoHideDuration: 3000
    });
  };

  const setErrorMessage = (msg: string) => {
    enqueueSnackbar(msg, {
      variant: 'error',
      autoHideDuration: 3000
    });
  };

  const editMessage = () => {
    setCurrentMessageId(message.id);
    setShowEdit(false);
    dispatch(updateMessage({ messageId: message.id, content: updatedMessage, channelId: channelId })).unwrap()
      .then((response: any) => {
        setSuccessMessage(response.message);
      }).catch((error: any) => {
        setErrorMessage(error);
      }).finally(() => {
        handleReload();
      });
  };

  const handleDelete = () => {
    dispatch(deleteMessage({ messageId: message.id, channelId: channelId, conversationId: message.conversation_id }));
    dispatch(decrementMessageCount({ conversationId: message.conversation_id }));
  };
  const handleSendNow = () => {
    setCurrentMessageId(message.id);
    setDeliverNowButtonDisabled(true);
    dispatch(deliverMessageNow({ messageId: message.id, channelId: channelId })).unwrap()
      .then((response: any) => {
        setSuccessMessage(response.message);
      }).catch((error: any) => {
        setErrorMessage(error);
      }).finally(() => {
        handleReload();
      });
  };

  const handlePlaygroundNavigate = () => {
    navigate(`/playground?message_id=${message.id}`);
  };

  const isSelf = sendingEntity && sendingEntity.role === message.sender_role;

  return (
    <Box
      key={message.id}
      sx={{
        mb: variant === 'compact' ? '8px' : '24px',
        display: "flex",
        flexDirection: "column",
        width: "100%",
        opacity: message.sender_role === "function" ? 0.7 : 1,
        position: "relative",
        alignItems: message.sender_role === "user" ? "flex-end" : "flex-start",
        '&:hover .timestamp, &:hover .action-buttons': {
          transform: 'translateY(0)',
          opacity: 1,
        },
      }}
    >
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        mb: -.5,
        width: "100%",
        justifyContent: message.sender_role === "user" ? "flex-end" : "flex-start",
      }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "14px",
            textTransform: "capitalize",
            color: isDarkMode ? 'text.secondary' : 'rgba(0, 0, 0, 0.6)',
            mr: '4px',
            ml: '4px',
          }}
        >
          {message.sender_name}
          {message.sender_role === 'assistant' ? (
            <Box
              component="img"
              src={getBranding('oppy_expressions_no_mouth')}
              alt="Assistant Icon"
              sx={{ width: '20px', height: '20px', mt: -1, ml: 1 }}
            />
          ) : message.sender_role === 'user' ? (
            <Icon sx={{ fontSize: '16px', ml: .5 }}>person</Icon>
          ) : (
            <Icon onClick={toggleRawContent} sx={{ fontSize: '18px', ml: .5 }}>
              {showRawContent ? "code" : "computer"}
            </Icon>
          )}
        </Typography>
      </Box>
      {(loading && currentMessageId) ? <CircularProgress /> :
        (showEdit ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              gap: "10px",
              scrollbarWidth: "none",
              maxWidth: "90%",
              minWidth: "300px",
            }}
          >
            <TextField
              value={updatedMessage}
              InputLabelProps={{ shrink: false }}
              onChange={(e) => setUpdatedMessage(e.target.value)}
              defaultValue={message.content}
              multiline
              maxRows={8}
              variant="outlined"
              sx={{
                width: '100%',
                maxWidth: '90%',
                '& .MuiInputBase-inputMultiline': {
                  scrollbarWidth: 'none',
                  'msOverflowStyle': 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none'
                  },
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.6) : theme.palette.background.paper,
                  '& fieldset': {
                    borderColor: isDarkMode ? alpha(theme.palette.divider, 0.1) : theme.palette.divider,
                  },
                },
                '& .MuiInputBase-input': {
                  color: isDarkMode ? theme.palette.text.primary : theme.palette.text.primary,
                },
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "10px" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setShowEdit(false);
                  setUpdatedMessage(message.content);
                }}
                sx={{
                  color: isDarkMode ? theme.palette.text.primary : undefined,
                  borderColor: isDarkMode ? alpha(theme.palette.divider, 0.1) : undefined,
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={editMessage}>
                Update
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                position: 'relative',
                overflow: 'visible',
                maxWidth: '80%',
              }}
            >
              <Box
                sx={{
                  marginTop: 1,
                  padding: "16px 24px",
                  borderRadius: "8px",
                  color: isDarkMode ? theme.palette.text.primary : theme.palette.primary.contrastText,
                  backgroundColor: isDarkMode ? alpha(theme.palette.primary.main, 0.2) : alpha(theme.palette.primary.main, 0.8),
                  wordWrap: "break-word",
                  overflowWrap: "anywhere",
                  transition: "300ms ease-in-out",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  '& > *': {
                    margin: 0,
                    padding: 0,
                    overflowWrap: 'anywhere',
                  },
                  '& img': {
                    maxWidth: '50%',
                    display: 'block',
                    margin: '0 auto',
                    borderRadius: "8px",
                    transition: "300ms ease-in-out",
                    mb: 2,
                  },
                  '& img:hover': {
                    maxWidth: '90%',
                    transition: "300ms ease-in-out",
                  },
                  borderLeft: isSelf ? `6px solid ${theme.palette.primary.main}` : 'none',
                  '@keyframes fadeInOut': {
                    '0%, 100%': { borderLeftColor: 'transparent' },
                    '50%': { borderLeftColor: theme.palette.primary.main },
                  },
                  animation: isSelf ? 'fadeInOut 7s infinite' : 'none',
                }}
              >
                <Typography variant="body2"
                  sx={{
                    '& a': {
                      color: "inherit",
                      textDecoration: "underline"
                    },
                    opacity: message.status === "delivered" ? 1 : 0.7
                  }}
                >
                  {conversation ? (
                    <ConversationCard
                      conversation={conversation}
                      variant="small"
                      handleConversationsListToggleClick={undefined}
                    />
                  ) : null}
                  <Markdown>{showRawContent ? message.raw_content : message.content}</Markdown>
                </Typography>
                {message.status === "pending_delivery" &&
                  <Typography variant="body2" sx={{
                    fontSize: "14px",
                    opacity: 0.5,
                    mb: -1,
                    alignSelf: "flex-start",
                  }}>
                    {conversationDeliveryState === "pause" ? "Sending paused" : "Send @ " + dateTimeFormatter(message.deliver_after)}
                  </Typography>
                }
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: '-24px',
                  padding: '2px',
                  borderRadius: '4px',
                  transform: 'translateY(100%)',
                  opacity: 0,
                  transition: 'transform 0.3s ease, opacity 0.3s ease',
                  display: showActionButtons ? 'flex' : 'none',
                  gap: '2px',
                  backgroundColor: isDarkMode ? alpha(theme.palette.background.paper, 0.9) : 'rgba(255, 255, 255, 0.9)',
                  zIndex: 1,
                  '& .MuiIconButton-root': {
                    color: isDarkMode ? theme.palette.text.secondary : undefined,
                    '&:hover': {
                      backgroundColor: isDarkMode ? alpha(theme.palette.action.hover, 0.1) : undefined,
                    },
                  },
                }}
              >
                {user.role === 11 &&
                  <IconButton size="small" onClick={handlePlaygroundNavigate}>
                    <PlayArrowIcon fontSize="small" />
                  </IconButton>
                }
              </Box>
              {/** Only show action buttons if functions are provided */}
              {/* {(user.role === 11) && ( */}
              {message.sender_role !== 'user' && (
                <Box
                  className="action-buttons"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: '-24px',
                    padding: '4px 6px',
                    borderRadius: '6px',
                    transform: 'translateY(100%)',
                    opacity: 0,
                    transition: 'transform 0.3s ease, opacity 0.3s ease, background-color 0.2s ease',
                    display: showActionButtons ? 'flex' : 'none',
                    gap: '4px',
                    backgroundColor: isDarkMode ? 'rgba(38, 38, 38, 0.95)' : 'rgba(255, 255, 255, 0.95)',
                    boxShadow: isDarkMode
                      ? '0 2px 8px rgba(0, 0, 0, 0.4)'
                      : '0 2px 8px rgba(0, 0, 0, 0.1)',
                    backdropFilter: 'blur(8px)',
                    zIndex: 1,
                    '& .MuiIconButton-root': {
                      color: isDarkMode ? 'grey.300' : 'grey.700',
                      '&:hover': {
                        backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                        color: isDarkMode ? 'common.white' : 'common.black',
                      },
                    },
                  }}
                >
                  {user.role === 11 &&
                    <IconButton size="small" onClick={handlePlaygroundNavigate}>
                      <PlayArrowIcon fontSize="small" />
                    </IconButton>
                  }
                  <>
                    <IconButton size="small" onClick={() => setShowEdit(true)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={handleDelete}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    {message.status !== "delivered" && (
                      <IconButton size="small" onClick={handleSendNow}>
                        <SendIcon fontSize="small" />
                      </IconButton>
                    )}
                  </>
                </Box>
              )}
              {/* )} */}
            </Box>
            <Typography
              variant="body2"
              className="timestamp"
              sx={{
                fontSize: "14px",
                opacity: 0,
                transform: 'translateY(-20px)',
                transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
                position: "absolute",
                bottom: "-20px",
                left: message.sender_role === "user" ? 'auto' : 0,
                right: message.sender_role === "user" ? 0 : 'auto',
                zIndex: 1,
                color: isDarkMode ? theme.palette.text.secondary : undefined,
              }}
            >
              {dateTimeFormatter(message.created_at)}
            </Typography>
          </>
        ))}
    </Box>
  );
};
export default Message;