import React, { useState } from 'react';
import { Typography, Skeleton, Tooltip } from '@mui/material';
import theme from 'src/themes/oppy-default-theme';
import Box from '@mui/system/Box';
import { ContactCreateDialog } from 'src/components/Contacts/ContactCreateDialog';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "src/store";
import { useSelector } from 'react-redux';
import { selectChannelById } from 'src/features/account/channels.slice';
import { RootState } from 'src/store';
import type { Contact } from '../types';

const EntityCard = ({ entity, onClick = () => { }, styles }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const channel = useSelector((state: RootState) => selectChannelById(state, entity.channel_id));

  const getFunctionsList = () => {
    if (!channel) return '';

    const formatFunction = (func: string) =>
      func.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    const publicFunctions = channel.public_functions?.map(formatFunction) || [];
    const adminFunctions = channel.admin_functions?.map(formatFunction) || [];

    return [
      'Public Functions:',
      ...publicFunctions.map(func => `• ${func}`),
      '\n\n',  // Add two newlines for more space
      'Admin Functions:',
      ...adminFunctions.map(func => `• ${func}`)
    ].join('\n');
  };

  const handleEntityClick = () => {
    if (entity.role === 'assistant') {
      navigate(`/channels?channel_id=${entity.channel_id}`);
    } else if (entity.role === 'user') {
      const values = {
        phone: entity.tag === 'twilio' ? entity.identifier : undefined,
        email: entity.tag === 'email' ? entity.identifier : undefined,
      };
      setInitialValues(values);
      setDialogOpen(true);
    }
  };

  const handleContactCreate = (contact: Partial<Contact>) => {
    // Handle contact creation
    setDialogOpen(false);
  };

  return (
    <>
      <Tooltip title={entity.role === 'assistant' ? getFunctionsList() : ''} arrow>
        <Box sx={styles.wrapperBox} onClick={handleEntityClick}>
          {!entity.name ? (
            <>
              <Skeleton variant="circular" width={24} height={24} style={{ marginRight: 8 }} />
              <Skeleton variant="text" width={100} height={24} />
            </>
          ) : (
            <>
              <Typography component="span" sx={styles.name}>
                {entity.name}
              </Typography>
              {entity.identifier && entity.identifier !== entity.name && entity.role !== 'assistant' &&
                <Typography variant='caption' sx={styles.detailsRow}>
                  {entity.identifier.startsWith('ui_chat:') ? 'Web Chat' : `${entity.identifier} (${entity.role})`}
                </Typography>
              }
            </>
          )}
        </Box>
      </Tooltip>
      <ContactCreateDialog
        open={dialogOpen}
        initialValues={initialValues}
        onClose={() => setDialogOpen(false)}
        handleContactUpdate={handleContactCreate}
      />
    </>
  );
};

export default EntityCard;
