import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useAppSelector } from 'src/store';
import useDebounce from 'src/hooks/useDebounce';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import Edit from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { enqueueSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';

const EmbedKeyInput = ({ style = {}, channelId, initialEmbedKey, parentWrapperStyle = {}, textFieldStyle = {}, linkWrapperStyle = {} }) => {
  const [showInput, setShowInput] = useState(false);
  const [error, setError] = useState(false);
  const [label, setLabel] = useState('');
  const [value, setValue] = useState(initialEmbedKey);
  const debouncedValue = useDebounce(value, 700);
  const [loading, setLoading] = useState(false);
  const [currentEmbedKey, setCurrentEmbedKey] = useState(initialEmbedKey);
  const theme = useTheme();
  const { darkMode } = useAppSelector(state => state.theme);

  useEffect(() => {
    setValue(initialEmbedKey);
    setCurrentEmbedKey(initialEmbedKey)
  }, [channelId]);

  useEffect(() => {
    if (debouncedValue) {
      handleChange(debouncedValue);
    }
  }, [debouncedValue]);

  const handleChange = async (val) => {
    if (val.length < 3) {
      setError(true);
      setLabel('Url must be at least 3 characters long.');
      return;
    }

    if (debouncedValue === currentEmbedKey) {
      setError(false);
      setLabel('');
      return;
    }

    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.channel_url_available}?url_slug=${debouncedValue}`;

    try {
      const res = await httpClient.get(url);

      if (res && res.data && res.data.available) {
        setError(false);
        setLabel(`app.oppy.pro/s/${debouncedValue} is available!"`);
      } else {
        setError(true);
        setLabel(`app.oppy.pro/s/${debouncedValue} is not available!"`);
      }
    } catch (e) {
      console.error(e);

      setError(true);
      setLabel('Failed to check URL availability.');
    }
  }

  const handleInputChange = (event) => {
    const val = event.target.value;
    setValue(val);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(false);
    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_update_embed_key(channelId)}`;
    try {
      const res = await httpClient.post(url, { embed_key: debouncedValue });
      setCurrentEmbedKey(res.data.embed_key);
      setShowInput(false);
      setError(false);
      setLabel('');
      enqueueSnackbar(`Share link updated to app.oppy.pro/s/${res.data.embed_key}`, { variant: 'success' });
    } catch (e) {
      console.error(e);
      setError(true);
      setLabel('Failed to update URL.');
      setCurrentEmbedKey(initialEmbedKey);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box sx={parentWrapperStyle}>
        {showInput ?
          <TextField
            disabled={loading}
            onChange={handleInputChange}
            error={error}
            helperText={label}
            onBlur={() => {
              setValue(currentEmbedKey)
              setError(false)
              setLabel('')
              setShowInput(false)
            }
            }
            label='Public Link To This Oppy'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: darkMode ? 'grey.300' : 'grey.600' }}>
                  app.oppy.pro/s/
                </InputAdornment>
              ),
              endAdornment:
                <>
                  <IconButton
                    disabled={debouncedValue === currentEmbedKey}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => { handleSubmit(); }}
                    sx={{
                      color: darkMode ? 'grey.300' : 'action.active',
                      '&:hover': {
                        color: darkMode ? 'grey.100' : 'primary.main',
                      },
                    }}
                  >
                    {loading ? <CircularProgress sx={{ fontSize: '22px' }} /> : <SaveIcon sx={{ fontSize: '22px' }} />}
                  </IconButton>
                </>
            }}
            value={value}
            sx={{
              ...textFieldStyle,
              '& .MuiOutlinedInput-root': {
                bgcolor: darkMode ? 'grey.800' : 'background.paper',
                '& fieldset': {
                  borderColor: darkMode ? 'grey.700' : 'grey.300',
                },
                '&:hover fieldset': {
                  borderColor: darkMode ? 'grey.600' : 'grey.400',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
              '& .MuiInputBase-input': {
                color: darkMode ? 'grey.300' : 'text.primary',
              },
              '& .MuiInputLabel-root': {
                color: darkMode ? 'grey.300' : 'text.secondary',
              },
              '& .MuiFormHelperText-root': {
                color: error ? 'error.main' : (darkMode ? 'grey.300' : 'text.secondary'),
              },
            }}
          />
          :
          <Box sx={linkWrapperStyle} >
            <Link
              sx={{
                ...style,
                margin: 1,
                color: darkMode ? 'grey.100' : 'primary.main',
                '&:hover': {
                  color: darkMode ? 'primary.light' : 'primary.dark',
                },
              }}
              href={`https://app.oppy.pro/s/${currentEmbedKey}`}
              target="_blank"
              variant='body1'
            >
              {`app.oppy.pro/s/${currentEmbedKey}`}
            </Link>
            <IconButton
              onClick={() => setShowInput(true)}
              sx={{
                color: darkMode ? 'grey.400' : 'action.active',
                '&:hover': {
                  color: darkMode ? 'grey.300' : 'primary.main',
                  bgcolor: darkMode ? 'grey.800' : 'grey.100',
                },
              }}
            >
              <Edit sx={{ ...style, fontSize: "16px" }} />
            </IconButton>
          </Box>
        }
      </Box>
    </>
  );
};

export default EmbedKeyInput;

// END Parent Component frontend/src/components/Channels/PagesDialog.tsx
