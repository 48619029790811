import { FC, useEffect, useState } from 'react';
import { Box, Paper, Typography, Fade } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const floatUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(var(--random-x), -20px);
  }
`;

interface SearchChunk {
    id: string;
    content: string;
    chunk_type: string;
    similarity: number;
}

interface BubbleData {
    id: number;
    text: string;
    position: {
        x: number;
        y: number;
    };
    randomX: number;
}

interface ChatBubblesProps {
    businessUrl?: string;
    searchResults?: SearchChunk[];
}

const ChatBubbleWrapper = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: 'rgba(73, 73, 200, 0.1)',
    backdropFilter: 'blur(8px)',
    border: '1px solid rgba(73, 73, 200, 0.2)',
    maxWidth: '300px',
    animation: `${floatUpAnimation} 4s ease-out forwards`,
}));

const extractDisplayText = (content: string): string[] => {
    // Helper function to limit text length
    const limitLength = (text: string): string => {
        const cleaned = text.replace(/\s+/g, ' ').trim();
        return cleaned.length > 100 ?
            cleaned.substring(0, 100).trim() + '...' :
            cleaned;
    };

    // Helper function to convert Ruby hash syntax to JSON
    const convertRubyHashToJSON = (rubyHash: string): string => {
        return rubyHash.replace(/=>/g, ':');
    };

    // First try to parse as JSON
    try {
        if (typeof content !== 'string') {
            throw new Error('Content must be a string');
        }
        // Convert Ruby hash syntax to JSON before parsing
        const jsonString = convertRubyHashToJSON(content);
        const parsed = JSON.parse(jsonString);

        const texts: string[] = [];

        // Add title if available
        if (typeof parsed.title === 'string') {
            texts.push(limitLength(parsed.title));
        }

        // Add summary if available
        if (typeof parsed.summary === 'string') {
            texts.push(limitLength(parsed.summary));
        }

        // If we have either title or summary, return them
        if (texts.length > 0) return texts;

        // Check for other fields if no title/summary
        if (typeof parsed.text === 'string') return [limitLength(parsed.text)];
        if (typeof parsed.description === 'string') return [limitLength(parsed.description)];
        if (typeof parsed.content === 'string') return [limitLength(parsed.content)];

        // If it's an object but none of the above fields exist,
        // try to get the first string value
        const firstStringValue = Object.values(parsed).find((value): value is string => typeof value === 'string');
        if (firstStringValue) return [limitLength(firstStringValue)];
    } catch (e) {
        // Not JSON, try to extract meaningful text

        // Check for common patterns
        if (content.includes('Contact Information:')) {
            return [limitLength(content.split('\n').slice(0, 3).join('\n'))];
        }

        // Check for bullet points or numbered lists
        if (content.includes('•') || /^\d+\./.test(content)) {
            return [limitLength(content.split('\n').slice(0, 3).join('\n'))];
        }

        // Check for sentences and return first two
        const sentences = content.split(/[.!?]+\s+/);
        if (sentences.length > 1) {
            return [limitLength(sentences.slice(0, 2).join('. ') + '.')];
        }
    }

    // If all else fails, return a clean substring
    return [limitLength(content)];
};

const ChatBubbles: FC<ChatBubblesProps> = ({ businessUrl, searchResults }) => {
    const [bubbles, setBubbles] = useState<BubbleData[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isInitialBubble, setIsInitialBubble] = useState(true);

    // Constants for bubble behavior
    const BUBBLES_PER_GROUP = 4;
    const BUBBLE_LIFETIME = 12000;
    const GROUP_DELAY = 8000;
    const CYCLE_DELAY = 4000;

    // Show initial analyzing bubble when URL is provided
    useEffect(() => {
        if (!businessUrl) return;

        const initialBubble: BubbleData = {
            id: Date.now(),
            text: 'Analyzing website content...',
            position: {
                x: Math.random() * (window.innerWidth - 300),
                y: Math.random() * (window.innerHeight - 100)
            },
            randomX: Math.random() * 100 - 50
        };

        setBubbles(prev => [...prev, initialBubble]);

        // Remove initial bubble after animation
        setTimeout(() => {
            setBubbles(prev => prev.filter(b => b.id !== initialBubble.id));
            setIsInitialBubble(false);
        }, BUBBLE_LIFETIME);
    }, [businessUrl]);

    // Display search results as bubbles in groups
    useEffect(() => {
        if (!searchResults?.length || isInitialBubble) return;

        let timeouts: NodeJS.Timeout[] = [];

        const createBubble = (text: string): BubbleData => ({
            id: Date.now() + Math.random(),
            text,
            position: {
                x: Math.random() * (window.innerWidth - 300),
                y: Math.random() * (window.innerHeight - 100)
            },
            randomX: Math.random() * 100 - 50
        });

        const addBubbleGroup = () => {
            // Show multiple chunks at once
            for (let i = 0; i < BUBBLES_PER_GROUP; i++) {
                const index = (currentIndex + i) % searchResults.length;
                const chunk = searchResults[index];
                const texts = extractDisplayText(chunk.content);

                // Create a bubble for each text (title, summary, etc.)
                texts.forEach((text) => {
                    const newBubble = createBubble(text);
                    setBubbles(prev => [...prev, newBubble]);

                    // Remove this bubble after BUBBLE_LIFETIME
                    const removeTimeout = setTimeout(() => {
                        setBubbles(prev => prev.filter(b => b.id !== newBubble.id));
                    }, BUBBLE_LIFETIME);

                    timeouts.push(removeTimeout);
                });
            }

            // Update the current index to the next set
            setCurrentIndex(prev => (prev + BUBBLES_PER_GROUP) % searchResults.length);

            // Schedule the next group
            const nextGroupTimeout = setTimeout(() => {
                addBubbleGroup();
            }, GROUP_DELAY);

            timeouts.push(nextGroupTimeout);
        };

        // Start showing bubbles
        const initialTimeout = setTimeout(() => {
            addBubbleGroup();
        }, CYCLE_DELAY);

        timeouts.push(initialTimeout);

        // Cleanup function
        return () => {
            timeouts.forEach(timeout => clearTimeout(timeout));
        };
    }, [searchResults, currentIndex, isInitialBubble]);

    return (
        <Box sx={{
            position: 'fixed',
            inset: 0,
            pointerEvents: 'none',
            overflow: 'hidden',
            zIndex: 1
        }}>
            {bubbles.map(bubble => (
                <Fade key={bubble.id} in timeout={400}>
                    <ChatBubbleWrapper
                        style={{
                            left: bubble.position.x,
                            top: bubble.position.y,
                            '--random-x': `${bubble.randomX}px`
                        } as any}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'primary.light',
                                fontWeight: 500,
                                whiteSpace: 'pre-wrap'
                            }}
                        >
                            {bubble.text}
                        </Typography>
                    </ChatBubbleWrapper>
                </Fade>
            ))}
        </Box>
    );
};

export default ChatBubbles;