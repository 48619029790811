import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Button, Divider } from '@mui/material';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { login } from 'src/features/auth/auth.slice';
import { meta } from 'src/libs/utils';
import { useAppDispatch, useAppSelector } from 'src/store';
import DocumentMeta from 'react-document-meta';
import EmailLogin from './EmailLogin';
import PhoneLogin from './PhoneLogin';
import { getBranding } from 'src/utils/branding';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const Login = ({ displayedAuthScreen, setDisplayedAuthScreen }) => {
  const [smsLogin, setSmsLogin] = useState<boolean>(false);
  const branding_logo = getBranding('logo');

  return (
    <>
      <Typography variant='h3'
        sx={{
          opacity: 0.9,
        }}
      >Log In
      </Typography>
      <Typography variant='body2'
        sx={{
          opacity: 0.7,
          pb: 1
        }}
      >Sign in to your account or{' '}
        <Link
          onClick={() => setDisplayedAuthScreen('register')}
          color="primary"
          component={RouterLink}
          to="/register"
          sx={{ textDecoration: 'none', cursor: 'pointer', fontWeight: 'bold' }}
        >create a new one
        </Link>
      </Typography>
      <Box sx={{ mb: 2, mt: 4, width: '100%' }}>
        {smsLogin ?
          <PhoneLogin smsLogin={smsLogin} setSmsLogin={setSmsLogin} />
          :
          <EmailLogin smsLogin={smsLogin} setSmsLogin={setSmsLogin} setDisplayedAuthScreen={setDisplayedAuthScreen} />
        }
        <Box sx={{
          mt: 2,
          mb: 2,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
        >
          <Divider > or </Divider>
          {/* phone login or email login button depending on state */}
          {smsLogin ?
            <Button
              variant="outlined"
              type="submit"
              sx={{
                mt: 3,
                mb: 2,
                p: 2
              }}
              onClick={() => setSmsLogin(!smsLogin)}
            >
              Email Login
            </Button>
            :
            <Button
              variant="outlined"
              type="submit"
              sx={{
                mt: 3,
                mb: 2,
                p: 2,
              }}
              onClick={() => setSmsLogin(!smsLogin)}
            >
              Phone Login
            </Button>
          }
        </Box>
      </Box>
    </>
  );
};

export default Login;
