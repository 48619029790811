import { Box, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
  text?: string;
  path?: string;
  className?: string;
}

export default function GoBack({ text, path, className }: Props) {
  const theme = useTheme();

  return (
    <Box className={className}>
      <Link
        to={path}
        style={{ textDecoration: 'none' }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            color: 'text.primary',
            '&:hover': {
              color: theme.palette.mode === 'dark' ? 'primary.light' : 'primary.main',
            },
            transition: theme.transitions.create('color', {
              duration: theme.transitions.duration.shorter,
            }),
          }}
        >
          <Box
            component="img"
            src={theme.palette.mode === 'dark' ? "/icon/backChevronLight.svg" : "/icon/backChevron.svg"}
            sx={{
              mr: 1,
              width: 20,
              height: 20,
              filter: theme.palette.mode === 'dark' ? 'brightness(0.9)' : 'none',
            }}
          />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {text ? `${text}` : 'Go to dashboard'}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
}
