import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  fetchConversationWithMessages,
  removeConversationById,
  removeMessageFromConversation
} from 'src/features/account/conversations.slice';
import {
  getContact,
  removeContactState,
  selectAllContacts,
} from 'src/features/account/contacts.slice';
import {
  fetchChannel,
} from 'src/features/account/channels.slice';
import useAuth from 'src/hooks/useAuth';
import { getPusherInstance } from 'src/services/auth.service';
import LiveTranscriptionDialog from '../Lottie/LiveTranscriptionDialog';
import { getMessage } from 'src/features/account/messages.slice';
import IconButton from '@mui/material/IconButton';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import CloseIcon from '@mui/icons-material/Close';
import AuthService from 'src/services/auth.service';
import { fetchNotes } from 'src/features/notes/notes.slice';

const AccountNotifications = () => {
  const { isLoggedIn, authLoading } = useAuth();
  const { user } = useAppSelector(state => state.user);
  const contacts = useAppSelector(selectAllContacts);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pusherSubscriptionSucceeded, setPusherSubscriptionSucceeded] = useState(false);
  const [transcriptionDialogOpen, setTranscriptionDialogOpen] = useState(false);
  const [latestTranscriptionChunk, setLatestTranscriptionChunk] = useState(null);

  // Snackbar action for new messages
  const snackbarNewMessageAction = (key, message) => (
    <>
      <IconButton onClick={() => { navigate(`/conversations?conversation_id=${message.conversation_id}&channel_id=${message.channel_id}&message_id=${message.id}`); }}>
        <MarkUnreadChatAltIcon sx={{ color: 'white' }} />
      </IconButton>
      <IconButton onClick={() => closeSnackbar(key)}>
        <CloseIcon />
      </IconButton>
    </>
  );

  // Function to handle actions when a new message is received
  const newMessageActions = (conversation, message) => {
    console.log('newMessageActions called with conversation and message:', conversation, message);
    const senderEntity = conversation?.participating_entities?.find(entity => entity?.id === message?.sender_id);
    console.log('senderEntity:', senderEntity);

    const truncatedMessageContent = message.content.length > 100 ? message.content.substring(0, 100) + '...' : message.content;
    const senderString = senderEntity?.name ? `${senderEntity.name}: ` : '';
    const notiMessage = `${senderString}${truncatedMessageContent}`;
    console.log('Notification message:', notiMessage);

    // Play appropriate sound based on sender role
    if (senderEntity?.role === 'function') {
      console.log('Playing keyboard sound');
      new Audio('/audio/keyboard.mp3').play();
    } else {
      console.log('Playing message received sound');
      new Audio("/audio/message_received.mp3").play();
    }

    // Display snackbar notification
    enqueueSnackbar(notiMessage, {
      hideIconVariant: true,
      autoHideDuration: 4000,
      action: key => snackbarNewMessageAction(key, message)
    });
  };

  // for a new onlineUser add a enqueueSnackbar
  const newOnlineUserSnackbar = (onlineUser) => {
    enqueueSnackbar(`${onlineUser.name} is online`, {
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
    });
  }

  useEffect(() => {
    if (onlineUsers.length > 0) {
      newOnlineUserSnackbar(onlineUsers[0]);
    }
  }, [onlineUsers]);

  // Function to play sound for message creation
  const playSoundForMessageCreation = (senderRole) => {
    if (senderRole === 'function') {
      console.log('Playing keyboard sound');
      new Audio('/audio/keyboard.mp3').play();
    } else {
      console.log('Playing message received sound');
      new Audio("/audio/message_received.mp3").play();
    }
  };

  // Function to show snackbar notification
  const showSnackbarNotification = (notification) => {
    if (!notification || !notification.notification) return;
    const { title, body } = notification.notification;
    if (title && body) {
      const notiMessage = `${title}: ${body}`;
      console.log('Notification message:', notiMessage);

      enqueueSnackbar(notiMessage, {
        hideIconVariant: true,
        autoHideDuration: 4000,
        action: key => snackbarNewMessageAction(key, { title, body })
      });
    }
  };

  // Function to handle pusher events with debouncing
  // const handlePusherEvent = debounce((eventName, data) => {
  //   console.log(`Received event ${eventName}:`, data);

  //   if (eventName === 'message_created') {
  //     // Play sound for message creation
  //     const senderRole = data.sender_role; // Ensure sender_role is part of the data
  //     playSoundForMessageCreation(senderRole);

  //     // Show notification if notification payload exists
  //     const notification = data.message?.notification;
  //     showSnackbarNotification(notification);
  //   }

  //   if (eventName === 'message_created' || eventName === 'message_updated') {
  //     // Fetch the message
  //     dispatch(getMessage({ messageId: data.message.id }));
  //   }

  //   if (eventName === 'message_deleted') {
  //     console.log('message_deleted - pusher_data:', data);
  //     dispatch(removeMessageFromConversation({ conversationId: data.message.conversation_id, messageId: data.message.id }));
  //   }
  // }, 1000); // Debounce by 1 second

  useEffect(() => {
    if (isLoggedIn && user) {
      console.log('AccountNotifications - Setting up Pusher connection');

      // Initialize Pusher and ensure we're signed in
      AuthService.pusherSignin();

      // Get the Pusher instance
      const pusherInstance = getPusherInstance();

      if (pusherInstance && user.account?.uuid) {
        // Subscribe to presence channel for the account
        const channel = pusherInstance.subscribe('presence-account-' + user.account.uuid);

        // Handle subscription success
        channel.bind('pusher:subscription_succeeded', () => {
          console.log('Successfully subscribed to presence channel');
          setPusherSubscriptionSucceeded(true);
          enqueueSnackbar('Live connection active', {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
          });
        });

        // Handle subscription error
        channel.bind('pusher:subscription_error', (error) => {
          console.error('Presence channel subscription error:', error);
          setPusherSubscriptionSucceeded(false);
          enqueueSnackbar('Live connection failed', {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
          });
        });

        // Bind all the event handlers
        bindEvents(channel);

        // Cleanup function
        return () => {
          try {
            channel.unbind_all();
            pusherInstance.unsubscribe('presence-account-' + user.account.uuid);
          } catch (error) {
            console.error('Error cleaning up Pusher channel:', error);
          }
        };
      }
    }
  }, [isLoggedIn, user]);

  // Separate bindEvents into its own function for clarity
  const bindEvents = (channel) => {
    // Channel events
    ['channel_created', 'channel_updated', 'channel_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        console.log(`${eventName} - pusher_data:`, data);
        if (eventName === 'channel_created' || eventName === 'channel_updated') {
          dispatch(fetchChannel(data.channel.id));
        }
      });
    });

    // Conversation events
    ['conversation_created', 'conversation_updated', 'conversation_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        if (eventName === 'conversation_created' || eventName === 'conversation_updated') {
          console.log(`Fetching updated conversation for ${eventName}`);
          dispatch(fetchConversationWithMessages({
            conversationId: data.conversation.id,
            messagesLimit: 0
          }));
        }
        if (eventName === 'conversation_deleted') {
          console.log(`Deleting conversation for ${eventName}`);
          dispatch(removeConversationById(data.conversation.id));
        }
      });
    });

    // Message events
    ['message_created', 'message_updated', 'message_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        console.log(`${eventName} - pusher_data:`, data);

        if (eventName === 'message_created' || eventName === 'message_updated') {
          dispatch(getMessage({ messageId: data.message.id }));
        }

        if (eventName === 'message_created') {
          const senderRole = data?.role;
          playSoundForMessageCreation(senderRole);

          if (senderRole !== 'function' && data?.message?.notification) {
            showSnackbarNotification(data.message.notification);
          }
        }

        if (eventName === 'message_deleted') {
          dispatch(removeMessageFromConversation({
            conversationId: data.message.conversation_id,
            messageId: data.message.id
          }));
        }
      });
    });

    ['transcript_updated'].forEach(eventName => {
      console.log('eventName', eventName);
      channel.bind(eventName, (data) => {
        console.log('transcript_updated - pusher_data:', data);
        setLatestTranscriptionChunk(data.transcription); // Pass the new chunk
        if (!transcriptionDialogOpen) {
          setTranscriptionDialogOpen(true);
        }
      });
    });

    ['contact_created', 'contact_updated', 'contact_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        console.log(`${eventName} - pusher_data:`, data);
        if (data.contact && data.contact.id) {
          if (eventName === 'contact_created' || eventName === 'contact_updated') {
            dispatch(getContact(data.contact.id));
          } else if (eventName === 'contact_deleted') {
            dispatch(removeContactState(data.contact.id));
          }
        }
      });
    });

    ['entity_created', 'entity_updated', 'entity_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        console.log('entity_created - pusher_data:', data);
        // setLatestChannelPusherEvent({ eventName, data });
      });
    });

    ['faq_created', 'faq_updated', 'faq_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        console.log('faq updated - pusher_data:', data);
        // setLatestChannelPusherEvent({ eventName, data });
      });
    });

    // Add note events handling
    ['note_created', 'note_updated', 'note_deleted'].forEach(eventName => {
      channel.bind(eventName, (data) => {
        console.log(`${eventName} - pusher_data:`, data);

        if (data?.note) {
          // Fetch updated notes for the owner
          dispatch(fetchNotes({
            ownerType: data.note.owner_type,
            ownerId: data.note.owner_id
          }));

          // Show notification if present
          if (data.note?.notification?.notification) {
            const { title, body } = data.note.notification.notification;
            enqueueSnackbar(`${title}: ${body}`, {
              variant: 'info',
              autoHideDuration: 3000,
            });
          }
        }
      });
    });
  };

  return (
    <>
      <LiveTranscriptionDialog
        open={transcriptionDialogOpen}
        onClose={() => setTranscriptionDialogOpen(false)}
        latestTranscriptionChunk={latestTranscriptionChunk}
      />
    </>
  );
};

export default AccountNotifications;
