import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  SxProps,
  Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setOpenDialog } from '../../../features/theme/theme.slice';
import ContactsUploader from './ContactsUploader';
import { useAppSelector } from '../../../store';
import { useTheme } from '@mui/material/styles';

const ContactsUploaderDialog: React.FC = () => {
  const dispatch = useDispatch();
  const openDialog = useAppSelector((state) => state.theme.openDialog);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';

  useEffect(() => {
    if (openDialog === 'ContactsUploaderDialog') {
      setOpen(true);
    }
  }, [openDialog]);

  const handleClose = () => {
    dispatch(setOpenDialog(''));
    setOpen(false);
  };

  const styles: Record<string, SxProps<Theme>> = {
    dialog: {
      '& .MuiDialog-paper': {
        bgcolor: darkMode ? 'grey.900' : 'background.paper',
        color: darkMode ? 'grey.100' : 'text.primary',
        transition: 'all 0.3s ease',
      },
    },
    dialogTitle: {
      m: 0,
      p: 2,
      bgcolor: darkMode ? 'grey.900' : 'background.paper',
      color: darkMode ? 'grey.100' : 'text.primary',
      borderBottom: `1px solid ${darkMode ? theme.palette.grey[800] : theme.palette.grey[200]}`,
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: darkMode ? 'grey.300' : 'grey.500',
      '&:hover': {
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)',
      },
    },
    content: {
      p: 3,
      bgcolor: darkMode ? 'grey.900' : 'background.paper',
      color: darkMode ? 'grey.100' : 'text.primary',
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={styles.dialog}
    >
      <DialogTitle sx={styles.dialogTitle}>
        Upload Contacts
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <ContactsUploader />
      </DialogContent>
    </Dialog>
  );
};

export default ContactsUploaderDialog;
