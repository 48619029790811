import { Box, Container, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Settings as SettingsIcon, AccountCircle, Schedule, LocationOn } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import GoBack from 'src/components/GoBack';
import Layout from '../Layout';

const ProfileLayout = ({ children, activeKey }) => {
  return (
    <Layout>
      <Container maxWidth={false} sx={{ height: '100%', py: 3 }}>
        <Box display="flex" sx={{ minHeight: '100vh' }}>
          {/* Sidebar */}
          <Box
            sx={{
              width: { xs: '100%', md: 280 },
              flexShrink: 0,
              bgcolor: 'background.paper',
              borderRight: 1,
              borderColor: 'divider',
              p: 3,
            }}
          >
            <Box sx={{ mb: 3 }}>
              <GoBack
                path="/channels"
                text="Go Back"
              />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <SettingsIcon sx={{ mr: 1, color: 'text.primary' }} />
              <Typography variant="h5" color="text.primary">
                Settings
              </Typography>
            </Box>

            <List component="nav" sx={{
              '& .MuiListItem-root': {
                borderRadius: 1,
                mb: 1,
                '&:hover': {
                  bgcolor: 'action.hover',
                },
                '&.active': {
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  '& .MuiListItemIcon-root': {
                    color: 'inherit',
                  },
                  '& .MuiTypography-root': {
                    color: 'inherit',
                  },
                },
              }
            }}>
              <ListItem
                button
                component={Link}
                to="/profile"
                className={activeKey === 'account' ? 'active' : ''}
              >
                <ListItemIcon>
                  <AccountCircle color="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="text.primary">
                      Account
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" color="text.secondary">
                      Email, password and more
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/booking-availability"
                className={activeKey === 'booking' ? 'active' : ''}
              >
                <ListItemIcon>
                  <Schedule color="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="text.primary">
                      Your Availability
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" color="text.secondary">
                      Office/Working Hours
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/travel-and-location"
                className={activeKey === 'travel' ? 'active' : ''}
              >
                <ListItemIcon>
                  <LocationOn color="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="text.primary">
                      Advanced Scheduling Preferences
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" color="text.secondary">
                      Buffers, drive times and filters
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>

          {/* Main Content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              bgcolor: 'background.default',
              overflow: 'auto',
            }}
          >
            <Box sx={{ maxWidth: 'lg', mx: 'auto' }}>
              {children}
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default ProfileLayout;
