interface TimeZoneOption {
  label: string;
  value: string;
  continent: string;
  offset: string; // Basic reference; DST rules vary by location
}

export const timeZones: TimeZoneOption[] = [
  // NORTH AMERICA
  { label: 'Eastern Time (US)', value: 'America/New_York', continent: 'North America', offset: 'UTC-05:00/EDT-04:00' },
  { label: 'Central Time (US)', value: 'America/Chicago', continent: 'North America', offset: 'UTC-06:00/CDT-05:00' },
  { label: 'Mountain Time (US)', value: 'America/Denver', continent: 'North America', offset: 'UTC-07:00/MDT-06:00' },
  { label: 'Pacific Time (US)', value: 'America/Los_Angeles', continent: 'North America', offset: 'UTC-08:00/PDT-07:00' },
  { label: 'Alaska Time (US)', value: 'America/Anchorage', continent: 'North America', offset: 'UTC-09:00/AKDT-08:00' },
  { label: 'Hawaii (US)', value: 'Pacific/Honolulu', continent: 'North America', offset: 'UTC-10:00' },
  { label: 'Toronto', value: 'America/Toronto', continent: 'North America', offset: 'UTC-05:00/EDT-04:00' },
  { label: 'Vancouver', value: 'America/Vancouver', continent: 'North America', offset: 'UTC-08:00/PDT-07:00' },
  { label: 'Mexico City', value: 'America/Mexico_City', continent: 'North America', offset: 'UTC-06:00/CDT-05:00' },

  // CENTRAL/SOUTH AMERICA
  { label: 'Panama City', value: 'America/Panama', continent: 'Central America', offset: 'UTC-05:00' },
  { label: 'Bogotá', value: 'America/Bogota', continent: 'South America', offset: 'UTC-05:00' },
  { label: 'Caracas', value: 'America/Caracas', continent: 'South America', offset: 'UTC-04:00' },
  { label: 'Santiago', value: 'America/Santiago', continent: 'South America', offset: 'UTC-04:00/CLST-03:00' },
  { label: 'São Paulo', value: 'America/Sao_Paulo', continent: 'South America', offset: 'UTC-03:00' },
  { label: 'Buenos Aires', value: 'America/Argentina/Buenos_Aires', continent: 'South America', offset: 'UTC-03:00' },

  // EUROPE
  { label: 'London', value: 'Europe/London', continent: 'Europe', offset: 'UTC+00:00/BST+01:00' },
  { label: 'Lisbon', value: 'Europe/Lisbon', continent: 'Europe', offset: 'UTC+00:00/WEST+01:00' },
  { label: 'Madrid', value: 'Europe/Madrid', continent: 'Europe', offset: 'UTC+01:00/CEST+02:00' },
  { label: 'Paris', value: 'Europe/Paris', continent: 'Europe', offset: 'UTC+01:00/CEST+02:00' },
  { label: 'Berlin', value: 'Europe/Berlin', continent: 'Europe', offset: 'UTC+01:00/CEST+02:00' },
  { label: 'Rome', value: 'Europe/Rome', continent: 'Europe', offset: 'UTC+01:00/CEST+02:00' },
  { label: 'Moscow', value: 'Europe/Moscow', continent: 'Europe', offset: 'UTC+03:00' },
  { label: 'Istanbul', value: 'Europe/Istanbul', continent: 'Europe', offset: 'UTC+03:00' },

  // AFRICA
  { label: 'Casablanca', value: 'Africa/Casablanca', continent: 'Africa', offset: 'UTC+00:00/+01:00 DST' },
  { label: 'Lagos', value: 'Africa/Lagos', continent: 'Africa', offset: 'UTC+01:00' },
  { label: 'Johannesburg', value: 'Africa/Johannesburg', continent: 'Africa', offset: 'UTC+02:00' },
  { label: 'Cairo', value: 'Africa/Cairo', continent: 'Africa', offset: 'UTC+02:00' },
  { label: 'Nairobi', value: 'Africa/Nairobi', continent: 'Africa', offset: 'UTC+03:00' },

  // MIDDLE EAST / SOUTH ASIA
  { label: 'Dubai', value: 'Asia/Dubai', continent: 'Asia', offset: 'UTC+04:00' },
  { label: 'Tehran', value: 'Asia/Tehran', continent: 'Asia', offset: 'UTC+03:30/IRDT+04:30' },
  { label: 'Riyadh', value: 'Asia/Riyadh', continent: 'Asia', offset: 'UTC+03:00' },
  { label: 'Jerusalem', value: 'Asia/Jerusalem', continent: 'Asia', offset: 'UTC+02:00/IDT+03:00' },
  { label: 'Kolkata', value: 'Asia/Kolkata', continent: 'Asia', offset: 'UTC+05:30' },

  // EAST/SOUTHEAST ASIA
  { label: 'Bangkok', value: 'Asia/Bangkok', continent: 'Asia', offset: 'UTC+07:00' },
  { label: 'Jakarta', value: 'Asia/Jakarta', continent: 'Asia', offset: 'UTC+07:00' },
  { label: 'Singapore', value: 'Asia/Singapore', continent: 'Asia', offset: 'UTC+08:00' },
  { label: 'Beijing', value: 'Asia/Shanghai', continent: 'Asia', offset: 'UTC+08:00' },
  { label: 'Hong Kong', value: 'Asia/Hong_Kong', continent: 'Asia', offset: 'UTC+08:00' },
  { label: 'Seoul', value: 'Asia/Seoul', continent: 'Asia', offset: 'UTC+09:00' },
  { label: 'Tokyo', value: 'Asia/Tokyo', continent: 'Asia', offset: 'UTC+09:00' },

  // AUSTRALIA / OCEANIA
  { label: 'Perth', value: 'Australia/Perth', continent: 'Australia', offset: 'UTC+08:00' },
  { label: 'Darwin', value: 'Australia/Darwin', continent: 'Australia', offset: 'UTC+09:30' },
  { label: 'Adelaide', value: 'Australia/Adelaide', continent: 'Australia', offset: 'UTC+09:30/ACDT+10:30' },
  { label: 'Brisbane', value: 'Australia/Brisbane', continent: 'Australia', offset: 'UTC+10:00' },
  { label: 'Sydney', value: 'Australia/Sydney', continent: 'Australia', offset: 'UTC+10:00/AEDT+11:00' },
  { label: 'Auckland', value: 'Pacific/Auckland', continent: 'Oceania', offset: 'UTC+12:00/NZDT+13:00' },
  { label: 'Fiji', value: 'Pacific/Fiji', continent: 'Oceania', offset: 'UTC+12:00/+13:00 DST' },
];

export default timeZones;
