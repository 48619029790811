import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../store';
import { setGoogleCalendarToken, getUser } from '../features/user/user.slice';
import { sendAuthCompleteMessage } from '../libs/googleAuthorization';
import { Box, CircularProgress, Typography } from '@mui/material';

const GoogleCalendarCallback = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const handleCallback = async () => {
            const url = new URLSearchParams(location.search);
            const authorization_code = url.get('code');

            if (authorization_code) {
                try {
                    await dispatch(
                        setGoogleCalendarToken({
                            authorization_code,
                            redirect_uri: `${window.location.origin}/calendar-callback`
                        })
                    ).unwrap();

                    await dispatch(getUser()).unwrap();

                    // Send message to parent window and close this one
                    sendAuthCompleteMessage();
                } catch (error) {
                    console.error('Failed to connect calendar:', error);
                }
            }
        };

        handleCallback();
    }, [location, dispatch]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                gap: 2
            }}
        >
            <CircularProgress />
            <Typography>
                Connecting your calendar...
            </Typography>
        </Box>
    );
};

export default GoogleCalendarCallback; 