import { createTheme, ThemeOptions } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import 'typeface-inter';

const robotoFont = "Roboto, sans-serif";
const powerGroteskFont = "'Power Grotesk', sans-serif";
const powerGroteskLightFont = "'Power Grotesk Light', sans-serif";
const spaceGroteskFont = "'Space Grotesk', sans-serif";

// Oppy Brand Colors
const brandColors = {
    primary: '#000000', // Black
    secondary: '#4949C8', // Blue
    success: '#21D19F', // Teal
    error: '#ff3b3b', // Red
    accent: '#ceff1a', // Yellow
    info: '#0288D1',
};

// Status Colors
const statusColors = {
    closed: brandColors.success,
    error: '#C62828',
    pending_assistant_reply: '#FFC107',
    pending_user_reply: '#0288D1',
    processing_autocomplete: '#FFC107',
    standby: '#9E9E9E',
    needs_attention: '#F44336',
    loading: '#BBB5FF',
    loading_messages: '#BBB5FF',
};

// Status Animations
const statusAnimations = {
    closed: {},
    error: {
        animation: 'pulsate 1.5s infinite',
        '@keyframes pulsate': {
            '0%': { opacity: 0.5 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0.5 }
        }
    },
};

// Define custom colors and values that will be consistent across modes
const themeConstants = {
    borderRadius: 8,
    spacing: 8,
    typography: {
        fontFamily: spaceGroteskFont,
        h1: {
            fontFamily: powerGroteskFont,
            fontWeight: 500,
        },
        h2: {
            fontFamily: powerGroteskFont,
            fontWeight: 500,
        },
        h3: {
            fontFamily: powerGroteskFont,
            fontWeight: 500,
        },
        h4: {
            fontFamily: powerGroteskFont,
            fontWeight: 500,
        },
        h5: {
            fontFamily: powerGroteskFont,
            fontWeight: 500,
        },
        h6: {
            fontFamily: powerGroteskFont,
            fontWeight: 500,
        },
        body1: {
            fontFamily: spaceGroteskFont,
            lineHeight: 'normal',
        },
        body2: {
            fontFamily: spaceGroteskFont,
            lineHeight: 'normal',
        },
        subtitle1: {
            fontFamily: spaceGroteskFont,
            lineHeight: 'normal',
        },
        subtitle2: {
            fontFamily: spaceGroteskFont,
            lineHeight: 'normal',
        },
        button: {
            fontFamily: spaceGroteskFont,
            lineHeight: 'normal',
            textTransform: 'none' as const,
        },
        caption: {
            fontFamily: powerGroteskLightFont,
        },
        overline: {
            fontFamily: powerGroteskLightFont,
        },
    },
};

// Define colors for both light and dark modes
const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
    palette: {
        mode,
        common: {
            black: '#000000',
            white: '#ffffff',
        },
        primary: {
            main: mode === 'light' ? brandColors.primary : '#ffffff',
            contrastText: mode === 'light' ? '#ffffff' : '#000000',
        },
        secondary: {
            main: brandColors.secondary,
            contrastText: '#ffffff',
        },
        success: {
            main: brandColors.success,
            contrastText: '#ffffff',
        },
        error: {
            main: brandColors.error,
            contrastText: '#ffffff',
        },
        info: {
            main: brandColors.info,
            contrastText: '#ffffff',
        },
        background: {
            default: mode === 'light' ? '#ffffff' : '#121212',
            paper: mode === 'light' ? '#f5f5f5' : '#1e1e1e',
        },
        text: {
            primary: mode === 'light' ? '#000000de' : '#ffffff',
            secondary: mode === 'light' ? '#00000099' : '#ffffff99',
        },
        action: {
            active: mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
            hover: mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)',
            selected: mode === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.16)',
            disabled: mode === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
            disabledBackground: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
        },
    },
    ...themeConstants,
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Power Grotesk';
                    font-style: normal;
                    font-weight: 500;
                    src: local('Power Grotesk');
                }
                @font-face {
                    font-family: 'Power Grotesk Light';
                    font-style: normal;
                    font-weight: normal;
                    src: local('Power Grotesk Light');
                }
                * {
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                }
                *::-webkit-scrollbar {
                    display: none;
                }
                *::-webkit-scrollbar-thumb {
                    display: none;
                }
                *::-webkit-scrollbar-track {
                    display: none;
                }
                body {
                    font-family: ${spaceGroteskFont};
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: themeConstants.borderRadius,
                    textTransform: 'none',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: themeConstants.borderRadius,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: themeConstants.borderRadius,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: themeConstants.borderRadius,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontFamily: powerGroteskFont,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: 0,
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    marginRight: '-20px',
                }
            }
        },
    },
});

export const getTheme = (mode: PaletteMode) => {
    const theme = createTheme(getDesignTokens(mode));
    return {
        ...theme,
        statusColors,
        statusAnimations,
    };
}; 