import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Popper,
  MenuItem,
  MenuList,
  Paper,
  ClickAwayListener,
  Grow,
  useTheme,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SendIcon from '@mui/icons-material/Send';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import theme from 'src/themes/oppy-default-theme';
import { getBranding } from 'src/utils/branding';

export interface Message {
  method: string;
  subject?: string;
  content: string;
  mediaUrls: string[];
  deliverAfter?: string;
  sendingEntityRole?: 'assistant' | 'function' | 'user';
}

interface MessageInputWithUploaderProps {
  enablePreferred: boolean;
  enableSMS: boolean;
  enableEmail: boolean;
  disabled: boolean;
  onMessageChange: (message: Message) => void;
  onSendMessage?: (message: Message & { deliverAfter: string; sendingEntityRole: string }) => void; // Made optional
  channelName?: string;
}

const MessageInputWithUploader: React.FC<MessageInputWithUploaderProps> = ({
  enablePreferred,
  enableSMS,
  enableEmail,
  disabled,
  onMessageChange,
  onSendMessage,
  channelName,
}) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const [tabValue, setTabValue] = useState(0);
  const [currentMethod, setCurrentMethod] = useState<string>(
    enablePreferred ? 'preferred' : enableSMS ? 'phone' : 'email'
  );
  const [subjectValue, setSubjectValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const splitButtonRef = useRef<HTMLButtonElement>(null);

  const [sendingEntityRole, setSendingEntityRole] = useState<'assistant' | 'function'>('assistant');
  const [placeholderText, setPlaceholderText] = useState('');
  const [dateTimeValue, setDateTimeValue] = useState(new Date().toISOString().substring(0, 16));

  const entityRoles: ('assistant' | 'function')[] = ['assistant', 'function'];

  useEffect(() => {
    updatePlaceholderText(sendingEntityRole);
  }, [sendingEntityRole, channelName]);

  useEffect(() => {
    // Notify parent of changes
    onMessageChange({
      method: currentMethod,
      subject: currentMethod === 'email' ? subjectValue : undefined,
      content: inputValue,
      mediaUrls: imageUrls,
      sendingEntityRole,
      deliverAfter: dateTimeValue,
    });
  }, [
    currentMethod,
    subjectValue,
    inputValue,
    imageUrls,
    sendingEntityRole,
    dateTimeValue
  ]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    let method = 'preferred';
    if (enablePreferred) {
      method = newValue === 0 ? 'preferred' : newValue === 1 && enableSMS ? 'phone' : 'email';
    } else {
      method = newValue === 0 && enableSMS ? 'phone' : 'email';
    }
    setCurrentMethod(method);
  };

  const handleMediaUpload = () => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        multiple: true,
        resourceType: 'auto',
        sources: ['local', 'camera'],
        clientAllowedFormats: [
          'png', 'jpg', 'jpeg', 'gif', 'bmp', 'ico',
          'mp4', 'mov', 'avi', 'wmv', 'flv', 'webm', 'mkv',
          'mp3', 'wav', 'm4a', 'flac',
          'pdf'
        ],
        maxFileSize: 100 * 1024 * 1024,
        cropping: false,
        showPoweredBy: false,
        styles: {
          palette: {
            window: theme.palette.background.default,
            tabIcon: theme.palette.primary.main,
            menuIcons: theme.palette.secondary.main,
            textDark: theme.palette.text.primary,
            textLight: '#fff',
            link: theme.palette.primary.main,
            inactiveTabIcon: theme.palette.text.disabled,
            error: theme.palette.error.main,
            inProgress: theme.palette.info.main,
            complete: theme.palette.success.main,
            sourceBg: theme.palette.background.paper,
          },
          fonts: {
            default: theme.typography.fontFamily,
            "'Poppins', sans-serif": "https://fonts.googleapis.com/css?family=Poppins",
          },
          logo: getBranding('logo'),
        },
      },
      (error, result) => {
        if (!error && result.event === 'success') {
          const { public_id, resource_type, format } = result.info;

          let transformation = '';
          let adjusted_resource_type = resource_type;
          let adjusted_format = format;

          if (resource_type === 'image') {
            transformation = 'c_limit,w_1600,h_1600,q_auto:eco,f_auto';
          } else if (resource_type === 'video') {
            transformation = 'c_limit,w_1280,h_720,q_auto:eco,f_auto';
          } else if (resource_type === 'raw' && format === 'pdf') {
            transformation = 'c_limit,w_800,q_auto:eco,f_auto,page=1';
            adjusted_resource_type = 'image';
            adjusted_format = 'jpg';
          }

          const transformedUrl = transformation
            ? `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/${adjusted_resource_type}/upload/${transformation}/${public_id}.${adjusted_format}`
            : result.info.secure_url;

          setImageUrls([...imageUrls, transformedUrl]);
        } else if (error) {
          console.error('Upload error:', error);
        }
      }
    );
  };

  const handleDeleteMedia = (index) => {
    setImageUrls(imageUrls.filter((_, i) => i !== index));
  };

  const updatePlaceholderText = (entityRole: string) => {
    let placeholder = '';
    if (entityRole === 'assistant') {
      placeholder = `Write as your Oppy, ${channelName}...`;
    } else if (entityRole === 'function') {
      placeholder = `May the force be with you! Write an internal note to inform or prompt your Oppy, ${channelName}. Invisible to your contact, but ${channelName} will read it and keep relevant information in mind for future messages...`;
    } else {
      placeholder = 'Write your message...';
    }
    setPlaceholderText(placeholder);
  };

  // Function to toggle sending entity
  const handleToggleSendingEntity = () => {
    const currentIndex = entityRoles.indexOf(sendingEntityRole);
    const nextIndex = (currentIndex + 1) % entityRoles.length;
    const nextEntityRole = entityRoles[nextIndex];
    setSendingEntityRole(nextEntityRole);
  };

  // Handle sending message
  const handleSendMessage = () => {
    if (!inputValue.trim()) return;
    if (onSendMessage) {
      onSendMessage({
        method: currentMethod,
        subject: currentMethod === 'email' ? subjectValue : undefined,
        content: inputValue,
        mediaUrls: imageUrls,
        deliverAfter: dateTimeValue,
        sendingEntityRole,
      });
      // Reset input fields after sending
      setInputValue('');
      setImageUrls([]);
      setSubjectValue('');
    }
  };

  // Handle menu actions
  const handleMenuClick = () => {
    setAnchorEl(splitButtonRef.current);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
      >
        {enablePreferred && <Tab label="Preferred Method" />}
        {enableSMS && <Tab label="SMS" />}
        {enableEmail && <Tab label="Email" />}
      </Tabs>

      {currentMethod === 'email' && (
        <TextField
          label="Subject"
          fullWidth
          value={subjectValue}
          onChange={(e) => setSubjectValue(e.target.value)}
          sx={{ mt: 2 }}
          disabled={disabled}
        />
      )}

      <Box sx={{ position: 'relative' }}>
        <TextField
          multiline
          minRows={5}
          placeholder={placeholderText}
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          disabled={disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={handleToggleSendingEntity}>
                  {sendingEntityRole === 'assistant' ? (
                    <img
                      src={getBranding('oppy_expressions_no_mouth')}
                      alt="Assistant Icon"
                      style={{ width: '20px', height: '20px' }}
                    />
                  ) : (
                    <TipsAndUpdatesIcon />
                  )}
                </IconButton>
                <IconButton onClick={handleMediaUpload} disabled={disabled}>
                  <AttachFileIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {inputValue && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={handleSendMessage}>
                      <SendIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-controls={openMenu ? 'split-button-menu' : undefined}
                      aria-expanded={openMenu ? 'true' : undefined}
                      aria-label="select send time"
                      aria-haspopup="menu"
                      onClick={handleMenuClick}
                      ref={splitButtonRef}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </Box>
                )}
              </InputAdornment>
            ),
          }}
          sx={{ mt: 2 }}
        />

        {/* Move Popper outside of InputAdornment */}
        <Popper
          open={openMenu}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal
          sx={{ zIndex: 1300 }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center top' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem>
                      <TextField
                        id="datetime"
                        label="Send At"
                        type="datetime-local"
                        value={dateTimeValue}
                        onChange={(e) => setDateTimeValue(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          min: new Date().toISOString().substring(0, 16),
                        }}
                        fullWidth
                      />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>

      {/* Media Thumbnails */}
      <Box
        sx={{
          mt: 2,
          backgroundColor: darkMode ? 'grey.900' : 'grey.100',
          borderRadius: 1,
          p: 2,
          border: 1,
          borderColor: darkMode ? 'grey.800' : 'grey.200',
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {imageUrls.map((url, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                width: 120,
                height: 120,
                mr: 1,
                mb: 1,
                bgcolor: darkMode ? 'grey.800' : 'grey.50',
                borderRadius: 1,
                border: 1,
                borderColor: darkMode ? 'grey.700' : 'grey.300',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  borderColor: darkMode ? 'grey.600' : 'grey.400',
                },
              }}
              onMouseEnter={() => setActiveThumbnailIndex(index)}
              onMouseLeave={() => setActiveThumbnailIndex(null)}
              onClick={() => setActiveThumbnailIndex(index)}
            >
              {(() => {
                if (/\.(png|jpg|jpeg|gif)$/i.test(url)) {
                  return (
                    <img
                      src={url}
                      alt={`Uploaded ${index}`}
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                  );
                } else if (/\.(mp4|mov|avi|wmv|flv|webm|mkv)$/i.test(url)) {
                  return (
                    <video
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'contain' }}
                      controls
                    >
                      <source src={url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  );
                } else if (/\.(pdf)$/i.test(url)) {
                  return (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: darkMode ? 'grey.800' : 'grey.200',
                        color: darkMode ? 'grey.300' : 'grey.700',
                      }}
                    >
                      <PictureAsPdfIcon sx={{ fontSize: 48 }} />
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: darkMode ? 'grey.800' : 'grey.200',
                        color: darkMode ? 'grey.300' : 'grey.700',
                      }}
                    >
                      <InsertDriveFileIcon sx={{ fontSize: 48 }} />
                    </Box>
                  );
                }
              })()}
              {activeThumbnailIndex === index && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                  }}
                >
                  <IconButton
                    size="small"
                    sx={{
                      bgcolor: 'rgba(0,0,0,0.7)',
                      color: 'white',
                      '&:hover': {
                        bgcolor: 'rgba(0,0,0,0.85)',
                      },
                    }}
                    onClick={() => handleDeleteMedia(index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MessageInputWithUploader;
