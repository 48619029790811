import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import { updateChannel } from 'src/features/account/channels.slice';
import FollowUpSettingsForm from '../FollowUpSettingsForm';
import { FollowUpSettings } from 'src/types/followUpSettings';

interface ChannelFollowUpSettingsProps {
  channelId: string;
}

const DEFAULT_PROMPT = "Monitor this conversation and determine if a follow-up is needed. Consider: user engagement, unanswered questions, and potential next steps. If appropriate, draft and send a follow-up message.";

const ChannelFollowUpSettings: React.FC<ChannelFollowUpSettingsProps> = ({ channelId }) => {
  const dispatch = useAppDispatch();
  const channel = useAppSelector(state => state.channels.entities[channelId]);
  const [followUpSettings, setFollowUpSettings] = useState<FollowUpSettings>({
    enabled: channel?.follow_up?.enabled ?? false,
    delay_minutes: channel?.follow_up?.delay_minutes ?? 1440, // Default to Daily
    prompt: channel?.follow_up?.prompt ?? DEFAULT_PROMPT,
    respect_business_hours: channel?.follow_up?.respect_business_hours ?? false,
  });
  const [saving, setSaving] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [initialFollowUpSettings, setInitialFollowUpSettings] = useState<FollowUpSettings>(followUpSettings);

  // Initialize follow-up settings from channel
  React.useEffect(() => {
    if (channel && channel.follow_up) {
      const followUp = channel.follow_up || {};
      setFollowUpSettings({
        enabled: followUp.enabled ?? false,
        delay_minutes: followUp.delay_minutes ?? 1440,
        prompt: followUp.prompt ?? DEFAULT_PROMPT,
        respect_business_hours: followUp.respect_business_hours ?? false,
      });
      setInitialFollowUpSettings({
        enabled: followUp.enabled ?? false,
        delay_minutes: followUp.delay_minutes ?? 1440,
        prompt: followUp.prompt ?? DEFAULT_PROMPT,
        respect_business_hours: followUp.respect_business_hours ?? false,
      });
    }
  }, [channel]);

  const handleSave = async () => {
    try {
      setSaving(true);
      await dispatch(updateChannel({ channelData: { id: channel.id, follow_up: followUpSettings } })).unwrap();
      setIsExpanded(false);
      setInitialFollowUpSettings(followUpSettings);
    } catch (error) {
      console.error('Failed to update follow-up settings:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <FollowUpSettingsForm
      followUpSettings={followUpSettings}
      setFollowUpSettings={setFollowUpSettings}
      handleSave={handleSave}
      saving={saving}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      initialFollowUpSettings={initialFollowUpSettings}
    />
  );
};

export default ChannelFollowUpSettings; 
