import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Auth from './pages/Auth'
import Calendar from './components/Onboarding/Gcalendar/Calendar';
import useAuth from './hooks/useAuth';
import NotFound from './pages/404';
import Booking from './pages/Booking';
import EmbedBooking from './pages/EmbedBooking';
import OnBoarding from './pages/OnBoarding';
import OnBoardingV2 from './pages/OnBoardingV2';
import Redirect from './pages/Redirect';
import ServiceEdit from './pages/ServiceEdit';
import ServicesList from './pages/ServicesList';
import Profile from './pages/profile';
import TravelAndLocation from './pages/TravelAndLocation';
import ConfirmationMessage from './pages/ConfirmationMessage';
import BookingAvailability from './pages/BookingAvailability';
import GoogleCalendar from './pages/GoogleCalendar';
import BookingConfirmationV2 from './components/BookingConfirmationV2/BookingConfirmationV2';
import BookingCancelation from './components/BookingCancelation/BookingCancelation';
import UpdateEmail from './components/profile/UpdateEmail';
import PaymentProcessing from './pages/PaymentProcessing';
import CheckoutCallback from './components/Checkout/CheckoutCallback';
import Dashboard from './pages/dashboard';
import FinalizeInvoice from './components/FinalizeInvoice/FinalizeInvoice';
import SubscriptionCallback from './components/Subscription/SubscriptionCallback';
import ChannelManager from './components/Channels/ChannelManager';
import ChannelsPhonePurchaseSuccess from './pages/channels/PhonePurchaseSuccess';
import Contacts from './pages/contacts';
import PublicShare from './components/PublicShare/PublicShare'
import { ThemeProvider } from './theme/ThemeProvider';
import { useAppSelector } from './store';
import { SnackbarProvider, closeSnackbar } from 'notistack'
import { styled } from '@mui/system';
import ConversationsWrapper from './components/Channels/ConversationsWrapper';
import Layout from './pages/layout';
import { getPusherInstance, PusherContext } from './services/auth.service';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LottieWysiwyg from './components/Lottie/LottieWysiwyg';
import Playground from './components/Playground/Playground';
import Deck from './components/Deck/Deck';
import UserManager from './pages/dashboard/UserManager';
import GoogleCalendarCallback from './pages/GoogleCalendarCallback';

const params = new URLSearchParams(window.location.search)

const setCookies = (params) => {
  if (params.get("sign_in_redirect") != null || params.get("sign_in_redirect")?.length === 0) {
    document.cookie = `rmk_sign_in_redirect=${params.get('sign_in_redirect')}`;
  }
  if (params.get("category") != null || params.get("category")?.length === 0) {
    document.cookie = `rmk_category=${params.get('category')}`;
  }
  if (params.get("ref") != null || params.get("ref")?.length === 0) {
    document.cookie = `rmk_ref=${params.get('ref')}`;
  }
  if (params.get("account_invite") != null || params.get("account_invite")?.length === 0) {
    document.cookie = `rmk_account_invite=${params.get('account_invite')}`;
  }
}

setCookies(params)

const OppySnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '& .MuiSnackbar-root': {
    maxWidth: '100px',
  },
}));

const snackbarAction = (snackbarId) => {
  return (
    <IconButton onClick={() => closeSnackbar(snackbarId)}>
      <CloseIcon />
    </IconButton>
  );
}

const App = () => {
  const { isLoggedIn, authLoading } = useAuth();
  const { user } = useAppSelector(state => state.user)
  // useDarkModeEnable()

  return (
    <>
      {/* {authLoading && <Loading/>} */}
      <div className="App">
        <ThemeProvider>
          <PusherContext.Provider value={getPusherInstance()}>
            <OppySnackbarProvider
              autoHideDuration={3000}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              action={snackbarAction}
            >
              <Router>
                <Routes>
                  <Route path="/lottie" element={<LottieWysiwyg key="lottie" />} />
                  <Route path="/" element={<Auth screen={"register"} />} />
                  {/* <Route path="/pricing" element={<PricingPage />} /> */}
                  <Route path="/booking/:uuid" element={<Booking />} />
                  <Route path="/bookings/:uuid" element={<BookingConfirmationV2 />} />
                  <Route path="/b/:uuid" element={<Booking />} />
                  <Route path="/c/:uuid" element={<BookingConfirmationV2 />} />
                  <Route path="/s/:channel_embed_key" element={<PublicShare />} />
                  <Route path="/cancel-booking/:cancel_booking_token" element={<BookingCancelation />} />
                  <Route path="/profile/:email_token" element={<UpdateEmail />} />
                  <Route path="/checkout/cancel" element={<CheckoutCallback />} />
                  <Route path="/finalize_invoice/:booking_token" element={<FinalizeInvoice />} />
                  <Route path="/subscription/success" element={<SubscriptionCallback />} />
                  <Route path="/deck" element={<Deck />} />
                  {!(isLoggedIn && user) ? (
                    <>
                      <Route path="/login" element={<Auth screen={"login"} />} />
                      <Route path="/register" element={<Auth screen={"register"} />} />
                      <Route path="/on-boarding-v2" element={<OnBoardingV2 />} />
                      <Route path="/forgot_password" element={<Auth screen={"forgot_password"} />} />
                      <Route path="/reset_password" element={<Auth screen={"reset_password"} />} />
                      <Route path="/calendar-callback" element={<GoogleCalendarCallback />} />
                      <Route path="*" element={<Redirect isLoggedIn={isLoggedIn} authLoading={authLoading} />} />
                    </>
                  ) : (
                    <>
                      <Route path="*" element={<Redirect isLoggedIn={isLoggedIn} authLoading={authLoading} />} />
                      <Route path="/404" element={<NotFound />} />
                      <Route path="/on-boarding" element={<Layout components={[<OnBoarding key="on-boarding" />]} />} />
                      <Route path="/calendar" element={<Layout components={[<Calendar key="calendar" />]} />} />
                      <Route path="/services-list" element={<Layout components={[<ServicesList key="services-list" />]} />} />
                      <Route path="/dashboard" element={<Layout components={[<UserManager key="user-manager" user={user} />]} />} />
                      <Route path="/channels" element={<Layout components={[<ChannelManager key="channels" />]} />} />
                      <Route path="/contacts" element={<Layout components={[<Contacts key="contacts" />]} />} />
                      <Route path="/playground" element={<Layout components={[<Playground key="playground" />]} />} />
                      <Route path="/channels/phone_purchase_success" element={<Layout components={[<ChannelsPhonePurchaseSuccess key="channels-phone-purchase-success" />]} />} />
                      <Route path="/conversations" element={<Layout components={[<ConversationsWrapper key="conversations-wrapper" />]} />} />
                      <Route path="/embed-booking" element={<EmbedBooking key="embed-booking" />} />
                      <Route path="/service-edit/:id" element={<Layout components={[<ServiceEdit key="service-edit" />]} />} />
                      <Route path="/profile" element={<Profile key="profile" />} />
                      <Route path="/travel-and-location" element={<Layout components={[<TravelAndLocation key="travel-and-location" />]} />} />
                      <Route path="/confirmation-message" element={<Layout components={[<ConfirmationMessage key="confirmation-message" />]} />} />
                      <Route path="/booking-availability" element={<Layout components={[<BookingAvailability key="booking-availability" />]} />} />
                      <Route path="/google-calendar" element={<Layout components={[<GoogleCalendar key="google-calendar" />]} />} />
                      <Route path="/payment-processing" element={<Layout components={[<PaymentProcessing key="payment-processing" />]} />} />
                    </>
                  )}
                </Routes>
              </Router>
            </OppySnackbarProvider>
          </PusherContext.Provider>
        </ThemeProvider>
      </div>
    </>
  );
};

export default App;