import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Skeleton,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { trackAnalytics } from "src/features/analytics/analytics.slice";
import { httpClient } from "src/libs";
import { useAppDispatch, useAppSelector } from "src/store";
import { useSnackbar } from 'notistack';
import ContactsSearchAutocomplete from "../Contacts/ContactsSearchAutocomplete";
import { selectContactById } from "src/features/account/contacts.slice";
import { selectChannelById } from "src/features/account/channels.slice";
import { SelectChangeEvent } from '@mui/material/Select';
import MessageInputWithUploader from '../common/MessageInputWithUploader';

interface ConversationCreateDialogV2Props {
  open: boolean;
  handleClose: () => void;
  incomingContactId?: string | null;
  incomingDraftMessage?: string;
  incomingChannelId?: string | null;
}

const ConversationCreateDialogV2 = ({
  open,
  handleClose,
  incomingContactId = null,
  incomingDraftMessage = '',
  incomingChannelId = null,
}: ConversationCreateDialogV2Props) => {
  const { channels = [], channelsLoading } = useAppSelector(state => state.channels);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [postingMessage, setPostingMessage] = useState(false);
  const incomingChannel = useAppSelector(state => selectChannelById(state, incomingChannelId));
  const [selectedChannel, setSelectedChannel] = useState(incomingChannel || null);
  const [inputType, setInputType] = useState('email');
  const [selectedContactId, setSelectedContactId] = useState(incomingContactId);
  const selectedContact = useAppSelector(state => selectContactById(state, selectedContactId));
  const [phoneMethodAvailable, setPhoneMethodAvailable] = useState(false);
  const [emailMethodAvailable, setEmailMethodAvailable] = useState(false);
  const [message, setMessage] = useState({
    method: inputType,
    content: incomingDraftMessage,
    mediaUrls: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSendMessage = (messageData) => {
    const { method, subject, content, mediaUrls, deliverAfter, sendingEntityRole } = messageData;

    if (!selectedChannel) {
      setErrorMessage("Please select a channel");
      return;
    }
    if (!selectedContact) {
      setErrorMessage("Please select a contact");
      return;
    }
    if (!content.trim()) {
      setErrorMessage("Message content cannot be empty");
      return;
    }

    setPostingMessage(true);

    dispatch(trackAnalytics({ action_name: "user_create_conversation_start", payload: { description: `user create conversation start` } }));
    const url = `${process.env.REACT_APP_API_V3_URL}/chat/private/messages/post_message`;

    const data = {
      content: content,
      from_role: sendingEntityRole, // Use sendingEntityRole directly
      channel_id: selectedChannel.id,
      to_identifier: method === 'email' ? selectedContact.email : selectedContact.phone_number,
      to_tag: method === 'email' ? 'email' : 'sms',
      deliver_after: new Date(deliverAfter).toISOString(),
      to_role: 'user',
      media_urls: mediaUrls.length > 0 ? mediaUrls : undefined,
      subject: subject,
    };

    httpClient.post(url, data, { headers: { "Content-Type": "application/json" } })
      .then((response) => {
        dispatch(trackAnalytics({ action_name: "user_create_conversation_success", payload: { description: `user create conversation success` } }));
        setErrorMessage("");
        setMessage({ ...message, content: "" });
        setPostingMessage(false);
        handleClose();
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        enqueueSnackbar(`Error sending message: ${error.message}`, {
          variant: 'error',
          autoHideDuration: 3000
        });
        if (error.response) {
          setErrorMessage(`${error.response.data.error_code}: ${error.response.data.message}`);
        } else if (error.request) {
          setErrorMessage(`The request was made, but no response was received.`);
        } else {
          setErrorMessage(`Error: ${error.message}.`);
        }
        setPostingMessage(false);
      });
  };

  const handleSelectContact = (contact) => {
    setSelectedContactId(contact.id);

    // Only clear the channel if the current channel doesn't support the contact's methods
    if (selectedChannel) {
      const channelSupportsPhone = !!selectedChannel.twilio_phone_number;
      const channelSupportsEmail = !!selectedChannel.email_address;
      const contactHasPhone = !!contact.phone_number;
      const contactHasEmail = !!contact.email;

      if (!(channelSupportsPhone && contactHasPhone) && !(channelSupportsEmail && contactHasEmail)) {
        setSelectedChannel(null);
      }
    }

    // Set initial input type based on available methods
    if (contact.phone_number && selectedChannel?.twilio_phone_number) {
      setInputType('phone');
    } else if (contact.email && selectedChannel?.email_address) {
      setInputType('email');
    }
  };

  useEffect(() => {
    const phoneAvailable = !!(selectedChannel?.twilio_phone_number && selectedContact?.phone_number);
    const emailAvailable = !!(selectedChannel?.email_address && selectedContact?.email);
    setPhoneMethodAvailable(phoneAvailable);
    setEmailMethodAvailable(emailAvailable);

    if (!phoneAvailable && !emailAvailable) {
      setInputType('');
    } else if (!phoneAvailable && emailAvailable) {
      setInputType('email');
    } else if (phoneAvailable && !emailAvailable) {
      setInputType('phone');
    }
  }, [selectedContact, selectedChannel]);

  const handleChannelChange = (event: SelectChangeEvent<string>) => {
    const channelId = event.target.value;
    const channel = channels.find(channel => channel.id === channelId) || null;
    setSelectedChannel(channel);
  };

  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title">New Conversation</DialogTitle>
          <DialogContent>
            {!channelsLoading ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, gap: 2 }}>
                <ContactsSearchAutocomplete onSelectContact={handleSelectContact} />
                <FormControl fullWidth>
                  <InputLabel id="channels-simple-select-label">Send From</InputLabel>
                  <Select
                    labelId="channels-simple-select-label"
                    id="channels-simple-select"
                    value={selectedChannel?.id || ''}
                    onChange={handleChannelChange}
                    label="Pick sender Oppy"
                    required
                  >
                    {channels
                      .filter(channel => channel.email_address || channel.twilio_phone_number)
                      .map((channel) => (
                        <MenuItem
                          key={channel.id}
                          value={channel.id}
                          disabled={(inputType === 'phone' && !channel.twilio_phone_number) || (inputType === 'email' && !channel.email_address)}
                        >
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {channel.chatbot_name}
                          </Typography>
                          {channel?.twilio_phone_number && (
                            <Typography variant="body2" color="text.secondary">
                              {channel?.twilio_phone_number}
                            </Typography>
                          )}
                          {channel?.email_address && (
                            <Typography variant="body2" color="text.secondary">
                              {channel?.email_address}
                            </Typography>
                          )}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <MessageInputWithUploader
                  enablePreferred={false}
                  enableSMS={phoneMethodAvailable}
                  enableEmail={emailMethodAvailable}
                  disabled={postingMessage}
                  onMessageChange={(msg) => {
                    setMessage(msg);
                    setInputType(msg.method);
                  }}
                  onSendMessage={handleSendMessage}
                  channelName={selectedChannel?.chatbot_name || 'Oppy'}
                />
              </Box>
            ) : (
              <>
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={210} height={40} />
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={210} height={40} />
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={210} height={40} />
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={'100%'} height={70} />
              </>
            )}
          </DialogContent>
          {/* Remove existing DialogActions since sending is handled within MessageInputWithUploader */}
        </Dialog>
      )}
    </>
  );
};

export default ConversationCreateDialogV2;
