const SCOPES = "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly";
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/calendar-callback`;
const ONBOARDING_REDIRECT_URI = `${window.location.origin}/calendar-callback`;

const getParams = (isOnboarding: boolean) => ({
  client_id: CLIENT_ID,
  redirect_uri: isOnboarding ? ONBOARDING_REDIRECT_URI : REDIRECT_URI,
  response_type: "code",
  scope: SCOPES,
  access_type: "offline",
  include_granted_scopes: "true",
  prompt: 'consent'
});

export class GoogleAuthorization {
  private authEndpoint: string;
  private onAuthWindowClosed: Function;
  private authWindow: Window | null = null;
  private messageChannel: BroadcastChannel;

  constructor(onAuthWindowClosed: Function) {
    this.authEndpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    this.onAuthWindowClosed = onAuthWindowClosed;
    this.messageChannel = new BroadcastChannel('google-auth-channel');
    this.messageChannel.onmessage = (event) => {
      if (event.data === 'auth-complete') {
        this.authWindow?.close();
        this.onAuthWindowClosed();
      }
    };
  }

  authorizeUser = (event: React.MouseEvent, isOnboarding: boolean = false) => {
    event.preventDefault();

    const url = this.buildAuthorizationUrl(isOnboarding);
    this.authWindow = window.open(url.toString(), '_blank');
    if (this.authWindow) {
      this.pollWindowClosed(this.authWindow);
    }
  };

  private buildAuthorizationUrl(isOnboarding: boolean = false) {
    const url = new URL(this.authEndpoint);
    url.search = new URLSearchParams(getParams(isOnboarding)).toString();
    return url;
  }

  private pollWindowClosed = (authWindow: Window | null) => {
    const checkInterval = setInterval(() => {
      if (!authWindow || authWindow.closed) {
        clearInterval(checkInterval);
        this.messageChannel.close();
        this.onAuthWindowClosed();
      }
    }, 500);
  }
}

// Helper function to send auth complete message
export const sendAuthCompleteMessage = () => {
  const channel = new BroadcastChannel('google-auth-channel');
  channel.postMessage('auth-complete');
  channel.close();
};
