//BEGIN frontend/src/pages/channels/ChannelManager.tsx
import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, Typography, Skeleton, IconButton } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "src/store";
import Channel from 'src/components/Channels/ChannelV2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { selectAllChannels, selectChannelById } from 'src/features/account/channels.slice'
import { toggleChannelsList, toggleCreateChannelDialog } from 'src/features/theme/theme.slice';
import { fetchChannels } from 'src/features/account/channels.slice';
import OppyWave from '../Lottie/OppyWave';
import { useColorMode } from 'src/theme/ThemeProvider';

const ChannelManager: React.FC = () => {

  const theme = useTheme();
  const { mode } = useColorMode();
  const isDarkMode = mode === 'dark';
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const channelIdUrlParam = searchParams.get("channel_id")
  // const [focusedChannel, setFocusedChannel] = useState(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const channels = useAppSelector(selectAllChannels)
  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(null);
  const focusedChannel = useAppSelector(state => selectChannelById(state, selectedChannelId));
  const { channelsLoading, initialLoad, error } = useAppSelector(state => state.channels);
  const { user } = useAppSelector(state => state.user)
  const [createChannelDialog, setCreateChannelDialog] = useState(false);
  const { isChannelsListOpen } = useAppSelector(state => state.theme)

  const styles = {
    card: {
      position: 'relative',
      cursor: 'pointer',
      height: '150px',
      minWidth: '30px',
      pl: 2,
      pr: 2,
      pb: 2,
      pt: 1.5,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      alignItems: 'flex-start',
      gap: '10px',
      background: isDarkMode ? theme.palette.grey[900] : theme.palette.background.paper,
      borderRadius: '10px',
      '&:hover': {
        background: isDarkMode ? theme.palette.grey[800] : theme.palette.action.hover,
        boxShadow: theme.shadows[1]
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    channelsListWrapper: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '295px',
      width: '100%',
      maxWidth: '350px',
      maxHeight: '90vh',
      p: 2,
      position: 'absolute',
      left: isChannelsListOpen ? '0px' : '-385px',
      top: '100px',
      transition: '300ms',
      zIndex: 100,
      borderRadius: 3,
      backgroundColor: isDarkMode ? theme.palette.grey[900] : theme.palette.background.paper,
      boxShadow: theme.shadows[4],
    },
    channelsListHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100px',
      p: 2,
      zIndex: 100,
      boxShadow: theme.shadows[2],
    },
    channelsListScrollArea: {
      overflow: isChannelsListOpen ? 'scroll' : 'hidden',
      height: isChannelsListOpen ? '1000px' : '0px',
      transition: '300ms'
    },
    loadingBox: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      p: 2,
      borderRadius: 3,
      background: isDarkMode ? theme.palette.grey[900] : theme.palette.background.paper,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
      boxShadow: theme.shadows[1],
    },
    emptyIcon: {
      width: '250px',
      height: '250px',
      animation: 'pulse 2s infinite',
      filter: isDarkMode ? 'brightness(0.8)' : 'none',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)'
      },
      '50%': {
        transform: 'scale(1.05)'
      },
      '100%': {
        transform: 'scale(1)'
      }
    },
    focusedChannel: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      bgcolor: theme.palette.background.default,
    },
  };


  useEffect(() => {
    if (focusedChannel) {
      navigate(`/channels?channel_id=${focusedChannel.id}`)
    } else if (channelIdUrlParam) {
      setSelectedChannelId(channelIdUrlParam);
    } else if (channels.length > 0) {
      setSelectedChannelId(channels[0].id);
    }
  }, [focusedChannel]);

  useEffect(() => {
    if (channels.length > 0 && !selectedChannelId && !focusedChannel) {
      setSelectedChannelId(channels[0].id);
    }
  }, [channels]);

  useEffect(() => {
    if (channelIdUrlParam) {
      setSelectedChannelId(channelIdUrlParam);
      setSearchParams({ channel_id: channelIdUrlParam });
    }
  }, [channelIdUrlParam, setSelectedChannelId, channels, focusedChannel]);

  useEffect(() => {
    if (!user.has_channels) {
      setCreateChannelDialog(true);
    }
  }, [user]);

  const handleBack = () => {
    setCreateChannelDialog(false);
  };

  return (
    <>
      <Box sx={styles.container}>
        {!focusedChannel &&
          <Box sx={styles.loadingBox}>
            {channelsLoading &&
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '300px', height: '300px', maxWidth: '100%', maxHeight: '100%' }}>
                <OppyWave />
                <Typography variant='h6' color="text.primary">
                  Loading Oppies...
                </Typography>
              </Box>
            }
            {error && !channelsLoading && !focusedChannel &&
              <Box>
                <Typography color="text.primary">Having trouble connecting to your Oppies. {error}</Typography>
                <Button onClick={() => dispatch(fetchChannels())}>Retry</Button>
              </Box>
            }
            {channelsLoading && !initialLoad && channels.length === 0 &&
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{
                  background: isDarkMode ? theme.palette.grey[900] : theme.palette.background.paper,
                }}
              />
            }
            {!focusedChannel && initialLoad && channels.length === 0 && !channelsLoading &&
              <Box sx={styles.emptyState} onClick={() => dispatch(toggleCreateChannelDialog())}>
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    mb: -4,
                    color: theme.palette.text.primary
                  }}
                >
                  Make your first Oppy in a few clicks!
                </Typography>
                <img style={styles.emptyIcon} src="/images/oppies/Oppy_Expressions_smiling.svg" alt="No Channels" />
                <Button sx={{ zIndex: 100 }} variant="outlined" color="secondary">+ New Oppy</Button>
              </Box>
            }
          </Box>
        }
        {focusedChannel &&
          <Box sx={styles.focusedChannel}>
            {focusedChannel && <Channel channelId={focusedChannel.id} />}
          </Box>
        }
      </Box>
    </>
  );
};

export default ChannelManager;

//end frontend/src/pages/channels/ChannelManager.tsx
