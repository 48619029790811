import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { getTheme } from './theme';
import CssBaseline from '@mui/material/CssBaseline';

// Create a context for theme mode
interface ThemeContextType {
    mode: PaletteMode;
    toggleColorMode: () => void;
}

const ThemeContext = createContext<ThemeContextType>({
    mode: 'light',
    toggleColorMode: () => { },
});

// Custom hook to use theme context
export const useColorMode = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useColorMode must be used within a ThemeProvider');
    }
    return context;
};

interface ThemeProviderProps {
    children: React.ReactNode;
}

const THEME_MODE_KEY = 'oppy_theme_mode';

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    // Get initial theme preference from localStorage or system
    const getInitialMode = (): PaletteMode => {
        const savedMode = localStorage.getItem(THEME_MODE_KEY);
        if (savedMode && (savedMode === 'light' || savedMode === 'dark')) {
            return savedMode;
        }
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    };

    const [mode, setMode] = useState<PaletteMode>(getInitialMode);

    // Update localStorage when mode changes
    useEffect(() => {
        localStorage.setItem(THEME_MODE_KEY, mode);
    }, [mode]);

    const colorMode = useMemo(
        () => ({
            mode,
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [mode]
    );

    const theme = useMemo(() => getTheme(mode), [mode]);

    return (
        <ThemeContext.Provider value={colorMode}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
}; 