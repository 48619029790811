import { createAsyncThunk, createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { serializeErrorMessage } from 'src/utils';
import ContactsService from 'src/services/contacts.services';
import { enqueueSnackbar } from 'notistack';
import { RootState } from 'src/store';
import { debounce } from 'lodash';
import { Contact } from 'src/types/';
import { parseISO, isValid } from 'date-fns';

export const contactsAdapter = createEntityAdapter<Contact>({
  selectId: (contact) => contact.id,
  sortComparer: (a, b) => {
    if (!a.created_at || !b.created_at) return 0;
    const dateAStr = a.created_at instanceof Date ? a.created_at.toISOString() : a.created_at;
    const dateBStr = b.created_at instanceof Date ? b.created_at.toISOString() : b.created_at;
    return dateBStr.localeCompare(dateAStr); // Sort by most recently created first
  },
});

const initialState = contactsAdapter.getInitialState({
  contactsLoading: false,
  error: null,
  initialLoad: false,
  totalContacts: 0,
  creatingContact: false,
  creatingContactError: null,
  updatingContactsIds: [],
  loadingContactsIds: [],
  earliestContactCreatedAtDateTime: new Date().toISOString()
});

export const getContacts = createAsyncThunk(
  // gets all contacts with pagination and filters
  'contacts/get',
  async (queryParams: Partial<Contact> = {}, thunkAPI) => {
    try {
      const response = await ContactsService.fetch_contacts(queryParams);
      if (response.data.success) {
        return {
          contacts: response.data.contacts,
          totalContacts: response.data.total_count,
          hasMore: response.data.has_more
        };
      } else {
        return thunkAPI.rejectWithValue('Failed to fetch contacts');
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getContact = createAsyncThunk(
  // gets a single contact by id  
  'contacts/getContact', // Ensure the action type string is unique and correctly used in the reducer
  async (contactId: string, thunkAPI) => {
    try {
      const response = await ContactsService.show(contactId);
      if (response.data.success && response.data.contact) {
        return response.data.contact;
      } else {
        return thunkAPI.rejectWithValue('No contacts found');
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateContact = createAsyncThunk(
  'contacts/update',
  async ({ contactId, updates }: { contactId: string; updates: Partial<Contact> }, thunkAPI) => {
    try {
      // Restructure the payload to match the API expectations
      // updatingcontactids push this contact id

      const response = await ContactsService.update({
        id: contactId,
        contact: updates  // This will be sent directly to the API
      });

      if (response.data.success && response.data.contact) {
        return response.data.contact;
      } else {
        return thunkAPI.rejectWithValue('Failed to update contact');
      }
    } catch (error) {
      enqueueSnackbar('Error updating contact', { variant: 'error' });
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteContact = createAsyncThunk(
  'contacts/delete',
  async (contactId: string, thunkAPI) => {
    try {
      const response = await ContactsService.destroy(contactId);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createContact = createAsyncThunk(
  'contacts/create',
  async (contactData: Partial<Contact>, thunkAPI) => {
    try {
      const response = await ContactsService.create(contactData);
      console.log('create contact response', response);
      console.log('create contact response data', response.data);
      console.log('create contact response data contact', response.data.contact);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Exported function to reorder contact
export const reorderContactState = (contactId: string, position: number, stage: string) => (dispatch: any) => {
  // Dispatch updateContact with the new position and stage
  dispatch(updateContact({ contactId, updates: { position, stage } }));
  console.log(`Reordered contact ${contactId} to position ${position} in stage ${stage}`);
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: initialState,
  reducers: {
    updateContactState: contactsAdapter.upsertOne,
    removeContactState: contactsAdapter.removeOne,
  },
  extraReducers: builder => {
    builder
      .addCase(createContact.fulfilled, (state, action) => {
        contactsAdapter.addOne(state, action.payload);
        state.contactsLoading = false;
        state.error = null;
      })
      .addCase(createContact.pending, (state) => {
        state.creatingContact = true;
        state.error = null;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.creatingContact = false;
        state.error = action.payload as string;
        enqueueSnackbar('Error creating contact', { variant: 'error' });
      })
      .addCase(getContacts.pending, (state) => {
        state.contactsLoading = true;
        state.error = null;
        state.initialLoad = true;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        const existingContacts = contactsAdapter.getSelectors().selectAll(state);
        const newContacts = action.payload.contacts;

        // Compare dates properly using parseISO
        const isDifferent = newContacts.some(newContact => {
          return !existingContacts.some(existing => {
            if (existing.id !== newContact.id) return false;
            if (!existing.updated_at || !newContact.updated_at) return true;

            const existingDate = existing.updated_at instanceof Date ?
              existing.updated_at.toISOString() :
              existing.updated_at;
            const newDate = newContact.updated_at instanceof Date ?
              newContact.updated_at.toISOString() :
              newContact.updated_at;

            return existingDate === newDate;
          });
        });

        if (isDifferent) {
          const normalizedContacts = newContacts.map(contact => ({
            ...contact,
            name: contact.name ?? '',
            phone_number: contact.phone_number ?? '',
            email: contact.email ?? '',
            first_name: contact.first_name ?? '',
            last_name: contact.last_name ?? '',
            // Store dates as ISO strings
            created_at: contact.created_at ?
              (contact.created_at instanceof Date ? contact.created_at.toISOString() : contact.created_at) :
              undefined,
            updated_at: contact.updated_at ?
              (contact.updated_at instanceof Date ? contact.updated_at.toISOString() : contact.updated_at) :
              undefined,
            last_contacted: contact.last_contacted ?
              (contact.last_contacted instanceof Date ? contact.last_contacted.toISOString() : contact.last_contacted) :
              undefined
          }));

          contactsAdapter.upsertMany(state, normalizedContacts);
          state.totalContacts = action.payload.totalContacts;

          // Handle earliest contact date
          state.earliestContactCreatedAtDateTime = newContacts.reduce((earliest, contact) => {
            if (!contact.created_at) return earliest;
            const contactDateStr = contact.created_at instanceof Date ?
              contact.created_at.toISOString() :
              contact.created_at;
            return contactDateStr < earliest ? contactDateStr : earliest;
          }, state.earliestContactCreatedAtDateTime);
        }
        state.contactsLoading = false;
        state.error = null;
        state.initialLoad = true;
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.contactsLoading = false;
        state.error = action.payload as string;
        state.initialLoad = false;
      })
      .addCase(getContact.fulfilled, (state, action) => {
        const contact = action.payload;
        const normalizedContact = {
          ...contact,
          name: contact.name ?? '',
          phone_number: contact.phone_number ?? '',
          email: contact.email ?? '',
          first_name: contact.first_name ?? '',
          last_name: contact.last_name ?? '',
          // Ensure dates are stored as ISO strings
          created_at: contact.created_at ?
            (contact.created_at instanceof Date ? contact.created_at.toISOString() : contact.created_at) :
            undefined,
          updated_at: contact.updated_at ?
            (contact.updated_at instanceof Date ? contact.updated_at.toISOString() : contact.updated_at) :
            undefined,
          last_contacted: contact.last_contacted ?
            (contact.last_contacted instanceof Date ? contact.last_contacted.toISOString() : contact.last_contacted) :
            undefined
        };
        state.loadingContactsIds = state.loadingContactsIds.filter(id => id !== contact.id);
        contactsAdapter.upsertOne(state, normalizedContact);
        state.error = null;
      })
      .addCase(getContact.pending, (state, action) => {
        state.loadingContactsIds.push(action.meta.arg);
        state.error = null;
      })
      .addCase(getContact.rejected, (state, action) => {
        state.loadingContactsIds = state.loadingContactsIds.filter(id => id !== action.meta.arg);
        state.error = action.payload as string;
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        // based on the contact id in payload, set the contact to loading false
        const contact = action.payload?.id ? state.entities[action.payload.id] : null;
        if (contact) {
          contact.loading = false;
        }
        if (action.payload?.id) {
          contactsAdapter.upsertOne(state, action.payload);
        }
        state.error = null;
      })
      .addCase(updateContact.pending, (state, action) => {
        // based on the contact id in payload, set the contact to loading true
        const contact = action.meta.arg.contactId ? state.entities[action.meta.arg.contactId] : null;
        if (contact) {
          contact.loading = true;
        }
        if (action.meta.arg.contactId) {
          state.updatingContactsIds.push(action.meta.arg.contactId);
        }
        state.error = null;
      })
      .addCase(updateContact.rejected, (state, action) => {
        // based on the contact id in payload, set the contact to loading false
        const contact = action.meta.arg.contactId ? state.entities[action.meta.arg.contactId] : null;
        if (contact) {
          contact.loading = false;
        }
        state.error = action.payload as string;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        // based on the contact id in payload, set the contact to deleting false
        const contact = state.entities[action.payload.id];
        if (contact) {
          contact.deleting = false;
        }
        contactsAdapter.removeOne(state, action.payload);
        state.error = null;
      })
      .addCase(deleteContact.pending, (state, action) => {
        // find the contact in the state
        const contact = state.entities[action.meta.arg];
        if (contact) {
          contact.deleting = true;
        }
        state.error = null;
      })
      .addCase(deleteContact.rejected, (state, action) => {
        const contact = state.entities[action.meta.arg];
        if (contact) {
          contact.deleting = false;
        }
        state.error = action.payload as string;
      });
  },
});

export const { updateContactState, removeContactState } = contactsSlice.actions;

export const {
  selectById: selectContactById,
  selectAll: selectAllContacts,
  selectEntities: selectContactEntities,
} = contactsAdapter.getSelectors<RootState>((state) => state.contacts);

export default contactsSlice.reducer;
