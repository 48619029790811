import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
  Checkbox,
  Button,
  FormControlLabel,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContactDialog from './ContactDialog';
import { useAppDispatch, useAppSelector } from 'src/store';
import { deleteContact, selectContactById, updateContact } from 'src/features/account/contacts.slice';
import { selectAllFilteredContacts, fetchFilteredContacts } from 'src/features/account/filteredContacts.slice';
import { Contact } from 'src/types/contacts';
import { styled } from '@mui/material/styles';
import { Star } from '@mui/icons-material';

interface ContactsListProps {
  onSelectionChange: (selectedIds: string[]) => void;
  selectedContactIds: string[]; // Add this prop
}

interface ContactListItemProps {
  contactId: string;
  selectedIds: string[];
  handleToggle: (contactId: string) => void;
  setEditDialogOpen: (open: boolean) => void;
  setSelectedContactId: (contactId: string) => void;
}

const ConfirmButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  minWidth: '120px',
  transition: 'all 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

const IconContainer = styled('div')<{ isconfirming: string }>(({ theme, isconfirming }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  transition: 'all 0.3s ease',
  transform: isconfirming === 'true' ? 'scale(0)' : 'scale(1)',
  position: 'relative',
  marginLeft: theme.spacing(1),
}));

const ContactListItem: React.FC<ContactListItemProps> = ({
  contactId,
  selectedIds,
  handleToggle,
  setEditDialogOpen,
  setSelectedContactId,
}) => {
  const dispatch = useAppDispatch();
  const contact = useAppSelector((state) => selectContactById(state, contactId));
  const [isConfirming, setIsConfirming] = useState(false);
  const [isFavoriting, setIsFavoriting] = useState(false);
  let confirmTimeout: NodeJS.Timeout;

  const handleEditClick = (contactId: string) => {
    setSelectedContactId(contactId);
    setEditDialogOpen(true);
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsConfirming(true);
    confirmTimeout = setTimeout(() => {
      setIsConfirming(false);
    }, 3000);
  };


  const handleConfirmDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    clearTimeout(confirmTimeout);
    setIsConfirming(false);
    dispatch(deleteContact(contactId)).then(() => {
      dispatch(fetchFilteredContacts({ query: '' }));
    });
  };

  const handleFavoriteClick = () => {
    if (contact) {
      const updatedTags = contact.tags?.includes('favorite')
        ? contact.tags.filter(tag => tag !== 'favorite')
        : [...(contact.tags || []), 'favorite'];
      setIsFavoriting(true);
      dispatch(updateContact({
        contactId: contact.id.toString(),
        updates: {
          favorite: !contact.favorite,
          tags: updatedTags
        }
      })).then(() => {
        setIsFavoriting(false);
      });
    }
  };

  useEffect(() => {
    return () => {
      if (confirmTimeout) clearTimeout(confirmTimeout);
    };
  }, []);

  if (!contact) return null;

  return (
    <ListItem
      key={contact.id}
      sx={{
        // animate the background color when hovering or when contact is loading
        //slowly fade out when contact is deleting but when loading, gradient moving slowly from left to right
        // when contact is just changed or created, do a shiny animation very fast on background color
        animation: `
          ${contact.deleting ? 'fadeOut 2s ease' : 'none'},
          ${contact.loading ? 'gradient 2s ease' : 'none'}
        `,
        backgroundColor: contact.loading ? 'linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2))' : 'transparent',
        // when deleting shrink the height of the list item to 0
        transition: '300ms',
        cursor: 'pointer',
        position: 'relative',
        ":hover": {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          transition: 'box-shadow 0.2s ease',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          transform: 'scale(1.01)',
        },
      }}
      onClick={() => handleEditClick(contact?.id?.toString() || '')}
    >
      <Checkbox
        checked={selectedIds.indexOf(contact?.id?.toString() || '') !== -1}
        tabIndex={-1}
        onChange={(e) => {
          e.stopPropagation();
          handleToggle(contact?.id?.toString() || '');
        }}
        onClick={(e) => e.stopPropagation()}
        disabled={contact.deleting}
        sx={{
          ":hover": {
            opacity: 1,
          },
          opacity: 0.1,
          transition: 'opacity 0.2s ease',
          ml: -3.3
        }}
      />
      {/* star icon to favorite the contact */}
      <IconButton
        edge="end"
        sx={{
          mr: .5,
        }}
        aria-label="favorite"
        onClick={(e) => {
          e.stopPropagation();
          handleFavoriteClick();
        }}
        disabled={contact.deleting}
      >
        <Star sx={{
          fill: contact.favorite ? 'gold' : 'none',
          stroke: contact.favorite ? 'none' : 'currentColor',
          color: contact.favorite ? 'gold' : 'inherit',
          animation: isFavoriting ? 'pulse 1s ease infinite, shimmer 2s linear infinite, spin 2s linear infinite' : 'none',
          '@keyframes pulse': {
            '0%': { transform: 'scale(1)' },
            '50%': { transform: 'scale(1.2)' },
            '100%': { transform: 'scale(1)' },
          },
          '@keyframes shimmer': {
            '0%': { filter: 'brightness(1) saturate(1)' },
            '50%': { filter: 'brightness(3) saturate(1.5)' },
            '100%': { filter: 'brightness(1) saturate(1)' },
          },
          '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '50%': { transform: 'rotate(180deg)' },
            '100%': { transform: 'rotate(360deg)' },
          },
          transition: 'color 0.2s ease',
          ":hover": { color: 'gold' }
        }} />
      </IconButton>
      <ListItemText
        primary={contact.name || `${contact.first_name || ''} ${contact.last_name || ''}`.trim() || 'Unknown'}
        secondary={
          <>
            <Typography component="span" variant="body2" color="text.primary">
              {contact.email || ''}
            </Typography>
            {' — '} {contact.phone_number || ''}
          </>
        }
      />
      <ListItemSecondaryAction sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          edge="end"
          aria-label="edit"
          onClick={(e) => {
            e.stopPropagation();
            handleEditClick(contact?.id?.toString() || '');
          }}
          disabled={contact.deleting}
        >
          <EditIcon />
        </IconButton>

        <IconContainer isconfirming={isConfirming.toString()}>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={handleDeleteClick}
            disabled={selectedIds.length > 0}
          >
            <DeleteIcon />
          </IconButton>
        </IconContainer>

        <ConfirmButton
          variant="contained"
          color="error"
          onClick={handleConfirmDelete}
          disabled={contact.deleting}
          sx={{
            opacity: isConfirming ? 1 : 0,
            transform: isConfirming ? 'scale(1)' : 'scale(0.8)',
            pointerEvents: isConfirming ? 'auto' : 'none',
            position: 'absolute',
            right: 0,
          }}
          startIcon={<DeleteIcon />}
        >
          Confirm
        </ConfirmButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const ContactsList: React.FC<ContactsListProps> = ({ onSelectionChange, selectedContactIds }) => {
  const dispatch = useAppDispatch();
  const contacts = useAppSelector(selectAllFilteredContacts);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>(selectedContactIds); // Single selection state

  const handleToggle = (contactId: string) => {
    const id = contactId?.toString() || '';
    const currentIndex = selectedIds.indexOf(id);
    let newSelectedIds = [];

    if (currentIndex === -1) {
      newSelectedIds = [...selectedIds, id];
    } else {
      newSelectedIds = selectedIds.filter((sid) => sid !== id);
    }

    setSelectedIds(newSelectedIds);
    onSelectionChange(newSelectedIds); // Pass selection up
  };

  // Handler for 'Select All' checkbox change event
  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let newSelectedIds: string[] = [];
    if (isChecked) {
      // Select all contacts
      newSelectedIds = contacts.map(contact => contact.id?.toString() || '').filter(Boolean);
      console.log('Selected all contacts:', newSelectedIds);
    } else {
      // Deselect all contacts
      newSelectedIds = [];
      console.log('Deselected all contacts');
    }
    setSelectedIds(newSelectedIds);
    onSelectionChange(newSelectedIds); // Pass selection up
  };

  const handleDeleteSelected = () => {
    if (window.confirm('Are you sure you want to delete the selected contacts?')) {
      Promise.all(selectedIds.map((contactId) => dispatch(deleteContact(contactId))))
        .then(() => {
          setSelectedIds([]);
          onSelectionChange([]); // Reset selection
          dispatch(fetchFilteredContacts({ query: '' }));
        });
    }
  };

  useEffect(() => {
    // Update selectedIds when selectedContactIds prop changes
    setSelectedIds(selectedContactIds);
  }, [selectedContactIds]);

  return (
    <Box>
      {/* 'Select All' checkbox at the top */}

      <List>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedIds.length === contacts.length && contacts.length > 0}
              onChange={handleSelectAllChange}
              indeterminate={selectedIds.length > 0 && selectedIds.length < contacts.length}
            />
          }
          label="Select All"
        />
        {contacts.map((contact) => (
          <ContactListItem
            key={contact.id}
            contactId={contact.id}
            selectedIds={selectedIds}
            handleToggle={handleToggle}
            setEditDialogOpen={setEditDialogOpen}
            setSelectedContactId={setSelectedContactId}
          />
        ))}
      </List>

      {selectedContactId && (
        <ContactDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          contactId={selectedContactId}
        />
      )}
    </Box>
  );
};

export default ContactsList;
