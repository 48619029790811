import React, { useState } from 'react';
import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import QrCode from '@mui/icons-material/QrCode';
import CloseIcon from '@mui/icons-material/Close';
import QRCodeGenerator from './QrCode';

const QrCodeDialog = ({ defaultPhoneNumber, defaultEmail, defaultBaseUrl, defaultUrl, defaultInitialMessage }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button startIcon={<QrCode />} variant="contained" color="secondary" onClick={handleOpen}>
        Share with QR Code
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Create Your QR Code
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <QRCodeGenerator
            defaultPhoneNumber={defaultPhoneNumber}
            defaultEmail={defaultEmail}
            defaultBaseUrl={defaultBaseUrl}
            defaultUrl={defaultUrl}
            defaultInitialMessage={defaultInitialMessage}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QrCodeDialog;
