import { createAsyncThunk, createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  Conversation,
  fetchConversationsWithMessages,
  fetchConversationWithMessages,
  sendMessage,
  updateConversationStatus,
} from './conversations.slice';
import { serializeErrorMessage } from '../../utils';
interface FilteredConversationsState extends EntityState<Conversation> {
  conversationsLoading: boolean;
  conversationsPresent: boolean;
  error: string | null;
  filterObject: any;
  conversationsUpdatedSinceFilterSet: boolean;
  isFilterActive: boolean;
}

// Create entity adapter for conversations
const filteredConversationsAdapter = createEntityAdapter<Conversation>({
  selectId: (conversation) => conversation.id,
  sortComparer: (a, b) => {
    const dateA = a.latest_message_at || a.updated_at;
    const dateB = b.latest_message_at || b.updated_at;
    return dateB.localeCompare(dateA);
  },
});

// Initial state using the adapter's getInitialState method
const initialState: FilteredConversationsState = filteredConversationsAdapter.getInitialState({
  conversationsLoading: false,
  conversationsPresent: false,
  error: null,
  filterObject: {},
  conversationsUpdatedSinceFilterSet: false,
  isFilterActive: false,
});

// Async thunk to fetch filtered conversations
export const fetchFilteredConversations = createAsyncThunk(
  'filteredConversations/fetch',
  async (queryParams: Partial<Conversation>, thunkAPI) => {
    try {
      const response = await thunkAPI.dispatch(fetchConversationsWithMessages(queryParams));
      if (response.payload && response.payload.conversations) {
        return response.payload.conversations;
      } else {
        return thunkAPI.rejectWithValue('Failed to fetch conversations');
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const filteredConversationsSlice = createSlice({
  name: 'filteredConversations',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filterObject = action.payload;
      // Determine if the filter is active
      const values = Object.values(action.payload);
      state.isFilterActive = values.some((value) => {
        if (Array.isArray(value)) return value.length > 0;
        return value !== '' && value != null;
      });
      state.conversationsUpdatedSinceFilterSet = false;
      console.log('Filter set:', action.payload);
      console.log('Is filter active?', state.isFilterActive);
    },
  },
  extraReducers: (builder) => {
    // Handle fetchFilteredConversations actions
    builder
      .addCase(fetchFilteredConversations.pending, (state) => {
        state.conversationsLoading = true;
        state.error = null;
        console.log('Fetching filtered conversations...');
      })
      .addCase(fetchFilteredConversations.fulfilled, (state, action) => {
        console.log('Filtered conversations fetched:', action.payload);
        filteredConversationsAdapter.setAll(state, action.payload);
        state.conversationsLoading = false;
        state.conversationsPresent = action.payload.length > 0;
        state.error = null;
        state.conversationsUpdatedSinceFilterSet = false;
      })
      .addCase(fetchFilteredConversations.rejected, (state, action) => {
        state.conversationsLoading = false;
        state.error = action.error.message as string;
        console.error('Error fetching filtered conversations:', state.error);
      })
      // Handle actions from conversations.slice.tsx
      .addCase(fetchConversationsWithMessages.fulfilled, (state, action) => {
        if (!state.isFilterActive) {
          // Update state only if filter is not active
          filteredConversationsAdapter.upsertMany(state, action.payload.conversations);
        } else {
          state.conversationsUpdatedSinceFilterSet = true;
        }
      })
      .addCase(fetchConversationWithMessages.fulfilled, (state, action) => {
        filteredConversationsAdapter.upsertOne(state, action.payload);
        state.conversationsUpdatedSinceFilterSet = false;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        const conversationId = action.payload.conversation_id;
        const message = action.payload.message;
        const existingConversation = state.entities[conversationId];
        if (existingConversation) {
          const messageExists = existingConversation.messages.some((msg) => msg.id === message.id);
          if (!messageExists) {
            existingConversation.messages.push(message);
          }
        }
        state.conversationsUpdatedSinceFilterSet = false;
      })
      .addCase(updateConversationStatus.fulfilled, (state, action) => {
        const conversation = action.payload.conversation;
        filteredConversationsAdapter.upsertOne(state, conversation);
        state.conversationsUpdatedSinceFilterSet = false;
      })
  },
});

export const { setFilter } = filteredConversationsSlice.actions;

export const {
  selectById: selectFilteredConversationById,
  selectAll: selectAllFilteredConversations,
} = filteredConversationsAdapter.getSelectors(
  (state: RootState) => state.filteredConversations
);

export const selectIsFilterActive = (state: RootState) =>
  state.filteredConversations.isFilterActive;

export default filteredConversationsSlice.reducer;
