import Pusher from 'pusher-js';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { ResSubscriptionFeaturesType, UserType } from 'src/types/user';
import authToken from './auth-header';
import { getUser } from 'src/features/user/user.slice';
import { createContext } from 'react';

const getPusherOptions = () => {
  const userData = localStorage.getItem('user');
  if (!userData) return null;

  const user = JSON.parse(userData);
  if (!user?.access_token) return null;

  return {
    cluster: "mt1",
    authEndpoint: `${process.env.REACT_APP_API_V3_URL}/private/pusher/authorize`,
    auth: {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    },
    userAuthentication: {
      endpoint: `${process.env.REACT_APP_API_V3_URL}/private/pusher/authenticate`,
      transport: 'ajax' as 'ajax',
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    },
    channelAuthorization: {
      endpoint: `${process.env.REACT_APP_API_V3_URL}/private/pusher/authorize`,
      transport: 'ajax' as 'ajax',
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    },
  };
};

// Declare pusher instance that will be updated
let pusherInstance: Pusher | null = null;

export const initializePusher = () => {
  if (pusherInstance) {
    try {
      pusherInstance.disconnect();
      pusherInstance = null;
    } catch (error) {
      console.error('Error disconnecting Pusher:', error);
    }
  }

  try {
    const options = getPusherOptions();
    if (!options) {
      console.log('No valid auth options found, skipping Pusher initialization');
      return null;
    }

    // Create new Pusher instance with authentication options
    pusherInstance = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, options);

    // Enable logging in development
    Pusher.logToConsole = process.env.NODE_ENV !== 'production';

    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);

    // Subscribe to private user channel
    const privateChannel = pusherInstance.subscribe(`private-user.${user.id}`);

    // Handle connection states
    pusherInstance.connection.bind('connected', () => {
      console.log('Pusher connected successfully');
      // After connection, try to sign in
      try {
        pusherInstance.signin();
      } catch (error) {
        console.error('Error signing in to Pusher after connection:', error);
      }
    });

    pusherInstance.connection.bind('error', (err) => {
      console.error('Pusher connection error:', err);
    });

    // Handle subscription events
    privateChannel.bind('pusher:subscription_succeeded', () => {
      console.log('Successfully subscribed to private channel');
    });

    privateChannel.bind('pusher:subscription_error', (error) => {
      console.error('Pusher subscription error:', error);
    });

    return pusherInstance;
  } catch (error) {
    console.error('Error initializing Pusher:', error);
    pusherInstance = null;
    return null;
  }
};

// Initialize Pusher context with null (will be updated when available)
export const PusherContext = createContext<Pusher>(null);

// Export a function to get the current Pusher instance
export const getPusherInstance = () => pusherInstance;

const register = async (user): Promise<UserType> => {
  return httpClient.post(`${API_ROUTES.registration}`, {
    user: {
      ...user,
    },
  })
    .then((response) => {
      if (response.data.access_token) {
        // save it in the localstorage maybe use a local storage plugin
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
};

const login = async (email: string, password: string): Promise<UserType> => {
  return httpClient
    .post(`${API_ROUTES.login}`, {
      user: {
        email,
        password,
      },
    })
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
        // Ensure we initialize Pusher after the user data is saved
        setTimeout(() => initializePusher(), 0);
      }
      return response.data;
    });
};

const smsLogin = async (phone_token: string): Promise<UserType> => {
  return httpClient
    .post(`${API_ROUTES.login_with_sms}`, { phone_token })
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
        // Reinitialize Pusher with new token
        initializePusher();
      }
      return response.data;
    });
};

const sendSms = async (phone: string) => {
  return httpClient.post(API_ROUTES.send_verification_code, { phone });
};

const validateSms = async (phone: string, code: string) => {
  return httpClient.post(API_ROUTES.verify_sms, { phone, code });
};

const logout = async () => {
  // Disconnect Pusher before removing user data
  if (pusherInstance) {
    try {
      pusherInstance.disconnect();
      pusherInstance = null;
    } catch (error) {
      console.error('Error disconnecting Pusher during logout:', error);
    }
  }
  // remove token from local storage
  localStorage.removeItem('user');
};

const forgotPassword = async (data: { email: string }) => {
  return httpClient.post(`${API_ROUTES.forgot_password}`, {
    user: data
  });
};

const resetPassword = async (data: { reset_password_token: string, password: string, password_confirmation: string }) => {
  return httpClient.put(`${API_ROUTES.reset_password}`, {
    user: data
  });
};

const getUserSubscription = async (): Promise<ResSubscriptionFeaturesType> => {
  return httpClient.get(`${API_ROUTES.subscription_paywall_user}`);
};

const createSubscription = async (data: { checkout_session_id: String }) => {
  return httpClient.post(`${API_ROUTES.create_subscription}`, data);
};

const masquerade = async (userId: string) => {
  try {
    const API_URL = `${process.env.REACT_APP_API_V3_URL}/private/users/${userId}/masquerade`;
    const response = await httpClient.post(API_URL);
    console.log(`Masquerading as user with ID: ${userId}`);

    if (response.data.access_token) {
      // Save the new token in local storage
      localStorage.setItem('user', JSON.stringify(response.data));
      // Reinitialize Pusher with new token
      initializePusher();
      // Dispatch getUser to update the Redux state
      getUser();
    }

    return response.data;
  } catch (error) {
    console.error('Error during masquerade:', error);
    throw error;
  }
};

const pusherSignin = () => {
  console.log('Signing in to Pusher');
  // Ensure we have a fresh Pusher instance with current token
  const instance = initializePusher();
  if (instance) {
    try {
      instance.signin();
    } catch (error) {
      console.error('Error signing in to Pusher:', error);
    }
  } else {
    console.warn('Pusher instance not available for signin');
  }
};

const AuthService = {
  register,
  login,
  logout,
  forgotPassword,
  resetPassword,
  getUserSubscription,
  createSubscription,
  sendSms,
  validateSms,
  smsLogin,
  masquerade,
  pusherSignin,
};

export default AuthService;
