import { FC } from 'react';
import { Box, Paper, Typography, Avatar, Chip, Zoom, Fade } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Person } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const BusinessCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    background: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(10px)',
    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(73, 73, 200, 0.2)' : 'rgba(0, 0, 0, 0.1)'}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '300px',
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 20,
}));

const AnimatedAvatar = styled(Avatar)(({ theme }) => ({
    width: 60,
    height: 60,
    bgcolor: 'primary.main',
    background: theme.palette.mode === 'dark'
        ? 'rgba(73, 73, 200, 0.2)'
        : 'rgba(73, 73, 200, 0.1)',
    '&:hover': {
        animation: `${pulseAnimation} 1s ease-in-out infinite`,
    },
}));

const CardHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

const CardContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

interface Props {
    formData: {
        firstName?: string;
        lastName?: string;
        phoneNumber?: string;
        email?: string;
        businessUrl?: string;
        aiAssistantConfig?: {
            name: string;
            role: string;
            personality: string;
        };
    };
    currentStep: number;
}

export const AIBusinessCard: FC<Props> = ({ formData, currentStep }) => {
    const theme = useTheme();
    const showCard = currentStep >= 3;
    const fullName = formData.firstName && formData.lastName ? `${formData.firstName} ${formData.lastName}` : undefined;
    const aiName = formData.aiAssistantConfig?.name || `${formData.firstName || 'Your'}'s AI`;
    const aiRole = formData.aiAssistantConfig?.role || 'AI Assistant';

    if (!showCard) return null;

    return (
        <Fade in timeout={500}>
            <BusinessCard elevation={3}>
                <CardHeader>
                    <AnimatedAvatar>
                        <Person sx={{
                            width: 30,
                            height: 30,
                            color: theme => theme.palette.mode === 'dark'
                                ? '#bbb5ff'
                                : '#4949c8'
                        }} />
                    </AnimatedAvatar>
                    <Box>
                        <Typography variant="h6" sx={{
                            fontWeight: 600,
                            color: theme => theme.palette.mode === 'dark' ? '#fff' : '#000'
                        }}>
                            {aiName}
                        </Typography>
                        <Typography variant="body2" sx={{
                            color: theme => theme.palette.mode === 'dark'
                                ? 'rgba(255, 255, 255, 0.7)'
                                : 'rgba(0, 0, 0, 0.7)'
                        }}>
                            {formData.businessUrl || aiRole}
                        </Typography>
                    </Box>
                </CardHeader>

                <CardContent>
                    {formData.aiAssistantConfig?.personality && (
                        <Typography variant="body2" sx={{
                            color: theme => theme.palette.mode === 'dark'
                                ? 'rgba(255, 255, 255, 0.7)'
                                : 'rgba(0, 0, 0, 0.7)'
                        }}>
                            {formData.aiAssistantConfig.personality}
                        </Typography>
                    )}
                </CardContent>
            </BusinessCard>
        </Fade>
    );
};

export default AIBusinessCard; 