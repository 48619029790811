// import mui link
import { useAppSelector } from 'src/store';
import 'react-phone-input-2/lib/style.css'
import DocumentMeta from 'react-document-meta';
import { meta } from 'src/libs/utils';
import { getBranding } from 'src/utils/branding';
import { Box } from '@mui/material';
import useAuth from 'src/hooks/useAuth';


const params = new URLSearchParams(window.location.search)
const categoryCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_category\s*\=\s*([^;]*).*$)|^.*$/, "$1");
const category = categoryCookie || params.get("category");
import Registration from '../components/Onboarding/Account/Registration';
import Login from '../components/Onboarding/Account/Login';
import ChannelsPage from './channels';
import { useEffect, useState } from 'react';
import ForgotPassword from '../components/Onboarding/Account/ForgotPassword';
import ResetPassword from '../components/Onboarding/Account/ResetPassword';
import { useNavigate } from 'react-router-dom';
import LavaLampBackground from 'src/components/ThemeElements/LavaLampBackground';

const Auth = ({ screen = "register" }) => {

  const { isLoggedIn, authLoading } = useAuth();
  const { user } = useAppSelector(state => state.user)
  const branding_logo = getBranding('oppy_word_mark_yellow');
  const [displayedAuthScreen, setDisplayedAuthScreen] = useState(screen)
  let navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      // redirect to /conversations
      navigate('/conversations')
    }
  }, [isLoggedIn])

  return (
    <>
      <DocumentMeta {...meta} />
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        zIndex={-1}
        sx={{
          backgroundColor: 'backgroundDark.default',
        }}
      >
        <LavaLampBackground />
      </Box>
      <Box
        display="flex"
        justifyContent=""
        alignItems={{
          md: 'flex-start',
          xs: 'center'
        }}
        maxWidth={'fit-content'}
        minHeight="100vh"
        flexDirection="column"
        p={{ xs: 1, sm: 2, md: 4 }}
        sx={{

        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          component="img"
          src={branding_logo}
          sx={{
            mb: -1,
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            height: 120,
            width: 200,
          }}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxWidth="100vw"
          width={{ xs: '100vw', sm: '100vw', md: '100vw', lg: '100vw' }}
          pl={{ xs: 3, sm: 4 }}
          pr={{ xs: 3, sm: 4 }}
          pt={{ xs: 3, sm: 4 }}
          pb={{ xs: 3, sm: 4 }}
          sx={{
            flexDirection: 'column',
            width: '100vw',
            maxWidth: '360px',
            backgroundColor: theme => theme.palette.mode === 'dark' ? 'grey.900' : 'white',
            color: theme => theme.palette.mode === 'dark' ? 'grey.300' : 'text.primary',
            borderRadius: '8px',
            mt: 0,
            boxShadow: theme => theme.palette.mode === 'dark'
              ? '0 0 20px rgba(0, 0, 0, 0.5), 0 8px 16px rgba(0, 0, 0, 0.4)'
              : '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: theme => theme.palette.mode === 'dark' ? 1 : 0,
            borderColor: theme => theme.palette.mode === 'dark' ? 'grey.800' : 'transparent',
            backdropFilter: 'blur(12px)',
          }}
        >
          {!(isLoggedIn && user) ?
            <>
              {displayedAuthScreen === "register" &&
                <Registration setDisplayedAuthScreen={setDisplayedAuthScreen} displayedAuthScreen={displayedAuthScreen} />
              }
              {displayedAuthScreen === "login" &&
                <Login setDisplayedAuthScreen={setDisplayedAuthScreen} displayedAuthScreen={displayedAuthScreen} />
              }
              {displayedAuthScreen === "forgot_password" &&
                <ForgotPassword setDisplayedAuthScreen={setDisplayedAuthScreen} displayedAuthScreen={displayedAuthScreen} />
              }
              {displayedAuthScreen === "reset_password" &&
                <ResetPassword setDisplayedAuthScreen={setDisplayedAuthScreen} displayedAuthScreen={displayedAuthScreen} />
              }
            </>
            // <Registration />
            :
            <ChannelsPage />
          }
        </Box>
      </Box>
    </>
  );
};

export default Auth;
