import { useState } from 'react';
import { getUser } from 'src/features/user/user.slice';
import { GoogleAuthorization } from 'src/libs/googleAuthorization';
import { useAppDispatch } from 'src/store';

const useGoogleAuthV2 = () => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const dispatch = useAppDispatch();

  const userAuthorization = (event: React.MouseEvent, isOnboarding?: boolean) => {
    event.preventDefault();
    setIsConnecting(true);

    const googleAuth = new GoogleAuthorization(handleAuthWindowClosed);
    googleAuth.authorizeUser(event, isOnboarding);
  };

  const handleAuthWindowClosed = () => {
    setIsConnecting(false);
    dispatch(getUser());
  };

  return {
    isConnecting,
    userAuthorization,
    isAuthorized,
  };
};

export default useGoogleAuthV2;