import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem, Switch, TextField, CircularProgress, FormControlLabel, Snackbar, FormGroup, Typography, InputAdornment, IconButton, Tooltip, Divider, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import Badge from '@mui/material/Badge';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SchoolIcon from '@mui/icons-material/School';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NotificationsIcon from '@mui/icons-material/Notifications';
import getRandomName from './RandomDefaultName';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import ConfettiExplosion from 'react-confetti-explosion';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { useAppDispatch } from 'src/store';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useAppSelector } from 'src/store';
import CalendarDialog from '../CalendarDialog';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import UrlInput from './embedKeyAvailabilityChecker';
import { PhoneVerification } from '../PhoneVerification';
import { getBranding } from 'src/utils/branding';
import ImportWebsitesInputArray from './ImportWebsitesInputArray';
import { checkUserCalendar } from 'src/features/user/user.slice';
import { networkError } from 'src/libs/utils';
import { toggleCreateChannelDialog } from 'src/features/theme/theme.slice';

const ChannelCreateDialog = ({ fullScreen }) => {
  const dispatch = useAppDispatch();
  // set user details from redux store
  const { user } = useAppSelector((state) => state.user);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phone, setPhone] = useState('');
  const [channel, setChannel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [CalendarDialogOpen, setCalendarDialogOpen] = useState(false);
  const [system_message, setSystemMessage] = useState('');
  const [calendarConnected, setCalendarConnected] = useState(false);
  const navigate = useNavigate();
  const [bookingEnabled, setBookingEnabled] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [urlValid, setUrlValid] = useState(false);
  const [pages, setPages] = useState([{ tracking_id: '', page_url: '' }]);
  const [expanded, setExpanded] = useState('Personality');
  const logo = getBranding('oppy_expressions_smiling')
  const open = useAppSelector(state => state.theme.isCreateChannelDialogOpen);


  const [formValues, setFormValues] = useState({
    chatbot_name: getRandomName(),
    chatbot_personality: "Professional",
    use_emojis: false,
    goals: [
      "Capture Email, Name & Phone Number",
      "Answer user's questions about your business and ask them questions to qualify them",
      "Share social channels for them to stay connected"
    ],
    business_website_url: "",
    knowledge_base: "",
    pages: [],
    faqs: [],
    fallback_phone_number: "",
    sms_notifications_enabled: false,
    company_name: user?.account?.name || "",
    chatbot_style: "2",
    embed_key: "",
  });

  // const updatePages = (trackingId, pageUrl) => {
  //   setPages(prevPages => {
  //     const pageExists = prevPages.find(page => page.tracking_id === trackingId);
  //     if (pageExists) {
  //       return prevPages.map(page => (page.tracking_id === trackingId ? { ...page, page_url: pageUrl } : page));
  //     } else {
  //       return [...prevPages, { tracking_id: trackingId, page_url: pageUrl }];
  //     }
  //   });
  // };

  const handlePagesUpdate = (updatedPages) => {
    setPages(updatedPages);
  };

  useEffect(() => {
    dispatch(checkUserCalendar()).unwrap().catch((error) => {
      if (error != networkError)
        setBookingEnabled(false)
    })
  }, []);


  useEffect(() => {
    setFormValues({ ...formValues, pages: pages });
  }, [pages]);

  useEffect(() => {
    dispatch(trackAnalytics({ action_name: "user_opened_basic_channel_create", payload: { description: `user clicked basic channel create` } }))
    setCalendarConnected(user.google_calendar_connected)
    if (user.google_calendar_connected) {
      setCalendarDialogOpen(false)
    }
  }, [user.google_calendar_connected])

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleToggle = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.checked });
    dispatch(trackAnalytics({ action_name: "user_toggled_input_basic_channel_create", payload: { description: `user toggled ${e.target.name} to ${e.target.checked}`, target_name: e.target.name, target_value: e.target.value } }))
  }

  const handleImportWebpage = (importedUrlSummary, url) => {
    setFormValues({ ...formValues, knowledge_base: importedUrlSummary, business_website_url: url });
  };

  const purchasePhoneSuccess = (channel) => {
    <ConfettiExplosion />
    setChannel(channel);
    dispatch(trackAnalytics({ action_name: "user_purchased_phone_number", payload: { description: `user purchased phone number ${channel.twilio_phone_number}`, purchased_phone_number: channel.twilio_phone_number } }))
  }

  const handleChangeGoal = (value, index) => {
    let newArr = [...formValues.goals];
    newArr[index] = value;
    setFormValues({ ...formValues, goals: newArr });
  }

  const handleAddGoal = (index) => {
    let newArr = [...formValues.goals];
    newArr.splice(index + 1, 0, ""); // adds a new goal after the current index
    setFormValues({ ...formValues, goals: newArr });
  }

  const handleRemoveGoal = (index) => {
    const newArr = [...formValues.goals];
    newArr.splice(index, 1);
    setFormValues({ ...formValues, goals: newArr });
  }

  const sanitizedPages = (incomingPages) => {
    let pagesContainingUrl = incomingPages.filter(page => page.page_url !== '');
    let filteredPages = pagesContainingUrl.filter(page => page.tracking_id !== '');
    return filteredPages;
  }

  const handleCreateBasicChatBot = async () => {
    setLoading(true);

    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel}`;

    const config = {
      chatbot_name: formValues.chatbot_name,
      user_appended_string: ``,
      provider_frontend_url: `${process.env.REACT_APP_BASE_URL}`,
      sms_notifications_enabled: formValues.sms_notifications_enabled,
      system_message: system_message,
      chatbot_style: formValues.chatbot_style,
      fallback_phone_number: phone,
      chatbot_personality: formValues.chatbot_personality,
      use_emojis: formValues.use_emojis,
      goals: formValues.goals,
      pages: sanitizedPages(pages),
      business_website_url: formValues.business_website_url,
      knowledge_base: formValues.knowledge_base,
      company_name: formValues.company_name,
      booking_enabled: (user.google_calendar_connected && bookingEnabled)

    };

    //format data as the above where config is the config object below
    const data = {
      title: formValues.company_name,
      config: config,
      embed_key: formValues.embed_key,
    };

    try {
      const response = await httpClient.post(url, { ...data });

      if (response && response.data) {
        setChannel(response.data)
        setLoading(false);
        setConfirmationDialog(true);
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);
        enqueueSnackbar('Assistant created!', { variant: 'success' });
        dispatch(trackAnalytics({ action_name: "user_created_basic_channel", payload: { description: `${response.data}` } }))
        window.location.reload()
      } else {
        setLoading(false);
        dispatch(trackAnalytics({ action_name: "user_error_upon_basic_channel_create", payload: { description: `error creating basic channel` } }))
        setError('Error while creating the channel. Please try again in a moment.');
      }
    } catch (error) {
      setLoading(false);
      dispatch(trackAnalytics({ action_name: "user_error_upon_basic_channel_create", payload: { description: `error creating basic channel. Error message ${error}` } }))
      setError('Error while creating the channel. Please try again in a moment.');
    }
  };

  const handlePostChannel = () => {
    if (!isPhoneVerified && formValues.sms_notifications_enabled) {
      enqueueSnackbar('Please verify your phone number.', {
        variant: 'error',
        autoHideDuration: 6000
      });
      return;
    }
    handleCreateBasicChatBot();
  };

  useEffect(() => {
    let system_message = `
      # Your Identity: ${formValues.chatbot_name && `You are an assistant named ${formValues.chatbot_name}`} 

      ${formValues.company_name ? ` working for ${formValues.company_name}` : " working for a company named Oppy.pro"}
      
      # Your Personality/Communication Style is: 
      ${formValues.chatbot_personality && `- Very ${formValues.chatbot_personality}`}
      ${formValues.use_emojis && ' - You use emojis in your responses'} \n`;

    if (formValues.business_website_url) {
      system_message += `# Business Website URL: ${formValues.business_website_url} \n`
    }
    if (formValues.knowledge_base) {
      system_message += `#Knowledge Base: ${formValues.knowledge_base} \n`
    }

    system_message += "After you fully understand the details above - YOU MUST Execute the following goals listed in order of importance while natrually conversing with the user: \n"

    formValues.goals.forEach((goal, index) => {
      system_message += `#Goal ${index + 1}: ${goal} \n`
    })

    system_message += "Now continue executing your goals in your conversation with the user.";
    setSystemMessage(system_message);
  }, [formValues]);

  const handleCalendarConnected = () => {
    dispatch(trackAnalytics({
      action_name: "user_clicked_connect_calendar",
      payload: { description: `user clicked connect calendar` }
    }))
    setBookingEnabled(true)
    setCalendarDialogOpen(false)
  }

  const handleCalendarBack = () => {
    setCalendarDialogOpen(false)
    dispatch(trackAnalytics({
      action_name: "user_exited_connect_calendar",
      payload: { description: `user exited connect calendar` }
    }))
  }

  const handleConnectCalendar = () => {
    setCalendarDialogOpen(true)
    dispatch(trackAnalytics({
      action_name: "user_clicked_connect_calendar",
      payload: { description: `user clicked connect calendar` }
    }))
  }

  const toggleCalendarCalendarSwitch = () => {
    if (!user.google_calendar_connected) {
      setCalendarDialogOpen(true)
    } else if (user.google_calendar_connected) {
      setBookingEnabled(!bookingEnabled)
    }

    dispatch(trackAnalytics({
      action_name: `user_toggled_connect_calendar_${!bookingEnabled}`,
      payload: { description: `user toggled connect calendar` }
    }))
  }

  const handlePhoneNumberChange = (number) => {
    setFormValues({
      ...formValues,
      fallback_phone_number: number
    });
  };

  const knowledgeValue = () => {
    return !formValues.knowledge_base ? `Import your web page above or simply type out a few basic details to help your new assistant, ${formValues.chatbot_name}, achieve your goals!` : formValues.knowledge_base
  }

  const handleEmbedKeyChange = (val) => {
    setFormValues({ ...formValues, embed_key: val });
  }

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClose = () => {
    dispatch(toggleCreateChannelDialog())
  }

  return (
    <>
      {open && 
        <Dialog open={open}  >
          <DialogTitle sx={{
            backgroundColor: '#000000',
            backgroundImage: `url(${logo})`,
            backgroundPosition: 'right',
            backgroundSize: '20%',
            backgroundRepeat: 'no-repeat',
            paddingTop: 7,
            paddingLeft: 5,
            paddingRight: 10,
          }}>
            <Grid container spacing={2}>
              <Grid>
                <Typography sx={{ color: '#BBB5FF' }} variant='h5' onClick={() => setFormValues({ ...formValues, use_emojis: !formValues.use_emojis })}>
                  {formValues.use_emojis ? '👋 Hi' : 'Hi'}{user.first_name && ` ${user.first_name}`},
                </Typography>

                <Tooltip title="Random Name" placement="top">
                  <Typography sx={{ color: '#BBB5FF', marginBottom: 4 }} variant='h4' onClick={() => setFormValues({ ...formValues, chatbot_name: getRandomName() })}>I'm {formValues.chatbot_name}!
                    <RefreshIcon color="secondary" sx={{}} />
                  </Typography>
                </Tooltip>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color: "#CEFF1A",
                    '& label': { // Apply style to the TextField label
                      color: "#4949C8",
                      backgroundColor: "#000000",
                      padding: '3px',
                      borderRadius: 10,
                    },
                  }}
                >
                  <Typography variant="body1">
                    I'm proud to work for
                  </Typography>
                  <TextField
                    focused
                    variant="standard"
                    size='small'
                    sx={{
                      marginTop: 1,
                      marginLeft: 1,
                      width: 'fit-content',
                      '& .MuiInputBase-input': { // Apply style to the TextField input
                        color: "#CEFF1A",
                      },
                    }}
                    label="Company Name"
                    name="company_name"
                    value={formValues.company_name}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
            </Grid>

            <Divider />
          </DialogTitle>
          <DialogContent>
            <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5 }} expanded={expanded === 'Personality'} onChange={handleAccordionChange('Personality')}>
              <AccordionSummary expandIcon={<SentimentSatisfiedAltIcon />} aria-controls="personalityd-content" id="personalityd-header">
                <Typography variant='h6'>Assistant Personality</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle2">Bring your assistant to life</Typography>
                <TextField
                  sx={{ margin: 1 }}
                  label="Assistant Name"
                  name="chatbot_name"
                  value={formValues.chatbot_name}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment:
                      <Button sx={{ margin: 1 }} variant="contained" className='d-flex align-items-center' onClick={() => setFormValues({ ...formValues, chatbot_name: getRandomName() })}>
                        <div className="d-flex align-items-center">
                          <RefreshIcon />
                          <Typography sx={{ marginLeft: 1, fontSize: 8 }}>Random Name</Typography>
                        </div>
                      </Button>
                  }}
                />
                <UrlInput setUrlValid={setUrlValid} handleUrlInputChange={handleEmbedKeyChange} />
                <TextField sx={{ margin: 1 }} label="Company Name" name="company_name" value={formValues.company_name} onChange={handleChange} />
                {/* dropdown option to pick Assistant Style which can be one of Light(value = 1), Dark(value=2) or Disco(value=3) */}
                <FormControl sx={{ margin: 1 }} variant="outlined" >
                  <InputLabel>Look</InputLabel>
                  <Select label="Assistant Style" name="chatbot_style" value={formValues.chatbot_style} onChange={handleChange}>
                    <MenuItem value="1">Light Mode</MenuItem>
                    <MenuItem value="2">Dark Mode</MenuItem>
                    <MenuItem value="3">Disco Mode</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ margin: 1 }} variant="outlined" >
                  <InputLabel>Assistant Personality</InputLabel>
                  <Select label="Assistant Personality" name="chatbot_personality" value={formValues.chatbot_personality} onChange={handleChange}>
                    <MenuItem value="Professional">Professional</MenuItem>
                    <MenuItem value="Friendly">Fun</MenuItem>
                    <MenuItem value="Casual">Casual</MenuItem>
                    <MenuItem value="Business">Business</MenuItem>
                    <MenuItem value="Funny">Funny</MenuItem>
                    <MenuItem value="Sassy">Sassy</MenuItem>
                    <MenuItem value="Caring">Caring</MenuItem>
                    <MenuItem value="Hip">Hip</MenuItem>
                    <MenuItem value="Witty - you are a wise-cracker but always sharp and focused on your goals. You are highly intellegent in your writing ability and need only few words to express yourself while subtlly building deep rapport with the user">Witty</MenuItem>
                    <MenuItem value="Chill AF">Chill AF</MenuItem>
                    <MenuItem value="Sarcastic">Sarcastic</MenuItem>
                    <MenuItem value="Serious">Serious</MenuItem>
                    <MenuItem value="Silly">Silly</MenuItem> 
                  </Select>
                </FormControl>
                {/* <Switch checked={formValues.use_emojis} onChange={handleToggle} name="use_emojis"  /> */}
                <FormControlLabel sx={{ margin: 1 }} control={<Switch checked={formValues.use_emojis} onChange={handleToggle} name="use_emojis" />} label={formValues.use_emojis ? "🙂 Use emojis" : "🤐 Don't use emojis"} />
              </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5 }} expanded={expanded === 'goals'} onChange={handleAccordionChange('goals')}>
              <AccordionSummary expandIcon={
                <Badge color="success" badgeContent={formValues?.goals?.length}>
                  <SportsScoreIcon />
                </Badge>
              } aria-controls="goalsd-content" id="goalsd-header">
                <Typography variant='h6'>Set Goals</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup sx={{ marginTop: 3 }}>
                  <FormControl fullWidth variant="outlined">
                    <Typography sx={{ margin: 1 }} variant="body2">What are your primary goals you want to achieve with your assistant?</Typography>
                    {/* loop through any existing goals in formValues.goals and for each do goal + value */}
                    {
                      formValues.goals && formValues.goals.map((goal, index) => {
                        return (
                          <TextField
                            key={index}
                            sx={{ margin: 1 }}
                            label={`Goal ${index + 1}`}
                            name={`goal_${index + 1}`}
                            value={goal}
                            onChange={event => handleChangeGoal(event.target.value, index)}
                            onBlur={() => {
                              if (goal === '') {
                                handleRemoveGoal(index);
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={() => {
                                      if (window.confirm('Are you sure you want to delete this goal?')) {
                                        handleRemoveGoal(index)
                                      }
                                    }}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                  {goal &&
                                    <IconButton
                                      onClick={() => handleAddGoal(index)}
                                    >
                                      <AddCircleOutlineIcon />
                                    </IconButton>
                                  }
                                </InputAdornment>
                              ),
                            }}
                          />
                        )
                      })
                    }
                  </FormControl>
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5 }} expanded={expanded === 'teach'} onChange={handleAccordionChange('teach')}>
              <AccordionSummary expandIcon={<SchoolIcon />} aria-controls="teachd-content" id="teachd-header">
                <Typography variant='h6'>Teach {formValues.chatbot_name}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <FormGroup sx={{ marginTop: 1 }}>
                  <FormControl fullWidth variant="outlined">
                    <Typography variant="body2">Any other details you'd like to add for {formValues.chatbot_name}. This is a good place to put do's & don'ts, FAQs and any other fine tunings you'd like to add to correct responses in the future.</Typography>
                    <FormGroup sx={{ mt: 1}}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          fullWidth
                          multiline
                          style={{ width: '100%' }}
                          name="knowledge_base"
                          variant='outlined'
                          label="Fine Tune Me (Optional)"
                          helperText={`${formValues.chatbot_name || 'Oppy'} might make mistakes. Short & direct feedback will help ${formValues.chatbot_name} be even more helpful. Consider verifying important info.`}
                          maxRows={10}
                          placeholder={`Do's & Don'ts, FAQs, when I should standby, additional context, personality, writing style, etc.`}
                          value={formValues.knowledge_base}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </FormGroup>
                  </FormControl>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            {/* make a new input for connections that's a button that connect calendar to enable scheduling */}
            <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5 }} expanded={expanded === 'scheduling'} onChange={handleAccordionChange('scheduling')}>
              <AccordionSummary sx={calendarConnected ? { backgroundColor: '' } : { backgroundColor: '#ff9800' }} expandIcon={
                <Badge color={calendarConnected ? "success" : "warning"} badgeContent={calendarConnected ? "✓" : "!"}><CalendarMonthIcon /></Badge>
              } aria-controls="schedulingd-content" id="schedulingd-header">
                <Typography variant='h6' >Scheduling {calendarConnected ? 'Enabled' : 'disabled connect 🗓️!'}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup sx={{ marginTop: 3 }}>
                  <CalendarDialog show={CalendarDialogOpen} handleGoBack={handleCalendarBack} calendarConnected={() => handleCalendarConnected} fullScreen={fullScreen} />
                  {calendarConnected ?
                    <FormControlLabel sx={{ margin: 1 }}
                      control={
                        <Switch
                          checked={bookingEnabled}
                          onChange={() =>
                            toggleCalendarCalendarSwitch()
                          }
                          name="calendar_connected"                 
                        />
                      }
                      label={`🗓️ Scheduling ${bookingEnabled ? "Enabled" : "Disabled"}`}
                    /> :
                    <div>
                      <h5>Connect Calendar To Enable Scheduling</h5>
                      <Button sx={{ margin: 1 }} variant="contained" onClick={handleConnectCalendar}>Connect Your Calendar</Button>
                    </div>
                  }
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5, width: '100%' }} expanded={expanded === 'notifications'} onChange={handleAccordionChange('notifications')}>
              <AccordionSummary expandIcon={
                <Badge color={formValues.sms_notifications_enabled ? "success" : "default"} badgeContent={formValues.sms_notifications_enabled ? "✓" : "💤"}>
                  <NotificationsIcon />
                </Badge>
              } aria-controls="notificationd-content" id="notificationd-header">
                <Typography variant='h6'>Notifications</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel sx={{ margin: 1 }}
                  control={<Switch checked={formValues.sms_notifications_enabled} onChange={handleToggle} name="sms_notifications_enabled" />}
                  label="BCC me via SMS notifications when a user messages"
                />
                {formValues.sms_notifications_enabled &&
                  //<ValidatedPhoneInput value={formValues.fallback_phone_number} setValue={handlePhoneNumberChange} setIsPhoneVerified={setIsPhoneVerified} />
                  <PhoneVerification
                    initialPhoneNumber={formValues.fallback_phone_number}
                    onPhoneNumberChange={handlePhoneNumberChange}
                    onPhoneVerification={(phoneNumber, token, isExistingUser) => {
                      setIsPhoneVerified(true);
                      setPhone(phoneNumber);
                    }}
                  />
                }
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">Back</Button>
            {/* reformat the button above to show circularloading icon if loading */}
            <Button onClick={handlePostChannel} color="primary" disabled={loading}>{loading ? <CircularProgress size={24} /> : "Save Assistant"}</Button>
          </DialogActions>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={!!error}
            onClose={() => setError(null)}
            message={error}
            autoHideDuration={6000}
            color="warning"
          />
        </Dialog>
      }
      {showConfetti && <ConfettiExplosion />}
    </>
  );
};

export default ChannelCreateDialog;
