// BEGIN /src/components/Channels/ConversationsList.tsx
import { useAppDispatch, useAppSelector } from "src/store";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CardContent, CircularProgress, Hidden, IconButton, Skeleton, Typography, duration } from "@mui/material";
import { Button, Card, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ViewAgenda, ViewStream } from '@mui/icons-material';
import ConversationCreateDialogV2 from "./ConversationCreateDialogV2";
import emptyConversationsListSvg from "src/assets/svg/Oppy_careers_Inspector-Appraiser.svg";
import { dateTimeFormatter } from "src/libs/dateTimeFormatter";
import ConversationsListFilterBar from "./ConversationsListFilterBar";
import ConversationCard from "./ConversationCard";
import { fetchConversationsWithMessages, selectConversationsWithContacts } from "src/features/account/conversations.slice";
import { selectAllContacts } from "src/features/account/contacts.slice";
import { AddComment } from '@mui/icons-material'; // Add import
import { useTheme } from '@mui/material/styles';
import { selectAllFilteredConversations, selectIsFilterActive } from "src/features/account/filteredConversations.slice";

interface ConversationsListV2Props {
  conversationsListStyle?: 'open' | 'closed';
  handleConversationsListToggleClick: () => void;
}

const getStyles = (darkMode: boolean) => ({
  mainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexFlow: 'row',
    flexGrow: 0,
    borderRadius: 3,
    height: '100vh',
    overflowY: 'scroll',
    minWidth: '320px',
    position: 'relative',
    bgcolor: darkMode ? 'grey.900' : 'background.default',
    color: darkMode ? 'grey.300' : 'text.primary',
    transition: 'all 0.3s ease',
    borderRight: 1,
    borderColor: darkMode ? 'grey.800' : 'grey.200',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      bgcolor: darkMode ? 'grey.800' : 'grey.100'
    },
    '&::-webkit-scrollbar-thumb': {
      bgcolor: darkMode ? 'grey.700' : 'grey.300',
      borderRadius: '4px',
      '&:hover': {
        bgcolor: darkMode ? 'grey.600' : 'grey.400'
      }
    }
  },
  topBar: {
    position: 'absolute',
    top: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2,
    background: darkMode ? 'grey.900' : '#FAFAFA',
    width: '100%',
    borderBottom: 1,
    borderColor: darkMode ? 'grey.700' : 'grey.200',
    transition: 'all 0.3s ease',
    zIndex: 1,
    boxShadow: darkMode ? 'none' : '0 1px 2px rgba(0,0,0,0.05)',
    '& .MuiTypography-root': {
      color: darkMode ? 'grey.100' : 'text.primary',
      transition: 'color 0.3s ease'
    }
  },
  topBarTitleAndFilterContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 2
  },
  topBarAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    '& .MuiIconButton-root': {
      color: darkMode ? 'grey.300' : 'text.secondary',
      transition: 'all 0.2s ease',
      '&:hover': {
        color: 'primary.main',
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
      }
    },
    '& .MuiButton-root': {
      color: darkMode ? 'grey.300' : 'text.primary',
      borderColor: darkMode ? 'grey.700' : 'grey.300',
      '&:hover': {
        borderColor: 'primary.main',
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
      }
    }
  },
  conversationsContainer: {
    mt: 12,
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    overflowY: 'scroll',
    display: 'flex',
    flexFlow: 'row',
    flexDirection: 'row',
    gap: '20px',
    p: 2,
    alignItems: 'stretch',
    flexWrap: 'wrap',
    transition: 'all 0.3s ease',
    bgcolor: darkMode ? 'grey.900' : 'background.default',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      bgcolor: darkMode ? 'grey.800' : 'grey.100'
    },
    '&::-webkit-scrollbar-thumb': {
      bgcolor: darkMode ? 'grey.700' : 'grey.300',
      borderRadius: '4px',
      '&:hover': {
        bgcolor: darkMode ? 'grey.600' : 'grey.400'
      }
    }
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 3,
    color: darkMode ? 'error.light' : 'error.main',
    transition: 'all 0.3s ease',
    bgcolor: darkMode ? 'rgba(244, 67, 54, 0.1)' : 'rgba(244, 67, 54, 0.05)',
    borderRadius: 1,
    '& .MuiTypography-root': {
      color: 'inherit'
    }
  },
  conversationGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    padding: '8px',
    borderRadius: 1,
    bgcolor: darkMode ? 'rgba(255, 255, 255, 0.02)' : 'rgba(0, 0, 0, 0.02)'
  },
  groupHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    color: darkMode ? 'grey.300' : 'text.primary',
    transition: 'all 0.3s ease',
    px: 1
  },
  conversationCardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px',
    width: '100%'
  },
  emptyStateBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& .MuiCard-root': {
      minWidth: 275,
      bgcolor: darkMode ? 'grey.800' : 'background.paper',
      color: darkMode ? 'grey.300' : 'text.primary',
      transition: 'all 0.3s ease',
      boxShadow: darkMode ? 'none' : 1,
      border: 1,
      borderColor: darkMode ? 'grey.700' : 'grey.200'
    }
  },
  emptyStateContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 3,
    '& img': {
      width: '50%',
      marginBottom: 2,
      opacity: darkMode ? 0.8 : 1,
      transition: 'opacity 0.3s ease'
    },
    '& .MuiTypography-root': {
      color: darkMode ? 'grey.400' : 'text.secondary',
      textAlign: 'center',
      transition: 'color 0.3s ease'
    },
    '& .MuiButton-root': {
      mt: 2,
      color: darkMode ? 'primary.light' : 'primary.main',
      borderColor: darkMode ? 'primary.light' : 'primary.main',
      '&:hover': {
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
      }
    }
  },
  dayTypography: {
    m: 2,
    mt: 3,
    ml: 0,
    mb: 0,
    alignItems: 'center',
    color: darkMode ? 'grey.300' : 'text.primary',
    transition: 'all 0.3s ease',
    fontWeight: 500,
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em'
  },
  dayCountTypography: {
    ml: 1,
    display: 'inline',
    fontWeight: 'normal',
    color: darkMode ? 'grey.400' : 'text.secondary',
    transition: 'all 0.3s ease',
    fontSize: '0.75rem'
  }
});

const ConversationsListV2: React.FC<ConversationsListV2Props> = ({ conversationsListStyle = 'open', handleConversationsListToggleClick }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const styles = getStyles(darkMode);
  const [focusedConversation, setFocusedConversation] = useState<any | null>(null);
  const [createConversationOpen, setCreateConversationOpen] = useState(false);
  const { loading: conversationsLoading, error: conversationsLoadError, initialLoad: conversationsInitialLoad } = useAppSelector(state => state.conversations);
  const contacts = useAppSelector(selectAllContacts);
  const contactsInitialLoad = useAppSelector(state => state.contacts.initialLoad);
  const contactsError = useAppSelector(state => state.contacts.error);
  const isFilterActive = useAppSelector(selectIsFilterActive);
  const unfilteredConversations = useAppSelector(selectConversationsWithContacts);
  const filteredConversations = useAppSelector(selectAllFilteredConversations);
  const conversations = isFilterActive ? filteredConversations : unfilteredConversations;
  const [viewMode, setViewMode] = useState<'full' | 'small'>('full');

  useEffect(() => {
    if (focusedConversation) {
      navigate(`?conversation_id=${focusedConversation.id}&channel_id=${focusedConversation.channel_id}`)
    }
  }, [focusedConversation]);

  // Add contacts as a dependency to useEffect to trigger re-render when contacts change
  useEffect(() => {
    if (!focusedConversation && conversations.length > 0) {
      setFocusedConversation(conversations[0]);
    }
    if (conversations.length === 0 && !conversationsLoading && conversationsInitialLoad) {
      setCreateConversationOpen(true);
    }
  }, [conversations, conversationsLoading, contacts, conversationsInitialLoad]);


  const displayOrder = ["Priority", "Today", "Yesterday", "Last 7 Days", "Older", "Closed"];

  const conversationsGroupedByDay = (conversations) => {
    const groupedConversations = displayOrder.reduce((accumulator, label) => {
      accumulator[label] = { conversations: [], count: 0 };
      return accumulator;
    }, {});

    const priorityStatuses = ['needs_attention', 'error', 'processing_autocomplete']; // Define priority statuses
    const conversationsCopy = [...conversations];

    // Sort the copy of the conversations array
    conversationsCopy.sort((a, b) => {
      const statusPriorityA = priorityStatuses.includes(a.status) ? 0 : 1;
      const statusPriorityB = priorityStatuses.includes(b.status) ? 0 : 1;
      if (statusPriorityA !== statusPriorityB) {
        return statusPriorityA - statusPriorityB;
      }
      const timeA = new Date(a.latest_message_at || a.updated_at).getTime();
      const timeB = new Date(b.latest_message_at || b.updated_at).getTime();
      return timeB - timeA; // Descending order
    });

    conversationsCopy.forEach(conversation => {
      const hasPendingMessages = conversation.messages.some(msg => msg.status === 'pending_delivery');
      if (conversation.delivery_state === 'paused' && hasPendingMessages) {
        groupedConversations['Priority'].conversations.push(conversation);
        groupedConversations['Priority'].count++;
      } else if (priorityStatuses.includes(conversation.status)) {
        groupedConversations['Priority'].conversations.push(conversation);
        groupedConversations['Priority'].count++;
      } else if (conversation.status === 'closed') {
        groupedConversations['Closed'].conversations.push(conversation);
        groupedConversations['Closed'].count++;
      } else {
        const label = dateTimeFormatter(conversation.latest_message_at || conversation.updated_at, 'humanized_time_period');
        groupedConversations[label].conversations.push(conversation);
        groupedConversations[label].count++;
      }
    });

    return groupedConversations;
  };

  const groupedConversations = conversationsGroupedByDay(conversations || []);

  const loadingSkeletons = Array.from(new Array(5)).map((_, index) => (
    <Box key={index} sx={{
      borderRadius: '10px',
      mb: 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 250,
      animationDuration: '4s',
    }}>
      <Skeleton variant="rectangular" width={'350px'} height={'250px'} animation={'wave'} sx={{ maxWidth: '100%', minWidth: '300px', borderRadius: '10px' }}>
        <Skeleton variant="rectangular" width={'100%'} height={'25px'} animation={'wave'} />
        <Skeleton variant="rectangular" width={'100%'} height={'150px'} animation={'wave'} />
      </Skeleton>
    </Box>
  ));

  const toggleViewIcon = viewMode === 'full' ? <ViewStream /> : <ViewAgenda />;

  const handleViewModeToggle = () => {
    setViewMode(prevMode => (prevMode === 'full' ? 'small' : 'full'));
  };

  return (
    <>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.topBar}>
          <Box sx={styles.topBarTitleAndFilterContent}>
            <Typography variant="h5" sx={{ color: darkMode ? 'grey.300' : 'text.primary' }}>Conversations</Typography>
            <ConversationsListFilterBar />
          </Box>
          <Box sx={styles.topBarAction}>
            <IconButton onClick={handleViewModeToggle} sx={{ mr: 2 }}>
              {toggleViewIcon}
            </IconButton>
            <IconButton color="secondary" onClick={() => setCreateConversationOpen(true)}>
              <AddComment />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.conversationsContainer}>
          {conversationsLoadError &&
            <Box sx={styles.errorBox}>
              <Typography>Having trouble connecting to your conversations. {conversationsLoadError}</Typography>
              <Button onClick={() => dispatch(fetchConversationsWithMessages({}))}>Retry</Button>
            </Box>}
          {conversationsLoading ?
            <Box sx={styles.conversationGroup}>
              <Box sx={styles.groupHeading}>
                <Typography variant="h5" sx={styles.dayTypography}>
                  <Skeleton variant="text" width={'300px'} height={'50px'} />
                </Typography>
              </Box>
              <Box sx={styles.conversationCardsContainer}>
                {loadingSkeletons}
              </Box>
            </Box>
            :
            displayOrder.map((group) => (
              groupedConversations[group].count ?
                <Box sx={styles.conversationGroup} key={group}>
                  <Box sx={styles.groupHeading}>
                    <Typography variant="h5" sx={styles.dayTypography}>
                      {group}
                      <Typography variant="h5" sx={styles.dayCountTypography}>
                        {groupedConversations[group].count}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box sx={styles.conversationCardsContainer}>
                    {groupedConversations[group].conversations.map((conversation) => (
                      <ConversationCard
                        handleConversationsListToggleClick={handleConversationsListToggleClick}
                        key={conversation.id}
                        conversation={conversation}
                        variant={viewMode} // Pass the variant prop here
                      />
                    ))}
                  </Box>
                </Box>
                : null
            ))
          }
          {conversations.length === 0 && !conversationsLoading &&
            <Box sx={styles.emptyStateBox}>
              <Card>
                <CardContent>
                  <Box sx={styles.emptyStateContent}>
                    <img src={emptyConversationsListSvg} alt="Empty" />
                    <Typography variant="subtitle1" gutterBottom>
                      Seems you don't have any conversations yet.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Pro tip: Start a conversation or begin sharing your Oppies!
                    </Typography>
                    <Button sx={{ mt: 5 }} variant="outlined" color="secondary" onClick={() => setCreateConversationOpen(true)}>+ New conversation</Button>

                  </Box>
                </CardContent>
              </Card>
            </Box>

          }

        </Box>
      </Box >
      <ConversationCreateDialogV2
        open={createConversationOpen}
        handleClose={() => setCreateConversationOpen(false)}
      />
      {/* end conversations list */}
    </>

  );
};

export default ConversationsListV2;
