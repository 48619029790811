import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ContactCard from '../ContactCard';
import EntityCard from '../EntityCard';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getContact, selectContactById } from 'src/features/account/contacts.slice';
import theme from 'src/themes/oppy-default-theme';
import { selectConversationById } from 'src/features/account/conversations.slice';

const ConversationContacts = ({ conversation_id, sx = {} }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const initialLoad = useAppSelector(state => state.contacts.initialLoad);
  const conversation = useAppSelector(state => selectConversationById(state, conversation_id));

  const style = {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    overflowX: 'auto',
    ...sx,
  };

  const cardStyles = {
    wrapperBox: {
      cursor: 'pointer',
      borderRadius: 1,
      width: '160px',
      border: `1px solid ${darkMode ? theme.palette.grey[800] : theme.palette.grey[200]}`,
      textOverflow: 'ellipsis',
      padding: 1,
      bgcolor: darkMode ? 'grey.900' : 'background.paper',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        borderColor: darkMode ? theme.palette.grey[700] : theme.palette.grey[300],
        bgcolor: darkMode ? 'grey.800' : 'grey.50',
      },
    },
    name: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      width: '100%',
      textOverflow: 'ellipsis',
      color: darkMode ? 'grey.300' : 'text.primary',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      overflow: 'hidden',
    },
    detailsRow: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 10,
      fontWeight: 'light',
      display: 'flex',
      alignItems: 'center',
      color: darkMode ? 'grey.400' : 'text.secondary',
    },
  };

  const getDisplayName = (contact) => {
    return contact?.first_name && contact?.last_name
      ? `${contact?.first_name} ${contact?.last_name}`
      : contact?.name || contact?.email || contact?.phone_number || contact.id;
  };

  // Extract contact IDs from participating entities
  const contactIds = conversation.participating_entities
    .filter((entity) => entity.contact_id)
    .map((entity) => entity.contact_id);

  // Fetch contacts at the top level
  const contacts = useAppSelector((state) =>
    contactIds.map((id) => selectContactById(state, id))
  );

  // Fetch missing contacts if not loaded
  useMemo(() => {
    contactIds.forEach((contactId, index) => {
      const contact = contacts[index];
      if (!contact && !initialLoad) {
        dispatch(getContact(contactId));
      }
    });
  }, [contactIds, contacts, dispatch, initialLoad]);

  // Build unique contact components
  const uniqueContactComponents = useMemo(() => {
    const uniqueContacts = new Map();
    contacts.forEach((contact) => {
      if (contact && !uniqueContacts.has(contact.id)) {
        uniqueContacts.set(contact.id, contact);
      }
    });

    return Array.from(uniqueContacts.values()).map((contact) => {
      const displayName = getDisplayName(contact);
      return (
        <ContactCard
          key={contact.id}
          contact={contact}
          name={displayName}
          styles={cardStyles}
        />
      );
    });
  }, [contacts, cardStyles]);

  // Build unique entity components
  const uniqueEntityComponents = useMemo(() => {
    return conversation.participating_entities
      .filter(
        (entity) =>
          entity.role !== 'function' &&
          !entity.contact_id &&
          !entity.contact?.id
      )
      .map((entity) => (
        <EntityCard key={entity.id} entity={entity} styles={cardStyles} />
      ));
  }, [conversation.participating_entities, cardStyles]);

  return (
    <Box sx={style}>
      {uniqueContactComponents}
      {uniqueEntityComponents}
    </Box>
  );
};

export default ConversationContacts;
