import React, { useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Divider, FormControl, Icon, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from "src/store";
import { useTheme } from '@mui/material/styles';

import { trackAnalytics } from "src/features/analytics/analytics.slice";
import { fetchConversationWithMessages, selectConversationById, sendMessage, toggleOpenConversation, updateConversationDeliveryState, updateConversationStatus } from "src/features/account/conversations.slice";
import SendIcon from '@mui/icons-material/Send';
import { dateTimeFormatter } from "src/libs/dateTimeFormatter";
import Message from "./Message";
import { updateMessage } from "src/features/account/messages.slice";
import DeliveryStateButton from "./DeliveryStateButton";
import GenerateReplyButton from "./GenerateReplyButton";
import ConversationOptionsMenu from "./ConversationOptionsMenu";
import ConversationContacts from "./ConversationContacts";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ConversationMemoryDrawer from "./ConversationMemoryDrawer";
import { getBranding } from "src/utils/branding";
import { postMessageHotKey } from "src/libs/hotKeys"; // Import the hotkey function
import { selectChannelById } from "src/features/account/channels.slice";
import PersonIcon from '@mui/icons-material/Person';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ConversationFollowUpSettings from './ConversationFollowUpSettings';
import { Theme, SxProps } from '@mui/material/styles';
import { CSSProperties } from 'react';

type StylesType = {
  [key: string]: SxProps<Theme>;
};

const getStyles = (darkMode: boolean): StylesType => ({
  chatBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    mb: '0',
    height: '100%',
    width: '100%',
    minHeight: '60vh',
    minWidth: '100%',
    maxWidth: '100%',
    bgcolor: darkMode ? 'grey.900' : 'background.default',
    color: darkMode ? 'grey.300' : 'text.primary',
    transition: 'all 0.3s ease',
    borderLeft: 1,
    borderColor: darkMode ? 'grey.700' : 'grey.200',
    boxShadow: darkMode ? '0 0 20px rgba(0, 0, 0, 0.5)' : 'none',
  },
  chatBoxHeader: {
    position: 'relative',
    background: darkMode ? 'rgba(24, 24, 27, 0.98)' : 'rgba(250, 250, 250, 0.95)',
    backdropFilter: 'blur(12px)',
    boxShadow: darkMode
      ? '0 4px 12px rgba(0, 0, 0, 0.3)'
      : '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderBottom: 1,
    borderColor: darkMode ? 'grey.800' : 'grey.200',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '110px',
    width: '100%',
    zIndex: 100,
    pb: 1,
    pr: { xs: 2, sm: 2, md: 2 },
    pl: { xs: 2, sm: 2, md: 2 },
    transition: 'all 0.3s ease',
    '& .MuiTypography-caption': {
      color: darkMode ? 'grey.100' : 'text.secondary',
      transition: 'color 0.3s ease'
    },
    '& .MuiIconButton-root': {
      color: darkMode ? 'grey.100' : 'text.primary',
      '&:hover': {
        bgcolor: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
      }
    }
  },
  statusIndicator: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
  optionsMenu: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  contactsContainer: {
    width: '100%',
    maxWidth: '100%',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': { display: 'none' },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
  },
  contactsHeading: {
    fontSize: '14px',
    color: darkMode ? 'grey.300' : '#000',
    textAlign: 'left',
    flexShrink: 0,
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    transition: 'color 0.3s ease'
  },
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    pt: '70px',
    pl: 1.5,
    pr: 1.5,
    pb: '150px',
    justifyContent: 'flex-start',
    gap: '14px',
    overflow: 'scroll',
    zIndex: 0,
    backgroundColor: darkMode ? 'grey.900' : 'background.default',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },
    '&::-webkit-scrollbar-track': {
      bgcolor: darkMode ? 'grey.800' : 'grey.100',
      borderRadius: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      bgcolor: darkMode ? 'grey.700' : 'grey.300',
      borderRadius: '4px',
      '&:hover': {
        bgcolor: darkMode ? 'grey.600' : 'grey.400'
      }
    }
  },
  messageGroup: {
    position: 'relative',
    transition: 'height 0.3s ease',
    '&:hover .dayDivider': {
      opacity: 1,
      height: '30px',
      transition: 'opacity 0.3s ease, height 0.3s ease',
    },
  },
  dayDivider: {
    opacity: 0,
    height: 0,
    transition: 'opacity 0.3s ease, height 0.3s ease',
  },
  dividerTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    margin: '0 auto',
    padding: '4px 8px',
    backgroundColor: darkMode ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)',
    borderRadius: '16px',
    color: darkMode ? 'grey.300' : 'text.primary',
    transition: 'all 0.3s ease'
  },
  inputBox: {
    background: darkMode ? 'rgba(18, 18, 18, 0.95)' : 'rgba(250, 250, 250, 0.95)',
    backdropFilter: 'blur(8px)',
    borderTop: 1,
    borderColor: darkMode ? 'grey.800' : 'grey.200',
    bottom: 0,
    left: 0,
    display: 'flex',
    position: { xs: 'fixed', sm: 'fixed', md: 'relative' },
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    pt: 0,
    pb: { xs: .5, sm: .5, md: 1 },
    pr: { xs: .5, sm: .5, md: 2 },
    pl: { xs: .5, sm: .5, md: 2 },
    transition: 'all 0.3s ease',
    boxShadow: darkMode
      ? '0 -4px 6px rgba(0, 0, 0, 0.2)'
      : '0 -4px 6px rgba(0, 0, 0, 0.1)',
  },
  formControl: {
    width: '100%',
    '& .MuiInputBase-root': {
      bgcolor: darkMode ? 'grey.900' : 'white',
      transition: 'all 0.3s ease',
      '&:hover': {
        bgcolor: darkMode ? 'grey.800' : 'grey.50'
      },
      '&.Mui-focused': {
        bgcolor: darkMode ? 'grey.900' : 'white'
      }
    }
  },
  startAdornmentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    marginLeft: '-10px',
    marginRight: '10px',
  },
  deliveryStateButton: {
    marginLeft: '-10px',
    marginRight: '10px',
  },
  textField: {
    width: '100%',
    '& .MuiInputBase-input': {
      color: darkMode ? 'grey.300' : 'text.primary'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: darkMode ? 'grey.700' : 'grey.300'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: darkMode ? 'grey.600' : 'grey.400'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'primary.main'
    }
  },
  iconButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    alignItems: 'center'
  },
  captionTypography: {
    position: "absolute",
    right: "1em",
    color: darkMode ? 'grey.400' : 'text.secondary'
  },
  iconButtonStyle: {
    mr: 1,
    height: '35px',
    width: '35px',
    display: { xs: 'flex', sm: 'flex', md: 'none' },
    color: darkMode ? 'grey.300' : 'text.primary',
    '&:hover': {
      bgcolor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
    }
  },
  boxFlexRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center'
  },
  boxFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    alignItems: { xs: 'center', sm: 'center', xl: 'flex-start' }
  },
  boxFlexRowGap: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
    mr: 1,
    mt: 1,
    alignItems: 'center'
  }
});

const getInputStyle = (darkMode: boolean): CSSProperties => ({
  backgroundColor: darkMode ? '#1e1e1e' : '#ffffff',
  color: darkMode ? '#adbac7' : '#24292f',
  fontSize: 16,
});

const getIconButtonContainerStyle = (): CSSProperties => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
  alignItems: 'center'
});

const Conversation = ({ conversation_id }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const styles = getStyles(darkMode);
  const inputStyle = getInputStyle(darkMode);
  const iconButtonContainerStyle = getIconButtonContainerStyle();
  const conversation = useAppSelector(state => selectConversationById(state, conversation_id));
  const openConversationId = useAppSelector(state => state.conversations.openConversationId); // Add this line
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null); // Create a ref for the input field
  const [lastMessageCount, setLastMessageCount] = useState(0);
  const channel = useAppSelector(state => selectChannelById(state, conversation.channel_id));
  const lastMessageCountRef = useRef(lastMessageCount);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const [postingMessage, setPostingMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sentMessage, setSentMessage] = useState(null);
  const [deletingChat, setDeletingChat] = useState(false);
  const [sendingEntity, setSendingEntity] = useState(() => {
    if (conversation.participating_entities && conversation.participating_entities.length > 0) {
      return conversation.participating_entities.find(entity => entity.role === 'assistant') || conversation.participating_entities[0];
    }
    return null;
  });
  const [messagesPollingActive, setMessagesPollingActive] = useState(false)
  const [conversationLoading, setConversationLoading] = useState(true)
  const [initialMessageSent, setInitialMessageSent] = useState(false)
  const [sendingEntityDrawerOpen, setSendingEntityDrawerOpen] = useState(false)
  const [sendingProgress, setSendingProgress] = useState(0)
  const [channelId, _setChannelId] = useState(conversation.channel_id)
  const [unSentMessage, setUnsentMessage] = useState(null)
  const [iconIndex, setIconIndex] = useState(0);
  const entityRoles = ['assistant', 'user', 'function'];
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [pendingMessage, setPendingMessage] = useState("");
  const showFunctionMessages = useAppSelector(state => state.user.showFunctionMessages);
  const advancedMode = useAppSelector(state => state.user.advancedMode);
  const user = useAppSelector(state => state.user.user);

  useEffect(() => {
    // fetch the conversation with messages
    console.log("fetching conversation with messages");
    dispatch(fetchConversationWithMessages({ conversationId: conversation.id, messagesLimit: 10 }));
    scrollToBottom();
  }, []);

  // useEffect(() => {
  //   if (messagesPollingActive) {
  //     const interval = setInterval(() => {
  //       dispatch(fetchConversationWithMessages({ conversationId: conversation.id, messagesLimit: 10 }));
  //     }, 10000);
  //     return () => clearInterval(interval);
  //   }
  // }, [messagesPollingActive, conversation.id]);

  useEffect(() => {
    setMessagesPollingActive(true);
    return () => setMessagesPollingActive(false);
  }, [conversation.id]);

  useEffect(() => {
    if (conversation.message_count > lastMessageCount) {
      scrollToBottom();
    }
    if (conversation.message_count !== lastMessageCount) {
      setLastMessageCount(conversation.message_count);
    }
  }, [conversation.message_count]);

  useEffect(() => {
    const hotKey = postMessageHotKey(inputRef, handleSendMessage);
    hotKey.mount();
    return () => hotKey.unmount();
  }, [inputValue, postingMessage]);

  useEffect(() => {
    // Unmount component if openConversationId changes and doesn't match the current conversation id
    if (openConversationId !== conversation.id) {
      setMessagesPollingActive(false);
    }
  }, [openConversationId, conversation.id]);

  const setMostRecentPendingDeliveryMessageFromConversation = () => {
    const messages = conversation.messages;
    if (!messages) {
      return
    }
    const mostRecentPendingDeliveryMessage = messages.find(message => message.status === 'pending_delivery');
    if (!mostRecentPendingDeliveryMessage) {
      return
    }
    setUnsentMessage(mostRecentPendingDeliveryMessage);
  }

  const fetchConversationsWithMessagesAction = async () => {
    const conversationId = conversation.id;
    const channelId = conversation.channel_id;
    try {
      await dispatch(fetchConversationWithMessages({ conversationId, messagesLimit: 30 }));
    } catch (err) {
      console.log('Failed to fetch messages: ', err);
    }
  };

  const handleSendMessage = (message: string) => {
    if (postingMessage || !message) return;

    if (conversation.delivery_state === 'pause' &&
      (sendingEntity?.role === 'assistant' || sendingEntity?.role === 'user')) {
      setPendingMessage(message);
      setOpenConfirmDialog(true);
    } else {
      dispatchSendMessage(message);
    }
  };

  const dispatchSendMessage = (message: string) => {
    setPostingMessage(true);
    setSendingProgress(0);

    const data = {
      conversation,
      message,
      sendingEntity
    };

    dispatch(sendMessage(data))
      .then(() => {
        setSendingProgress(90);
        setErrorMessage("");
        setInputValue("");
        setLastMessageCount(prevCount => prevCount + 1);
      })
      .catch((error) => {
        setErrorMessage("Error sending message.");
        dispatch(trackAnalytics({ action_name: "user_send_message_error", payload: { error: error } }));
        setSendingProgress(0);
        console.error("Error sending message:", error);
        setPostingMessage(false);
        setSentMessage(null);
        setInputValue(message);
      })
      .finally(() => {
        setSendingProgress(100);
        fetchConversationsWithMessagesAction();
        setPostingMessage(false);
      });
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  const handleTextFieldFocus = () => {
    scrollToBottom();
  }
  const toggleDeliveryState = () => {
    if (conversation.delivery_state === 'pause') {
      dispatch(updateConversationDeliveryState({ conversationId: conversation.id, channelId, deliveryState: 'play' }));
    } else {
      dispatch(updateConversationDeliveryState({ conversationId: conversation.id, channelId, deliveryState: 'pause' }));
    }
  }

  const conversationMessagesGroupedByDay = (messages) => {
    const groupedMessages = messages.reduce((accumulator, message) => {
      const date = new Date(message.deliver_after || message.created_at);
      const dateKey = date.toLocaleDateString();
      if (!accumulator[dateKey]) {
        accumulator[dateKey] = [];
      }
      accumulator[dateKey].push(message);
      return accumulator;
    }, {});
    return groupedMessages;
  }

  const groupedMessages = conversationMessagesGroupedByDay(conversation.messages || []);

  const formatDateToISO8601 = (day) => {
    return groupedMessages[day][0].deliver_after || groupedMessages[day][0].created_at
  }

  const handleIconClick = () => {
    let newIndex = (iconIndex + 1) % 3;

    // If user doesn't have role 11, skip the 'user' role option
    if (user?.role !== 11 && entityRoles[newIndex] === 'user') {
      newIndex = (newIndex + 1) % 3;
    }

    setIconIndex(newIndex);
    const newEntity = conversation.participating_entities.find(entity => entity.role === entityRoles[newIndex]);
    setSendingEntity(newEntity || null);

    if (entityRoles[newIndex] === 'function' && conversation.delivery_state !== 'pause') {
      dispatch(updateConversationDeliveryState({
        conversationId: conversation.id,
        channelId,
        deliveryState: 'pause'
      }));
    }
  };

  // Initialize sending entity based on user role
  useEffect(() => {
    if (conversation.participating_entities && conversation.participating_entities.length > 0) {
      const assistantEntity = conversation.participating_entities.find(entity => entity.role === 'assistant');
      setSendingEntity(assistantEntity || conversation.participating_entities[0]);
      setIconIndex(entityRoles.indexOf('assistant'));
    }
  }, [conversation.participating_entities]);

  // Reset to assistant when advanced mode is turned off
  useEffect(() => {
    if (!advancedMode && conversation.participating_entities) {
      const assistantEntity = conversation.participating_entities.find(entity => entity.role === 'assistant');
      if (assistantEntity) {
        setSendingEntity(assistantEntity);
        setIconIndex(entityRoles.indexOf('assistant'));
      }
    }
  }, [advancedMode, conversation.participating_entities]);

  return (
    <>
      <Box sx={styles.chatBox}>
        <Box sx={styles.chatBoxHeader}>
          <Box sx={styles.optionsMenu}>
            <ConversationOptionsMenu conversation={conversation} />
          </Box>
          <Typography
            variant="caption"
            sx={{
              mt: 1,
              color: darkMode ? 'grey.300' : 'text.secondary',
              fontWeight: 'medium'
            }}
          >
            {conversation.call_status}
          </Typography>
          <Box sx={styles.contactsContainer}>
            <IconButton
              onClick={() => {
                dispatch(toggleOpenConversation(null));
              }}
              sx={{
                position: 'relative'
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <ConversationContacts conversation_id={conversation.id} />
          </Box>
          <LinearProgress
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '2px',
              zIndex: 1000,
              opacity: conversation.loading ? 1 : 0,
              visibility: conversation.loading ? 'visible' : 'hidden',
              transition: 'opacity 0.3s, visibility 0.3s',
            }}

          />
        </Box>
        <Box
          ref={messagesContainerRef}
          sx={styles.messagesContainer}
        >
          {(!conversation || !conversation.messages || conversation.messages.length === 0) && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: '', height: '200px', width: '100%' }}>
              <img src="/images/oppies/Oppy_careers_Inspector-Appraiser.svg" alt="No Messages" style={{ width: '200px', height: '100px', opacity: 0.5 }} />
              <Typography variant="h6" sx={{ mt: 2, opacity: 0.5 }}>No messages, start a conversation now!</Typography>
            </Box>
          )}
          {conversation && conversation.messages &&
            Object.keys(groupedMessages).map((day) => (
              <Box key={day} sx={styles.messageGroup}>
                <Divider sx={styles.dayDivider} className="dayDivider">
                  <Box sx={styles.dividerTextWrapper}>
                    <Typography
                      variant="caption"
                    >
                      {dateTimeFormatter(formatDateToISO8601(day), 'day_month_ordinal_humanized')}
                    </Typography>
                  </Box>
                </Divider>
                {groupedMessages[day]
                  .filter(message => showFunctionMessages || message.sender_role !== 'function')
                  .map((message) => (
                    <Message
                      key={message.id}
                      message={message}
                      sendingEntity={sendingEntity}
                      channelId={channelId}
                      fetchConversationsWithMessagesAction={fetchConversationsWithMessagesAction}
                      conversationDeliveryState={conversation.delivery_state}
                    />
                  ))}
              </Box>
            ))
          }

          {conversation && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0 }}>
              <ConversationFollowUpSettings conversationId={conversation.id} />
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "4px" }}>
                <GenerateReplyButton conversation={conversation} />
                <Box sx={styles.deliveryStateButton}>
                  <DeliveryStateButton
                    deliveryState={conversation.delivery_state as 'play' | 'pause' | 'loading' | 'stop'}
                    onClick={toggleDeliveryState}
                  />
                </Box>
              </Box>
              <Typography variant="caption" sx={{
                mt: 1,
                textAlign: 'center',
                opacity: 0.5,
                transition: 'opacity 300ms',
                '&:hover': {
                  opacity: 1
                }
              }}>
                Clicking the magic icon generates new replies and actions based on the latest delivered messages. It will pause message auto delivery so you can review the latest replies and actions before delivering anything to your contact.
              </Typography>
              {/* here add a ConversationFollowUpSettings.tsx component */}
            </Box>
          )}

        </Box>
        <Box sx={styles.inputBox}>
          <FormControl sx={styles.formControl}>
            <TextField
              type="text"
              multiline
              placeholder={`${sendingEntity?.role === 'user' ? 'Write as the contact' : sendingEntity?.role === 'assistant' ? `Write as your Oppy, ${channel?.chatbot_name}` : `May the force be with you! Write an internal note to inform or prompt your Oppy, ${channel?.chatbot_name}. Invisible to your contact, but ${channel?.chatbot_name} will read it and keep relevant information in mind for future messages.`}...`}
              value={inputValue}
              autoComplete="off"
              onChange={(e) => setInputValue(e.target.value)}
              autoFocus={true}
              onFocus={() => { handleTextFieldFocus() }}
              disabled={postingMessage}
              inputRef={inputRef}
              inputProps={{
                maxLength: 1000
              }}
              InputProps={{
                style: inputStyle,
                endAdornment: (
                  <Box style={iconButtonContainerStyle}>
                    <IconButton
                      disabled={!inputValue}
                      sx={{
                        opacity: !inputValue ? 0 : 1,
                        transition: 'opacity 0.3s',
                        display: !inputValue ? 'none' : 'flex',
                      }}
                      onClick={() => {
                        handleSendMessage(inputValue);
                      }}
                    >
                      {postingMessage ?
                        <CircularProgress
                          size={24}
                          thickness={5}
                          color="primary"
                          variant="determinate"
                          value={sendingProgress}
                        />
                        :
                        <SendIcon />
                      }
                    </IconButton>
                  </Box>
                ),
                startAdornment: (
                  <Box sx={styles.startAdornmentWrapper}>
                    {advancedMode ? (
                      <IconButton onClick={handleIconClick}>
                        {sendingEntity?.role === 'user' && <PersonIcon />}
                        {sendingEntity?.role === 'assistant' && <img src={getBranding('oppy_expressions_no_mouth')} alt="Assistant Icon" style={{ width: '20px', height: '20px' }} />}
                        {sendingEntity?.role === 'function' && <TipsAndUpdatesIcon />}
                      </IconButton>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                        {sendingEntity?.role === 'assistant' && <img src={getBranding('oppy_expressions_no_mouth')} alt="Assistant Icon" style={{ width: '20px', height: '20px' }} />}
                      </Box>
                    )}
                  </Box>
                )
              }}
              sx={styles.textField}
            />
          </FormControl>
        </Box>
        <ConversationMemoryDrawer conversationId={conversation.id} />

      </Box>
      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Message Delivery Paused</DialogTitle>
        <DialogContent>
          This conversation's message delivery is currently paused or delayed. To deliver the message immediately, you need to mouse over the message itself and click the send icon. Do you want to keep this as a draft message?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmDialog(false);
            dispatchSendMessage(pendingMessage);
          }}>
            Post Draft Message
          </Button>
          <Button variant="outlined" onClick={() => {
            setOpenConfirmDialog(false);
            dispatch(updateConversationDeliveryState({
              conversationId: conversation.id,
              channelId,
              deliveryState: 'play'
            })).then(() => {
              dispatchSendMessage(pendingMessage);
            });
          }} color="primary">
            Enable Delivery & Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Conversation;
