import { useState } from 'react';
import { Box, Container, Paper, Typography, useTheme, Fade, Grow, Slide } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../store';
import ChatBubbles from '../components/OnBoardingV2/ChatBubbles';
import AIBusinessCard from '../components/OnBoardingV2/AIBusinessCard';
import { getStepComponent } from '../components/OnBoardingV2/FormSteps';
import getRandomName from '../components/Channels/RandomDefaultName';
import AuthService from '../services/auth.service';
import { setUser } from '../features/user/user.slice';
import { setCookie } from 'nookies';
import { UserType } from '../types/user';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components
const OnboardingContainer = styled(Box)(({ theme }) => ({
    minHeight: '100vh',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#000' : theme.palette.background.default,
    position: 'relative',
    overflow: 'hidden',
}));

const FloatingForm = styled(Paper)(({ theme }) => ({
    position: 'relative',
    maxWidth: '500px',
    margin: '0 auto',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    background: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(10px)',
    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(73, 73, 200, 0.2)' : 'rgba(0, 0, 0, 0.1)'}`,
    boxShadow: theme.shadows[10],
    zIndex: 2,
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '2px',
        background: theme.palette.primary.main,
        transform: 'scaleX(var(--progress))',
        transformOrigin: 'left',
        transition: 'transform 0.3s ease',
    },
}));

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${fadeIn} 0.5s ease-out`,
}));

const GradientText = styled(Typography)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? 'linear-gradient(to right, #bbb5ff, #4949c8)'
        : 'linear-gradient(to right, #4949c8, #2525a0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent',
}));

// Types for form data
interface FormData {
    businessUrl: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    password: string;
    otp?: string;
    phone_verification?: string;
    calendarConnected: boolean;
    crmType?: 'followupboss' | 'cloze' | 'other';
    crmApiKey?: string;
    interests: string[];
    aiAssistantConfig: {
        name: string;
        role: string;
        personality: string;
        use_emojis: boolean;
        goals: string[];
        chatbot_style: string;
        company_name: string;
        knowledge_base: string;
    };
}

const initialFormData: FormData = {
    businessUrl: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: Math.random().toString(36).slice(-12), // Generate a random password
    calendarConnected: false,
    interests: [],
    aiAssistantConfig: {
        name: '',
        role: '',
        personality: '',
        use_emojis: true,
        goals: [
            "Capture Email, Name & Phone Number",
            "Answer user's questions about your business and ask them questions to qualify them",
            "Share social channels for them to stay connected"
        ],
        chatbot_style: '1',
        company_name: '',
        knowledge_base: '',
    }
};

interface SearchChunk {
    id: string;
    content: string;
    chunk_type: string;
    similarity: number;
}

const OnBoardingV2 = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const totalSteps = 12;
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [searchResults, setSearchResults] = useState<SearchChunk[]>([]);
    const [embedKey, setEmbedKey] = useState<string | null>(null);

    const handleUpdateForm = (field: string, value: any) => {
        setFormData(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSearchResults = (results: SearchChunk[]) => {
        setSearchResults(results);
    };

    const handleNext = async (newEmbedKey?: string | null) => {
        setLoading(true);
        try {
            if (newEmbedKey) {
                setEmbedKey(newEmbedKey);
            }
            if (currentStep === 5) {
                const refCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_ref\s*\=\s*([^;]*).*$)|^.*$/, "$1");
                const ref = refCookie || searchParams.get("ref");
                const accountInviteCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_account_invite\s*\=\s*([^;]*).*$)|^.*$/, "$1");
                const account_invite = accountInviteCookie || searchParams.get("account_invite");
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                const registrationResponse = await AuthService.register({
                    email: formData.email,
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    password: formData.password,
                    phone: formData.phoneNumber,
                    phone_verification: formData.phone_verification,
                    ref,
                    account_invite,
                    timezone,
                    category: searchParams.get("category") || undefined
                });

                // Update Redux store with the user data from registration response
                dispatch(setUser(registrationResponse));
            }
            setCurrentStep(prev => Math.min(prev + 1, totalSteps));
        } catch (error) {
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status,
                stack: error.stack
            });
        } finally {
            setLoading(false);
        }
    };

    const handlePrev = () => {
        setCurrentStep(prev => Math.max(prev - 1, 1));
    };

    const CurrentStepComponent = getStepComponent(currentStep);

    return (
        <OnboardingContainer>
            <ChatBubbles
                businessUrl={formData.businessUrl}
                searchResults={searchResults}
            />

            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Fade in timeout={500}>
                    <Box sx={{ textAlign: 'center', mb: 8 }}>
                        <GradientText variant="h2" gutterBottom fontWeight="bold">
                            10X Your Productivity
                        </GradientText>
                        <AnimatedTypography variant="h5" color="text.secondary">
                            Create your AI assistant in just a few steps
                        </AnimatedTypography>
                    </Box>
                </Fade>

                <Grow in timeout={500}>
                    <Box>
                        <FloatingForm
                            elevation={3}
                            style={{ '--progress': `${(currentStep / totalSteps) * 100}%` } as any}
                        >
                            <Slide direction="left" in mountOnEnter unmountOnExit timeout={300}>
                                <Box>
                                    {CurrentStepComponent && (
                                        <CurrentStepComponent
                                            formData={formData}
                                            onUpdate={handleUpdateForm}
                                            onNext={handleNext}
                                            onPrev={handlePrev}
                                            loading={loading}
                                            onSearchResults={handleSearchResults}
                                            embedKey={embedKey}
                                        />
                                    )}
                                </Box>
                            </Slide>
                        </FloatingForm>
                    </Box>
                </Grow>
            </Container>

            <AIBusinessCard
                formData={formData}
                currentStep={currentStep}
            />
        </OnboardingContainer>
    );
};

export default OnBoardingV2;