import React, { useEffect } from 'react';
import Conversation from './Conversation';
import { useAppDispatch, useAppSelector } from "src/store";
import ConversationsListV2 from './ConversationsListV2';
import Box from '@mui/system/Box';
import Dialog from '@mui/material/Dialog';
import { Theme, SxProps } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toggleOpenConversation, selectAllConversations, selectConversationById } from 'src/features/account/conversations.slice';

interface StyleProps {
  box: SxProps<Theme>;
  dialog: SxProps<Theme>;
}

const getStyles = (darkMode: boolean): StyleProps => ({
  box: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: darkMode ? 'grey.900' : 'background.default',
    color: darkMode ? 'grey.300' : 'text.primary',
    transition: 'all 0.3s ease',
  },
  dialog: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: { xs: '100vw', sm: '100vw', md: '750px', lg: '850px', xl: '950px' },
    '& .MuiDialog-paper': {
      width: { xs: '100vw', sm: '100vw', md: '750px', lg: '850px', xl: '950px' },
      bgcolor: darkMode ? 'grey.900' : 'background.paper',
      color: darkMode ? 'grey.300' : 'text.primary',
      transition: 'all 0.3s ease',
      '& ::-webkit-scrollbar': {
        width: '8px',
        height: '8px'
      },
      '& ::-webkit-scrollbar-track': {
        bgcolor: darkMode ? 'grey.800' : 'grey.100'
      },
      '& ::-webkit-scrollbar-thumb': {
        bgcolor: darkMode ? 'grey.700' : 'grey.300',
        borderRadius: '4px',
        '&:hover': {
          bgcolor: darkMode ? 'grey.600' : 'grey.400'
        }
      }
    },
    '& .MuiBackdrop-root': {
      bgcolor: darkMode ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)',
    }
  },
});

const ConversationsWrapper = () => {
  const dispatch = useAppDispatch();
  const conversations = useAppSelector(selectAllConversations);
  const openConversationId = useAppSelector(state => state.conversations.openConversationId);
  const conversation = useAppSelector(state => selectConversationById(state, openConversationId));
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { darkMode } = useAppSelector(state => state.theme);
  const styles = getStyles(darkMode);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlConversationId = urlParams.get('conversation_id');
    if (urlConversationId && !openConversationId) {
      dispatch(toggleOpenConversation(urlConversationId));
    }
  }, [dispatch]); // Removed openConversationId from dependencies

  return (
    conversations && (
      <Box sx={styles.box}>
        <ConversationsListV2 conversationsListStyle="open" handleConversationsListToggleClick={() => { }} />
        {conversation && (
          <Dialog
            fullScreen={isSmallScreen}
            open={Boolean(conversation)}
            disableScrollLock
            onClose={() => {
              dispatch(toggleOpenConversation(null));
            }}
            sx={styles.dialog}
            PaperProps={{
              elevation: darkMode ? 0 : 24
            }}
          >
            <Conversation conversation_id={conversation.id} />
          </Dialog>
        )}
      </Box>
    )
  );
};

export default ConversationsWrapper;
