import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  Badge,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Save as SaveIcon,
  HelpOutline as HelpIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material';
import {
  FollowUpSettings,
  IntervalPreset,
  FollowUpSettingsFormProps,
} from 'src/types/followUpSettings';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'src/store';
import { useColorMode } from 'src/theme/ThemeProvider';

const INTERVAL_PRESETS: IntervalPreset[] = [
  { label: 'Every hour', minutes: 60, tooltip: 'Check conversation hourly for potential updates' },
  { label: 'Every 4 hours', minutes: 240, tooltip: 'Regular check-ins throughout the day' },
  { label: 'Daily', minutes: 1440, tooltip: 'Once per day follow-up check' },
  { label: '2 Days', minutes: 2880, tooltip: 'Check every other day' },
  { label: '3 Days', minutes: 4320, tooltip: 'Check every third day' },
  { label: 'Weekly', minutes: 10080, tooltip: 'Weekly conversation review' },
  { label: 'Custom', minutes: -1, tooltip: 'Set a custom interval in minutes' },
];

const FollowUpSettingsForm: React.FC<FollowUpSettingsFormProps> = ({
  followUpSettings,
  setFollowUpSettings,
  handleSave,
  saving,
  isExpanded,
  setIsExpanded,
  initialFollowUpSettings,
}) => {
  const [selectedInterval, setSelectedInterval] = useState<IntervalPreset>(INTERVAL_PRESETS[2]); // Default to Daily
  const [customMinutes, setCustomMinutes] = useState('');
  const [advancedOpen, setAdvancedOpen] = useState(false);

  const theme = useTheme();
  const { mode } = useColorMode();
  const { darkMode } = useAppSelector(state => state.theme);
  const isDarkMode = mode === 'dark' || darkMode;

  useEffect(() => {
    const preset = INTERVAL_PRESETS.find(p => p.minutes === followUpSettings.delay_minutes);
    if (preset) {
      setSelectedInterval(preset);
      setCustomMinutes('');
    } else {
      setSelectedInterval(INTERVAL_PRESETS.find(p => p.minutes === -1)!); // Custom
      setCustomMinutes(followUpSettings.delay_minutes.toString());
    }
  }, [followUpSettings.delay_minutes]);

  const handleIntervalChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const minutes = Number(event.target.value);
    const preset = INTERVAL_PRESETS.find(p => p.minutes === minutes);
    if (preset) {
      setSelectedInterval(preset);
      setCustomMinutes('');
    } else {
      setSelectedInterval(INTERVAL_PRESETS.find(p => p.minutes === -1)!); // Custom
      setCustomMinutes(minutes.toString());
    }
    setFollowUpSettings({ ...followUpSettings, delay_minutes: minutes });
  };

  const handleCustomMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const minutes = event.target.value;
    setCustomMinutes(minutes);
    setFollowUpSettings({ ...followUpSettings, delay_minutes: parseInt(minutes) || 0 });
  };

  const hasUnsavedChanges = React.useMemo(() => {
    const currentSettings = JSON.stringify(followUpSettings);
    const initialSettings = JSON.stringify(initialFollowUpSettings);
    const unsaved = currentSettings !== initialSettings;
    console.log('Unsaved changes:', unsaved); // Log whether there are unsaved changes
    return unsaved;
  }, [followUpSettings, initialFollowUpSettings]);

  return (
    <Box mt={2}>
      <Divider sx={{
        '&::before, &::after': {
          borderColor: isDarkMode ? 'grey.700' : 'grey.200',
        }
      }}>
        <Badge color="info" variant="dot" invisible={!hasUnsavedChanges}>
          <Button
            fullWidth
            onClick={() => setIsExpanded(!isExpanded)}
            startIcon={<ScheduleIcon sx={{ color: isDarkMode ? 'grey.400' : 'inherit' }} />}
            endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
            sx={{
              color: isDarkMode ? 'grey.300' : 'text.primary',
              '&:hover': {
                bgcolor: isDarkMode ? 'grey.800' : 'grey.100',
              }
            }}
          >
            Follow-up Settings
          </Button>
        </Badge>
      </Divider>
      <Collapse in={isExpanded}>
        <Box p={2} sx={{ bgcolor: isDarkMode ? 'grey.900' : 'background.default' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={followUpSettings.enabled}
                onChange={(e) =>
                  setFollowUpSettings({ ...followUpSettings, enabled: e.target.checked })
                }
                sx={{
                  color: isDarkMode ? 'grey.500' : 'grey.400',
                  '&.Mui-checked': {
                    color: 'primary.main',
                  },
                }}
              />
            }
            label={
              <Typography sx={{ color: isDarkMode ? 'grey.300' : 'text.primary' }}>
                Enable Automated Follow-ups
              </Typography>
            }
          />
          <Box mt={2}>
            <Typography variant="subtitle1" display="flex" alignItems="center" sx={{ color: isDarkMode ? 'grey.300' : 'text.primary' }}>
              Check Interval
              <Tooltip title="How often Oppy should check this conversation for follow-up">
                <IconButton size="small" sx={{ color: isDarkMode ? 'grey.400' : 'action.active' }}>
                  <HelpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
            <TextField
              select
              value={selectedInterval.minutes}
              onChange={handleIntervalChange}
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  bgcolor: isDarkMode ? 'grey.800' : 'background.paper',
                  '& fieldset': {
                    borderColor: isDarkMode ? 'grey.700' : 'grey.300',
                  },
                  '&:hover fieldset': {
                    borderColor: isDarkMode ? 'grey.600' : 'grey.400',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                },
                '& .MuiInputBase-input': {
                  color: isDarkMode ? 'grey.300' : 'text.primary',
                },
                '& .MuiMenuItem-root': {
                  color: isDarkMode ? 'grey.300' : 'text.primary',
                },
              }}
            >
              {INTERVAL_PRESETS.map((interval) => (
                <MenuItem
                  key={interval.minutes}
                  value={interval.minutes}
                  sx={{
                    bgcolor: isDarkMode ? 'grey.800' : 'background.paper',
                    '&:hover': {
                      bgcolor: isDarkMode ? 'grey.700' : 'grey.100',
                    },
                  }}
                >
                  {interval.label}
                </MenuItem>
              ))}
            </TextField>
            {selectedInterval.minutes === -1 && (
              <TextField
                label="Custom Interval (minutes)"
                type="number"
                value={customMinutes}
                onChange={handleCustomMinutesChange}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{ min: 1 }}
                sx={{
                  mt: 1,
                  '& .MuiOutlinedInput-root': {
                    bgcolor: isDarkMode ? 'grey.800' : 'background.paper',
                    '& fieldset': {
                      borderColor: isDarkMode ? 'grey.700' : 'grey.300',
                    },
                    '&:hover fieldset': {
                      borderColor: isDarkMode ? 'grey.600' : 'grey.400',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'primary.main',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: isDarkMode ? 'grey.300' : 'text.primary',
                  },
                  '& .MuiInputLabel-root': {
                    color: isDarkMode ? 'grey.400' : 'text.secondary',
                  },
                }}
              />
            )}
          </Box>
          <Button
            onClick={() => setAdvancedOpen((prev) => !prev)}
            endIcon={advancedOpen ? <ExpandLess /> : <ExpandMore />}
            sx={{
              mt: 2,
              color: isDarkMode ? 'grey.300' : 'text.primary',
              '&:hover': {
                bgcolor: isDarkMode ? 'grey.800' : 'grey.100',
              }
            }}
          >
            Advanced Options
          </Button>
          <Collapse in={advancedOpen}>
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={followUpSettings.respect_business_hours}
                    onChange={(e) =>
                      setFollowUpSettings({
                        ...followUpSettings,
                        respect_business_hours: e.target.checked,
                      })
                    }
                    sx={{
                      color: isDarkMode ? 'grey.500' : 'grey.400',
                      '&.Mui-checked': {
                        color: 'primary.main',
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ color: isDarkMode ? 'grey.300' : 'text.primary' }}>
                    Respect Business Hours
                  </Typography>
                }
              />
              <TextField
                label="Follow-up Instructions"
                multiline
                rows={4}
                value={followUpSettings.prompt}
                onChange={(e) =>
                  setFollowUpSettings({ ...followUpSettings, prompt: e.target.value })
                }
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  mt: 2,
                  '& .MuiOutlinedInput-root': {
                    bgcolor: isDarkMode ? 'grey.800' : 'background.paper',
                    '& fieldset': {
                      borderColor: isDarkMode ? 'grey.700' : 'grey.300',
                    },
                    '&:hover fieldset': {
                      borderColor: isDarkMode ? 'grey.600' : 'grey.400',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'primary.main',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: isDarkMode ? 'grey.300' : 'text.primary',
                  },
                  '& .MuiInputLabel-root': {
                    color: isDarkMode ? 'grey.400' : 'text.secondary',
                  },
                }}
              />
            </Box>
          </Collapse>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              startIcon={
                saving ? (
                  <CircularProgress size={20} style={{ color: theme.palette.common.white }} />
                ) : (
                  <SaveIcon />
                )
              }
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default FollowUpSettingsForm; 
